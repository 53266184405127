import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core'
import { AssessmentGroupDto } from '../../../models/flip/assessment-group-dto'

@Component({
  selector: 'app-flip-assessment-side-card',
  templateUrl: './flip-assessment-side-card.component.html',
  styleUrls: ['./flip-assessment-side-card.component.scss'],
})
export class FlipAssessmentSideCardComponent implements AfterViewInit {
  @Input() flipAssessmentGroups: AssessmentGroupDto[]
  @Input() scrollIndex: number
  @ViewChild('scrollContainer') scrollContainer: ElementRef | undefined

  ngAfterViewInit(): void {
    if (this.scrollIndex != undefined) {
      this.scrollToItem(this.scrollIndex)
    }
  }

  scrollToItem(index: number): void {
    if (this.scrollContainer?.nativeElement) {
      const container = this.scrollContainer.nativeElement
      const selectedElement =
        container.getElementsByClassName('item-container')[index]

      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'end',
        })
      }
    }
  }
}
