import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { MsalGuard } from '@azure/msal-angular'
import { BrowserUtils } from '@azure/msal-browser'
import { LoginComponent } from 'src/app/components/screen/login/login.component'
import { TestServicesComponent } from 'src/app/components/screen/test-services/test-services.component'
import { ClientDashboardComponent } from './components/screen/client-dashboard/client-dashboard.component'
import { ClientReviewsComponent } from './components/screen/client-reviews/client-reviews.component'
import { ClientComponent } from './components/screen/client/client.component'
import { CoursesComponent } from './components/screen/courses/courses.component'
import { DashboardComponent } from './components/screen/dashboard/dashboard.component'
import { FaqsAndFormsComponent } from './components/screen/faqs-and-forms/faqs-and-forms.component'
import { FlipAssessmentComponent } from './components/screen/flip-assessment/flip-assessment.component'
import { FlipHistoryComponent } from './components/screen/flip-history/flip-history.component'
import { FlipIndividualGraphComponent } from './components/screen/flip-individual-graph/flip-individual-graph.component'
import { FlipInstanceComponent } from './components/screen/flip-instance/flip-instance.component'
import { FlipPopulationComponent } from './components/screen/flip-population/flip-population.component'
import { ProfileComponent } from './components/screen/profile/profile.component'
import { ReddiHistoryComponent } from './components/screen/reddi-history/reddi-history.component'
import { ReddiInstanceComponent } from './components/screen/reddi-instance/reddi-instance.component'
import { CanActivateGuard } from './guards/can-activate'
import { CanDeactivateGuard } from './guards/can-deactivate.guard'
import { ReddiAssessmentComponent } from './components/screen/reddi-assessment/reddi-assessment.component'
import { FlipExternalReportComponent } from './components/screen/flip-external-report/flip-external-report.component'
import { FlipReportFormComponent } from './components/screen/flip-report-form/flip-report-form.component'

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [CanActivateGuard, MsalGuard],
  },
  {
    path: 'test-services',
    component: TestServicesComponent,
    canActivate: [CanActivateGuard, MsalGuard],
  },
  {
    path: '',
    component: LoginComponent,
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    pathMatch: 'full',
    canActivate: [CanActivateGuard, MsalGuard],
  },
  {
    path: 'all-clients',
    component: DashboardComponent,
    pathMatch: 'full',
    canActivate: [CanActivateGuard, MsalGuard],
  },
  {
    path: 'client-dashboard/:clientId',
    component: ClientDashboardComponent,
    pathMatch: 'full',
    canActivate: [CanActivateGuard, MsalGuard],
  },
  {
    path: 'flip/external-report/:clientId',
    component: FlipExternalReportComponent,
    pathMatch: 'full',
    canActivate: [CanActivateGuard, MsalGuard],
  },
  {
    path: 'flip/flip-report-form/:clientId',
    component: FlipReportFormComponent,
    pathMatch: 'full',
    canActivate: [CanActivateGuard, MsalGuard],
  },
  {
    path: 'faqs-and-forms',
    component: FaqsAndFormsComponent,
    pathMatch: 'full',
    canActivate: [CanActivateGuard, MsalGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'add-client',
    component: ClientComponent,
    pathMatch: 'full',
    canActivate: [CanActivateGuard, MsalGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'edit-client/:clientId',
    component: ClientComponent,
    pathMatch: 'full',
    canActivate: [CanActivateGuard, MsalGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'flip-assessment/:clientId',
    component: FlipAssessmentComponent,
    pathMatch: 'full',
    canActivate: [CanActivateGuard, MsalGuard],
  },
  {
    path: 'flip/graph/:clientId',
    component: FlipIndividualGraphComponent,
    pathMatch: 'full',
    canActivate: [CanActivateGuard, MsalGuard],
  },
  {
    path: 'flip/history/:clientId',
    component: FlipHistoryComponent,
    pathMatch: 'full',
    canActivate: [CanActivateGuard, MsalGuard],
  },
  {
    path: 'client-reviews/:clientId',
    component: ClientReviewsComponent,
    pathMatch: 'full',
    canActivate: [CanActivateGuard, MsalGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'flip/instance/:clientId/:instanceId',
    component: FlipInstanceComponent,
    pathMatch: 'full',
    canActivate: [CanActivateGuard, MsalGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'reporting/flip/population',
    component: FlipPopulationComponent,
    pathMatch: 'full',
    canActivate: [CanActivateGuard, MsalGuard],
  },
  {
    path: 'reddi/instance/:clientId/:reddiId',
    component: ReddiInstanceComponent,
    pathMatch: 'full',
    canActivate: [CanActivateGuard, MsalGuard],
  },
  {
    path: 'reddi/assessment/:clientId',
    component: ReddiAssessmentComponent,
    pathMatch: 'full',
    canActivate: [CanActivateGuard, MsalGuard],
  },
  {
    path: 'reddi/history/:clientId',
    component: ReddiHistoryComponent,
    pathMatch: 'full',
    canActivate: [CanActivateGuard, MsalGuard],
  },
  {
    path: 'courses',
    component: CoursesComponent,
    pathMatch: 'full',
    canActivate: [CanActivateGuard, MsalGuard],
  },

  // Cater for situation where someone goes to a non existent page
  { path: '404', component: LoginComponent },
  { path: '**', component: LoginComponent },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iFrames or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
