import { TableColumnDefinition } from '../table/table-column-definition'

export const FlipHistoryTableColumnDefinitions: TableColumnDefinition[] = [
  {
    columnName: 'assessmentDate',
    columnWidth: '170px',
    columnTextAlign: 'left',
    header: 'Assessment Date',
    sortDisabled: true,
    columnType: 'string',
  },
  {
    columnName: 'score',
    columnWidth: '80px',
    columnTextAlign: 'left',
    header: 'Score',
    sortDisabled: true,
    columnType: 'string',
  },
  {
    columnName: 'dataEnteredBy',
    columnWidth: '180px',
    columnTextAlign: 'left',
    header: 'Date Entered By',
    sortDisabled: true,
    columnType: 'truncated-string',
  },
  {
    columnName: 'notes',
    columnWidth: '280px',
    columnTextAlign: 'left',
    header: 'Notes',
    sortDisabled: true,
    columnType: 'truncated-string',
  },
  {
    columnName: 'view',
    columnTextAlign: 'left',
    columnType: 'link',
    columnWidth: '70px',
    header: '',
    linkColor: 'primary',
    linkKind: 'icon',
    showIcon: true,
    sortDisabled: true,
    textAlign: 'left',
  },
]
