import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AssessmentItemDto } from '../../../../models/flip/assessment-item-dto'

@Component({
  selector: 'app-assessment-modal',
  templateUrl: './assessment-modal.component.html',
  styleUrls: ['./assessment-modal.component.scss'],
})
export class AssessmentModalComponent {
  constructor(
    public dialogRef: MatDialogRef<AssessmentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AssessmentItemDto,
  ) {}

  close() {
    this.dialogRef.close(true)
  }
}
