import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-collapsible',
  templateUrl: './collapsible.component.html',
  styleUrls: ['./collapsible.component.scss'],
})
export class CollapsibleComponent {
  @Input() items: any[]

  constructor() {}

  getClassForScore(score: 'Mostly' | 'Rarely'): string {
    return 'flip-score flip-score-' + score.toLowerCase()
  }
}
