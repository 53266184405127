import { Component, Input } from '@angular/core'
import { ConfigService } from 'src/app/services/config/config.service'

@Component({
  selector: 'app-reddi-logo',
  templateUrl: './reddi-logo.component.html',
  styleUrls: ['./reddi-logo.component.scss'],
})
export class ReddiLogoComponent {
  constructor(private config: ConfigService) {}

  protected logoFolderPath: string =
    this.config.getSettings('spa').imageFolder + '/reddi-logo/'

  protected logoPath: string = this.logoFolderPath + 'Reddi-Logo-168x62.png'

  protected path: string

  @Input() inverted: boolean = false

  ngOnInit() {
    this.path = this.logoPath
  }
}
