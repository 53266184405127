import { Component } from '@angular/core'
import { MsalBroadcastService, MsalService } from '@azure/msal-angular'
import { InteractionStatus } from '@azure/msal-browser'
import { Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { ApplicationInsightsService } from 'src/app/services/application-insights/application-insights.service'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  displayedColumns: string[] = ['claim', 'value']
  dataSource: Claim[] = []
  private readonly _destroying$ = new Subject<void>()

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private appInsights: ApplicationInsightsService,
  ) {
    appInsights.logPageView('profile', '/profile')
  }

  ngOnInit(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) =>
            status === InteractionStatus.None ||
            status === InteractionStatus.HandleRedirect,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount()
        this.getClaims(
          this.authService.instance.getActiveAccount()?.idTokenClaims,
        )
      })
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount()

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts()
      this.authService.instance.setActiveAccount(accounts[0])
    }
  }

  getClaims(claims: any) {
    let list: Claim[] = new Array<Claim>()

    Object.keys(claims).forEach(function (k, v) {
      let c = new Claim()
      c.id = v
      c.claim = k
      c.value = claims ? claims[k] : null
      list.push(c)
    })
    this.dataSource = list
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined)
    this._destroying$.complete()
  }
}

export class Claim {
  id: number
  claim: string
  value: string
}
