import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
})
export class CourseCardComponent {
  @Input() _title: string
  @Input() _percentage: number
  @Input() _completed: boolean = false
  @Input() _todo: boolean = false
  @Input() _imageLink: string = ''
  @Input() _url: string
  _buttonText = 'Open Course'
  _buttonColor = 'primary'

  getButtonText(completed: boolean): string {
    return completed ? 'Revisit Course' : 'Open Course'
  }

  getButtonColor(completed: boolean): string {
    return completed ? 'accent' : 'primary'
  }

  redirectToUrl(url: string) {
    if (url === undefined) {
      throw Error('Unable to navigate to url')
    } else {
      window.open(url, '_blank')
    }
  }
}
