import { TableCategoryLinkColumnDTO } from '../table/table-category-link-column-dto'
import { TableLinkButtonColumnDTO } from '../table/table-link-button-column-dto'
import { TableLinkColumnDTO } from '../table/table-link-column-dto'

export class ReviewTableDTO {
  category?: TableCategoryLinkColumnDTO
  fullName?: TableLinkColumnDTO
  reviewType: string
  reviewRule?: string
  createdOn?: string
  comments?: string
  readMore?: TableLinkButtonColumnDTO
  reviewId?: string
  reviewed?: boolean
}
