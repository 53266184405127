/**
 * @description This component is used to test the services that are used to call the Web API
 */

import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core'
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms'
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import { MatDialog } from '@angular/material/dialog'
import * as moment from 'moment'
import { LogLevel } from 'src/app/common/log-level'
import { ClientDto } from 'src/app/models/client/client-dto'
import { ClientTableDTO } from 'src/app/models/client/client-table-dto'
import { ToolSummaryDto } from 'src/app/models/client/client-toolsummary-dto'
import { ClientUpdateDto } from 'src/app/models/client/client-update-dto'
import { FeatureStatusRecord } from 'src/app/models/features/feature-status-record'
import { GenderDto } from 'src/app/models/lookup/gender-dto'
import { QuickViewEventDTO } from 'src/app/models/quickview/quick-view-event-dto'
import { IncorrectSoundTooltipDto } from 'src/app/models/reddi/incorrect-sound-tooltip-dto'
import { ReddiDto, ReddiRoundDto } from 'src/app/models/reddi/reddi-dto'
import { TableColumnDefinition } from 'src/app/models/table/table-column-definition'
import { TableLinkColumnDTO } from 'src/app/models/table/table-link-column-dto'
import { TableQuickViewColumnDTO } from 'src/app/models/table/table-quick-view-column-dto'
import { ApplicationInsightsService } from 'src/app/services/application-insights/application-insights.service'
import { ClientService } from 'src/app/services/client/client.service'
import { ConfigService } from 'src/app/services/config/config.service'
import { FeaturesService } from 'src/app/services/features/features.service'
import { LogService } from 'src/app/services/logger/log.service'
import { LookupService } from 'src/app/services/lookup/lookup.service'
import { ReddiService } from 'src/app/services/reddi/reddi.service'
import { UserService } from 'src/app/services/user-service/user-service.service'
import { ReddiConfigurationDto } from 'src/app/models/reddi/reddi-configuration-dto'
import { ReddiInstanceTableColumnDefinition } from 'src/app/models/table-defs/reddi-instance-table-def'
import { ReddiRoundTableDto } from 'src/app/models/reddi/reddi-round-table-dto'
import { ActivatedRoute } from '@angular/router'
import { BannerService } from 'src/app/services/banner/banner.service'
import { forkJoin } from 'rxjs'
import {
  CreateReddiEditButtonDto,
  CreateReddiRoundTableDto,
  Cue,
  DistanceToDisplayName,
  ListeningCondition,
  VolumeToDisplayName,
} from 'src/app/services/reddi/reddi-utils'
import { CheckBoxData } from '../../../models/reddi/checkbox-data.model'
import { AssessmentGroupDto } from '../../../models/flip/assessment-group-dto'
import { AssessmentItemDto } from '../../../models/flip/assessment-item-dto'
import { ReddiEditRoundModalComponent } from '../../complex/modals/reddi-edit-round-modal/reddi-edit-round-modal.component'
import { FlipItemSummaryComponent } from '../../complex/flip-item-summary/flip-item-summary.component'

@Component({
  selector: 'app-test-services',
  templateUrl: './test-services.component.html',
  styleUrls: ['./test-services.component.scss'],
})
export class TestServicesComponent {
  loginDisplay: any
  testDob: Date = new Date(2020, 1, 1) // 1st of Feb 2020 (JS Months are 0 Indexed)
  maxLength: number = 25

  displayedColumns: string[] = ['claim', 'value', 'description']

  data: MatTableDataSource<ClientTableDTO> =
    new MatTableDataSource<ClientTableDTO>()

  _table2Data: MatTableDataSource<ReddiAssessmentDTO> =
    new MatTableDataSource<ReddiAssessmentDTO>()

  _table3Data: MatTableDataSource<ReddiAssessmentDTO> =
    new MatTableDataSource<ReddiAssessmentDTO>()

  _table4Data: MatTableDataSource<ClientTableDTO> =
    new MatTableDataSource<ClientTableDTO>()

  _table1ColumnsToDisplay: string[] = []
  _table1ColumnDefinitions: TableColumnDefinition[] =
    new Array<TableColumnDefinition>()

  _table2ColumnsToDisplay: string[] = []
  _table2ColumnDefinitions: TableColumnDefinition[] =
    new Array<TableColumnDefinition>()

  _table3ColumnsToDisplay: string[] = []
  _table3ColumnDefinitions: TableColumnDefinition[] =
    new Array<TableColumnDefinition>()

  _table4ColumnsToDisplay: string[] = []
  _table4ColumnDefinitions: TableColumnDefinition[] =
    new Array<TableColumnDefinition>()

  //setup for REDDI Instance
  _age: string
  _dateOfBirth: Date = new Date()
  _dataEnteredBy: string
  _soundEnteredBy: string
  _presentationDate: string
  _considerations: string[]
  _notes: string = 'No notes entered'
  _showClientDetails: boolean = false

  _tableColumnsToDisplay: string[] = []

  allColumns: string[] = [
    'distance',
    'listening',
    'volume',
    'cues',
    'm',
    'or',
    'oo',
    'ah',
    'ee',
    'sh',
    's',
    'edit',
  ]

  exceptSound2: string[] = [
    'distance',
    'listening',
    'volume',
    'cues',
    'm',
    'oo',
    'ah',
    'ee',
    'sh',
    's',
    'edit',
  ]

  _iteration: ReddiDto
  _client: ClientDto
  _configuration: ReddiConfigurationDto

  _tableColumnDefinitions: TableColumnDefinition[] =
    ReddiInstanceTableColumnDefinition

  _tableData: MatTableDataSource<ReddiRoundTableDto> =
    new MatTableDataSource<ReddiRoundTableDto>()

  @ViewChildren('flipItemContainer', { read: ElementRef })
  @ViewChildren('notesSideCard', { read: ElementRef })
  //flip scroll to view
  @ViewChildren(FlipItemSummaryComponent)
  flipItemComponents: QueryList<FlipItemSummaryComponent>
  scrollToItem(itemId: any): void {
    // Find the element based on the itemId
    console.log(`[${Date.now()}] scrollToItem called for item ID: ${itemId}`)
    const component = this.flipItemComponents.find(
      (comp) => comp.number === itemId,
    )

    if (component) {
      console.log(`[${Date.now()}] Found component, starting scroll`)
      component.elm.nativeElement.scrollIntoView({ behavior: 'smooth' })
      component.selected = true

      setTimeout(() => {
        console.log(`[${Date.now()}] Setting hovered state`)
        component.setHoveredState(true)
      }, 300)
    } else {
      console.error(`[${Date.now()}] Component with number ${itemId} not found`)
    }
  }
  summaryItems: QueryList<ElementRef>
  _clientForm = new FormGroup({
    textArea: new FormControl('', [
      Validators.maxLength(this.maxLength),
      this.customValidator.bind(this), // Bind this to customValidator
    ]),
  })

  _reddiRoundDemo = [
    {
      rowButtons: [
        {
          id: 1,
          value: false,
          type: 'detected',
          kind: 'primary',
        },
        {
          id: 2,
          value: false,
          type: 'notdetected',
          kind: 'accent',
        },
        {
          id: 3,
          value: false,
          type: 'correct',
          kind: 'primary',
        },
        {
          id: 4,
          value: false,
          type: 'incorrect',
          kind: 'accent',
        },
      ],
      incorrectSoundTooltips: {
        id: 1,
        value: '',
        showTooltip: false,
        control: new FormControl<string>(''),
      },
    },
    {
      rowButtons: [
        {
          id: 5,
          value: false,
          type: 'detected',
          kind: 'primary',
        },
        {
          id: 6,
          value: false,
          type: 'notdetected',
          kind: 'accent',
        },
        {
          id: 7,
          value: false,
          type: 'correct',
          kind: 'primary',
        },
        {
          id: 8,
          value: false,
          type: 'incorrect',
          kind: 'accent',
        },
      ],
      incorrectSoundTooltips: {
        id: 2,
        value: '',
        showTooltip: false,
        control: new FormControl<string>(''),
      },
    },
  ]

  _checkboxes: CheckBoxData[] = [
    new CheckBoxData(1, 'Everyday', ListeningCondition.Everyday),
    new CheckBoxData(2, 'Left (R device off)', ListeningCondition.Left),
    new CheckBoxData(3, 'Right (L device off)', ListeningCondition.Right),
  ]

  _helpTipTitle: string = 'Help Tip:'

  _helpTipValue =
    'When the child isn’t looking, put something in the microwave and wait for it to beep.  ' +
    'Do they look towards the microwave? When your mobile phone rings, do they look for your bag? This is one of the first crucial steps children  ' +
    'take in attaching meaning to sounds. Ask a friend or relative to knock on the door so you can see if the child looks   ' +
    'at or moves towards the door at the sound.'
  data_collapsible = [
    {
      header: 'Comprehending Language in Different Listening Conditions',
      subItems: [
        {
          number: 'Item 1.1',
          content: 'Follows short directions that are unpredictable or silly',
          score: 'Mostly',
        },
        {
          number: 'Item 1.2',
          content: 'Knows the actions for several different verses of a song',
          score: 'Rarely',
        },
      ],
    },
    {
      header: 'Comprehending Language in Different Listening Conditions',
      subItems: [
        {
          number: 'Item 2.1',
          content: 'Follows short directions that are unpredictable or silly',
          score: 'Mostly',
        },
        {
          number: 'Item 2.2',
          content: 'Knows the actions for several different verses of a song',
          score: 'Rarely',
        },
      ],
    },
  ]
  event: any
  clientList: ClientDto[] = []
  toolSummaries: ToolSummaryDto[]
  featureStatusRecords: FeatureStatusRecord[]
  pageSize: number = 5
  genders: GenderDto[] = []
  listTimeTaken: number = 0
  horizontalPosition: MatSnackBarHorizontalPosition = 'center'
  verticalPosition: MatSnackBarVerticalPosition = 'top'
  durationInSeconds = 5
  selectedDistance: string | null = null
  //added for demo flip item summary
  flipItems = [
    { number: 'Item 1.1', description: 'Description 1', score: 'Mostly' },
    { number: 'Item 1.2', description: 'Description 2', score: 'Rarely' },
    { number: 'Item 1.3', description: 'Description 3', score: 'Mostly' },
    { number: 'Item 1.4', description: 'Description 4', score: 'Rarely' },
    { number: 'Item 1.5', description: 'Description 5', score: 'Mostly' },
    { number: 'Item 1.6', description: 'Description 6', score: 'Rarely' },
  ]

  _flipAssessmentItems1: AssessmentItemDto[] = [
    {
      description:
        'They seem to have a conversation with you. They will babble back and forth (you say something, they babble some sounds, you say something else, they babble some more sounds), as if you are having a conversation. They will stop babbling when you talk, and then when you stop, they will begin babbling again.',
      groupId: '3aa45358-fbf5-e911-a813-000d3a7972c1',
      helpTip:
        'When you are sitting with them, talk to them either using a ‘sing-song’ voice or with some babble sounds. After a few words or sounds, pause and look at them, waiting for them to respond. When they say something to you, respond with more sounds, then wait again for them to take their turn. This is the beginning of conversation, where we take it in turns to listen and talk.',
      itemId: '9b160a98-0bf6-e911-a811-000d3a799fcb',
      name: 'When we’re somewhere noisy, they can have a conversation on the phone, or they can listen to a story on a digital device and answer questions or tell you about it. Some more text to wrap more',
      orderNumber: 80,
      templateId: '988a24b5-faf5-e911-a813-000d3a7972c1',
      itemNumber: '1.1',
      score: null,
    },
    {
      description:
        'When you sing, they may bounce or bob up and down, move their arms or legs, sway from side to side or try to sing along. This is different to their response when you are simply talking or reading them a story.',
      groupId: '3aa45358-fbf5-e911-a813-000d3a7972c1',
      helpTip:
        'Start singing to them, and look for signs that they can tell this is different to when you talk to them. You may see them pause/stop or change what they are doing either when you begin, or finish singing. Participating in these back and forth talking and singing games are an essential part of early conversation skills using language and listening.',
      itemId: '9d160a98-0bf6-e911-a811-000d3a799fcb',
      name: 'Can tell the difference between talking and singing ',
      orderNumber: 90,
      templateId: '988a24b5-faf5-e911-a813-000d3a7972c1',
      itemNumber: '1.2',
    },
  ]

  _flipAssessmentItems2: AssessmentItemDto[] = [
    {
      description:
        'They seem to have a conversation with you. They will babble back and forth (you say something, they babble some sounds, you say something else, they babble some more sounds), as if you are having a conversation. They will stop babbling when you talk, and then when you stop, they will begin babbling again.',
      groupId: '3aa45358-fbf5-e911-a813-000d3a7972c1',
      helpTip:
        'When you are sitting with them, talk to them either using a ‘sing-song’ voice or with some babble sounds. After a few words or sounds, pause and look at them, waiting for them to respond. When they say something to you, respond with more sounds, then wait again for them to take their turn. This is the beginning of conversation, where we take it in turns to listen and talk.',
      itemId: '9b160a98-0bf6-e911-a811-000d3a799fcb',
      name: 'Makes sounds back to me when I talk to them',
      orderNumber: 80,
      templateId: '988a24b5-faf5-e911-a813-000d3a7972c1',
      itemNumber: '1.3',
      score: true,
    },
    {
      description:
        'When you sing, they may bounce or bob up and down, move their arms or legs, sway from side to side or try to sing along. This is different to their response when you are simply talking or reading them a story.',
      groupId: '3aa45358-fbf5-e911-a813-000d3a7972c1',
      helpTip:
        'Start singing to them, and look for signs that they can tell this is different to when you talk to them. You may see them pause/stop or change what they are doing either when you begin, or finish singing. Participating in these back and forth talking and singing games are an essential part of early conversation skills using language and listening.',
      itemId: '9d160a98-0bf6-e911-a811-000d3a799fcb',
      name: 'Can tell the difference between talking and singing',
      orderNumber: 90,
      templateId: '988a24b5-faf5-e911-a813-000d3a7972c1',
      itemNumber: '1.4',
    },
  ]
  number: string = 'Item: 1.1'
  _flipAssessmentGroup: AssessmentGroupDto[] = [
    {
      groupId: 'bcfbcbc3-faf5-e911-a813-000d3a7972c1',
      items: this._flipAssessmentItems1,
      name: 'Associating Sound with Meaning',
      templateId: '988a24b5-faf5-e911-a813-000d3a7972c1',
    },
    {
      groupId: 'e3c5e189-fbf5-e911-a813-000d3a7972c1',
      items: this._flipAssessmentItems2,
      name: 'Listening Through Discourse & Narratives with some extra text to wrap',
      templateId: '988a24b5-faf5-e911-a813-000d3a7972c1',
    },
  ]
  private _title: string

  constructor(
    private clientService: ClientService,
    private featureService: FeaturesService,
    private appInsights: ApplicationInsightsService,
    private snackbar: MatSnackBar,
    private logger: LogService,
    private config: ConfigService,
    private lookupService: LookupService,
    public userService: UserService,
    public reddiService: ReddiService,
    public router: ActivatedRoute,
    private bannerService: BannerService,
    private dialog: MatDialog,
  ) {
    logger.level = this.config.getSettings('logging').logLevel
  }

  onDistanceSelectionChange(selectedValue: string): void {
    this.selectedDistance = selectedValue
    console.log('Selected distance:', this.selectedDistance)
  }

  customValidator(control: AbstractControl) {
    // Your custom validation logic
    // Example: Reject if the text contains special characters
    const isValid = !/[!@#$%^&*(),.?":{}|<>]/.test(control.value)
    return isValid ? null : { containsSpecialChars: true }
  }

  onSubmit() {
    if (this._clientForm.valid) {
      // Submit logic
      console.log(
        'Form is valid. text is ' + this._clientForm.controls.textArea.value, //  this.formGroup.get('textArea').value,
      )
    } else {
      // Handle invalid form
      console.log('Form is invalid. Do something else...')
    }
  }

  myclick(id: number) {
    console.log('id selected = ' + id)
    for (var y = 0; y < this._reddiRoundDemo.length; y++) {
      console.log('Processing row ' + y)
      console.log(
        'incorrectSoundTooltips = ' +
          this._reddiRoundDemo[y].incorrectSoundTooltips.value,
      )
      //Only process the row that was clicked - leave the others alone
      var processThisRow: boolean = false
      for (var t = 0; t < this._reddiRoundDemo[y].rowButtons.length; t++) {
        if (this._reddiRoundDemo[y].rowButtons[t].id === id) {
          processThisRow = true
        }
      }
      if (processThisRow) {
        for (var t = 0; t < this._reddiRoundDemo[y].rowButtons.length; t++) {
          this._reddiRoundDemo[y].rowButtons[t].value =
            this._reddiRoundDemo[y].rowButtons[t].id === id
          if (this._reddiRoundDemo[y].rowButtons[t].id === id && id % 4 == 0) {
            //We have clicked the incorrect button so need to show the incorrect tooltip
            this._reddiRoundDemo[y].incorrectSoundTooltips.showTooltip = true
          } else {
            this._reddiRoundDemo[y].incorrectSoundTooltips.showTooltip = false
          }
        }
      }
    }
  }

  handleIncorrectSoundTooltip(response: IncorrectSoundTooltipDto) {
    console.log(response.text)
  }

  ngAfterViewInit() {
    // Ensure the list is not empty and contains at least two items
    if (this.summaryItems && this.summaryItems.length > 1) {
      const secondItem = this.summaryItems.toArray()[1].nativeElement
      // secondItem.scrollIntoView({ behavior: 'smooth' })
    }
  }

  ngOnInit(): void {
    this.appInsights.logPageView('test-services', '/test-services')

    this._table2Data = this.Table2_LoadData()
    this._table2ColumnsToDisplay = this.Table2_SetColumnsToDisplay()
    this._table2ColumnDefinitions = this.Table2_SetColumnDefinitions()

    this._table3Data = this.Table3_LoadData()
    this._table3ColumnsToDisplay = this.Table3_SetColumnsToDisplay()
    this._table3ColumnDefinitions = this.Table3_SetColumnDefinitions()
  }

  // REDDI instance
  getTableColumns(sound2Enabled: boolean) {
    return sound2Enabled ? this.allColumns : this.exceptSound2
  }

  processRounds(reddi: ReddiDto): ReddiRoundTableDto[] {
    const tableRounds: ReddiRoundTableDto[] = []
    let round: ReddiRoundDto
    const editButtonCallback = (index: number) => {
      this.handleEditRound(round, index)
    }
    for (let r = 0; r < reddi.rounds.length; r++) {
      round = reddi.rounds[r]
      console.log('inside processrounds' + reddi.rounds.length)

      tableRounds.push({
        rowId: r?.toString(),
        distance: DistanceToDisplayName(round.distance),
        listening: ListeningCondition[round.listeningCondition],
        volume: VolumeToDisplayName(round.volume),
        cues: Cue[round.cue],
        m: CreateReddiRoundTableDto(round.sound1Result, round.sound1Imitation),
        or: CreateReddiRoundTableDto(round.sound2Result, round.sound2Imitation),
        oo: CreateReddiRoundTableDto(round.sound3Result, round.sound3Imitation),
        ah: CreateReddiRoundTableDto(round.sound4Result, round.sound4Imitation),
        ee: CreateReddiRoundTableDto(round.sound5Result, round.sound5Imitation),
        sh: CreateReddiRoundTableDto(round.sound6Result, round.sound6Imitation),
        s: CreateReddiRoundTableDto(round.sound7Result, round.sound7Imitation),
        edit: CreateReddiEditButtonDto(editButtonCallback, 0),
      })
    }
    console.log(tableRounds[0].edit)
    return tableRounds
  }

  processRoundsUpdate(reddi: ReddiRoundDto, index: number): ReddiRoundTableDto {
    const editButtonCallback = () => {
      this.handleEditRound(reddi, index)
    }

    console.log('inside updateprocessrounds distance' + reddi.distance)
    console.log('inside updateprocessrounds volume' + reddi.volume)
    console.log(
      'inside updateprocessrounds Listening' + reddi.listeningCondition,
    )
    console.log('inside updateprocessrounds Cues' + reddi.cue)
    console.log('rowid ')

    let round: ReddiRoundTableDto = new ReddiRoundTableDto()
    round.rowId = index?.toString()
    round.distance = DistanceToDisplayName(reddi.distance)
    round.listening = ListeningCondition[reddi.listeningCondition]
    round.volume = VolumeToDisplayName(reddi.volume)
    round.cues = Cue[reddi.cue]
    round.m = CreateReddiRoundTableDto(
      reddi.sound1Result,
      reddi.sound1Imitation,
    )
    round.or = CreateReddiRoundTableDto(
      reddi.sound2Result,
      reddi.sound2Imitation,
    )
    round.oo = CreateReddiRoundTableDto(
      reddi.sound3Result,
      reddi.sound3Imitation,
    )
    round.ah = CreateReddiRoundTableDto(
      reddi.sound4Result,
      reddi.sound4Imitation,
    )
    round.ee = CreateReddiRoundTableDto(
      reddi.sound5Result,
      reddi.sound5Imitation,
    )
    round.sh = CreateReddiRoundTableDto(
      reddi.sound6Result,
      reddi.sound6Imitation,
    )
    round.s = CreateReddiRoundTableDto(
      reddi.sound7Result,
      reddi.sound7Imitation,
    )
    round.edit = CreateReddiEditButtonDto(editButtonCallback, index)

    console.log(round)

    return round
  }

  openEditModal(element: ReddiRoundDto, index: number): void {
    const dialogRef = this.dialog.open(ReddiEditRoundModalComponent, {
      panelClass: 'reddi-edit-round-panel',
      width: '65%',
      data: { roundData: element, rowId: index },
    })

    dialogRef.afterClosed().subscribe((updatedRoundData) => {
      if (updatedRoundData) {
        // Find the round in your table data and update it
        const rowIndex = this._tableData.data.findIndex(
          (row) => row.rowId === updatedRoundData.rowId,
        )
        console.log('rowindex: ' + rowIndex)
        if (rowIndex !== -1) {
          this._tableData.data[rowIndex] = this.processRoundsUpdate(
            updatedRoundData,
            index,
          )
          this.refreshTable()
        }
      }
    })
  }

  //handle edit button for reddi round edit
  handleEditRound(round: ReddiRoundDto, index: number) {
    console.log('Edit button clicked for round:', round)
  }

  // handle flip item summary click event
  handleItemClick(number: string) {
    // Logic to execute when the item is clicked
    alert('Item number clicked!' + number)
    console.log('Item was clicked!' + number)
  }

  /**
   * @description This method is called when the Link Button is clicked
   * @param event Response from the Link Button Click
   */
  onTableLinkButtonClick(event: any) {}

  /**
   * @description This method is called when the Link is clicked
   * @param event Resposne from the Link Click
   */
  onTableLinkClick(event: any) {}

  /**
   * @description This method is called when the Quick View Button is clicked
   * It will emit an event to the parent component
   * @param event Response from the Quick View Button Click
   */
  onQuickViewButtonClick(event: QuickViewEventDTO) {
    // Quick View Button Clicked. Update pages and components as required
  }

  /**
   * @description This method is used to call the List method of the Client Service
   * It will call the Web API and return a list of clients
   * The list will be displayed in the table
   * @param caseManagerContactId
   * @param orderByColumnName
   * @param orderBy
   * @param maxRecords
   * @returns
   */
  list(
    caseManagerContactId: string,
    orderByColumnName: string,
    orderBy: string,
    maxRecords: number,
  ) {
    return this.clientService
      .List(caseManagerContactId, orderByColumnName, orderBy, maxRecords)
      .subscribe({
        next: (response: ClientDto[]) => {
          this.clientList = response as ClientDto[]

          this.data = this.SetClientDataTable(this.clientList)
          this._table1ColumnsToDisplay = this.Table1_SetColumnsToDisplay()
          this._table1ColumnDefinitions = this.Table1_SetColumnDefinitions()
          this._table1ColumnsToDisplay = this.Table1_SetColumnsToDisplay()
          this._table1ColumnDefinitions = this.Table1_SetColumnDefinitions()

          // Load the static table data
          // Load the static table data
          this._table4Data = this.SetClientDataTable(this.clientList)
          this._table4ColumnsToDisplay = this.Table4_SetColumnsToDisplay()
          this._table4ColumnDefinitions = this.Table4_SetColumnDefinitions()
          this._table4ColumnsToDisplay = this.Table4_SetColumnsToDisplay()
          this._table4ColumnDefinitions = this.Table4_SetColumnDefinitions()

          this.logger.log(
            'ClientList completed successfully',
            LogLevel.Debug,
            this.clientList,
          )
          this.snackbar.open(
            'ClientList completed successfully .See console for output',
            'Close',
            {
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              duration: this.durationInSeconds * 1000,
            },
          )
        },
        error: (error: any) => {
          this.snackbar.open(error.message, 'Close', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: this.durationInSeconds * 1000,
          })
          throw error
        },
        complete: () => {},
      })
  }

  org() {
    return this.clientService
      .OrgClients('tsc_preferredfullname', 'Descending', 50)
      .subscribe({
        next: (response: any) => {
          this.clientList = <ClientDto[]>response

          this.logger.log(
            'ClientList completed successfully',
            LogLevel.Debug,
            this.clientList,
          )
          this.snackbar.open(
            'OrgList completed successfully .See console for output',
            'Close',
            {
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              duration: this.durationInSeconds * 1000,
            },
          )
        },
        error: (error: any) => {
          this.snackbar.open(error.message, 'Close', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: this.durationInSeconds * 1000,
          })
          throw error
        },
        complete: () => {},
      })
  }

  details() {
    return this.clientService
      .Details('8731a0b4-8032-ee11-bdf3-002248e41084')
      .subscribe({
        next: (response: any) => {
          console.log(response)
          this.clientList = <ClientDto[]>response
          this.logger.log(
            'ClientList completed successfully',
            LogLevel.Debug,
            this.clientList,
          )
          this.snackbar.open(
            'ClientDetails completed successfully .See console for output',
            'Close',
            {
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              duration: this.durationInSeconds * 1000,
            },
          )
        },
        error: (error: any) => {
          this.snackbar.open(error.message, 'Close', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: this.durationInSeconds * 1000,
          })
          throw error
        },
        complete: () => {},
      })
  }

  toolsummaries() {
    return this.clientService
      .GetToolSummaries('8731a0b4-8032-ee11-bdf3-002248e41084', 'ALL')
      .subscribe({
        next: (response: any) => {
          this.toolSummaries = <ToolSummaryDto[]>response
          this.logger.log(
            'ClientList completed successfully',
            LogLevel.Debug,
            this.toolsummaries,
          )
          this.snackbar.open(
            'Tool Summaries completed successfully .See console for output',
            'Close',
            {
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              duration: this.durationInSeconds * 1000,
            },
          )
        },
        error: (error: any) => {
          this.snackbar.open(error.message, 'Close', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: this.durationInSeconds * 1000,
          })
          throw error
        },
        complete: () => {},
      })
  }

  create() {
    var client = new ClientDto()
    client.firstname = 'User'
    client.lastname = 'Interface'
    client.genderCode = 1
    client.dateOfBirth = new Date(2023, 1, 1)
    client.clientCaseManagerId = '0708d85d-c030-ee11-bdf3-002248e41084'

    return this.clientService.CreateClient(client).subscribe({
      next: (response: any) => {
        this.toolSummaries = <ToolSummaryDto[]>response
        this.logger.log(
          'ClientList completed successfully',
          LogLevel.Debug,
          this.toolsummaries,
        )
        this.snackbar.open(
          'CreateClient completed successfully .See console for output',
          'Close',
          {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: this.durationInSeconds * 1000,
          },
        )
      },
      error: (error: any) => {
        this.snackbar.open(error.message, 'Close', {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          duration: this.durationInSeconds * 1000,
        })
        throw error
      },
      complete: () => {},
    })
  }

  update() {
    var client = new ClientUpdateDto()
    client.clientCaseManagerId = '0708d85d-c030-ee11-bdf3-002248e41084'
    client.clientPrimaryCentreId = null
    client.contactId = '8731a0b4-8032-ee11-bdf3-002248e41084'
    client.dateOfBirth = new Date(2023, 1, 2)
    client.firstname = 'Charley'
    client.genderCode = 1
    client.lastname = 'Witherspoon'

    return this.clientService.UpdateClient(client).subscribe({
      next: (response: any) => {
        this.toolSummaries = <ToolSummaryDto[]>response
        this.logger.log(
          'ClientList completed successfully',
          LogLevel.Debug,
          this.toolSummaries,
        )
        this.snackbar.open(
          'UpdateClient completed successfully .See console for output',
          'Close',
          {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: this.durationInSeconds * 1000,
          },
        )
      },
      error: (error: any) => {
        this.snackbar.open(error.message, 'Close', {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          duration: this.durationInSeconds * 1000,
        })
        throw error
      },
      complete: () => {},
    })
  }

  testLog(): void {
    this.logger.log('Test the `log()` Method', LogLevel.Debug)
  }

  getFeatures() {
    return this.featureService.GetFeatures().subscribe({
      next: (response: any) => {
        this.featureStatusRecords = <FeatureStatusRecord[]>response
        this.logger.log(
          'Retrieve of Features from WebAPI completed successfully: this.featureStatusRecords. Feature Name = ' +
            this.featureStatusRecords[0].name,
          LogLevel.Debug,
          this.featureStatusRecords,
        )
        const message =
          'Retrieve of Features from WebAPI completed successfully. See console for output. Feature Name = ' +
          this.featureStatusRecords[0].name
        this.snackbar.open(message, 'Close', {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          duration: this.durationInSeconds * 1000,
        })
      },
      error: (error: any) => {
        this.snackbar.open(error.message, 'Close', {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          duration: this.durationInSeconds * 1000,
        })
        throw error
      },
      complete: () => {},
    })
  }

  getIteration() {
    return this.reddiService
      .getReddiIteration('e0c82d87-b94b-ea11-a812-000d3a794e59')
      .subscribe({
        next: (response: any) => {
          let reddi = <ReddiDto>response
          this.logger.log(
            'Got reddi iteration: ' + reddi.iteration.iterationId,
            LogLevel.Debug,
            reddi,
          )

          console.log(reddi)
          console.log(Cue[reddi.rounds[0].cue])
          const message = 'Got reddi iteration: ' + reddi.iteration.iterationId
          this.snackbar.open(message, 'Close', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: this.durationInSeconds * 1000,
          })
        },
        error: (error: any) => {
          this.snackbar.open(error.message, 'Close', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: this.durationInSeconds * 1000,
          })
          throw error
        },
        complete: () => {},
      })
  }

  LookupGetGenders() {
    return this.lookupService.Genders().subscribe({
      next: (response: any) => {
        this.genders = <GenderDto[]>response
        this.logger.log(
          'Get Genders completed successfully',
          LogLevel.Debug,
          this.genders,
        )
        this.snackbar.open(
          'Get Genders completed successfully .See console for output',
          'Close',
          {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: this.durationInSeconds * 1000,
          },
        )
      },
      error: (error: any) => {
        this.snackbar.open(error.message, 'Close', {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          duration: this.durationInSeconds * 1000,
        })
        throw error
      },
      complete: () => {},
    })
  }

  private refreshTable() {
    this._tableData = new MatTableDataSource([...this._tableData.data]) // Refresh the table data source
  }

  private Table1_SetColumnDefinitions(): Array<TableColumnDefinition> {
    const columnDefinitions = new Array<TableColumnDefinition>()
    columnDefinitions.push({
      columnName: 'preferredFullName',
      header: 'Full Name',
      sortDisabled: true,
      columnType: 'link',
      columnWidth: '30%',
      columnTextAlign: 'left',
      linkColor: 'primary',
      linkKind: 'standard',
      showIcon: false,
    })

    columnDefinitions.push({
      columnName: 'dateOfBirth',
      columnWidth: '10%',
      columnTextAlign: 'left',
      header: 'DOB',
      sortDisabled: true,
      columnType: 'string',
    })
    columnDefinitions.push({
      columnName: 'clientPrimaryCentreName',
      columnWidth: '30%',
      columnTextAlign: 'left',
      header: 'Location',
      sortDisabled: true,
      columnType: 'string',
    })
    columnDefinitions.push({
      columnName: 'age',
      columnWidth: '10%',
      columnTextAlign: 'left',
      header: 'Age',
      sortDisabled: true,
      columnType: 'string',
    })
    columnDefinitions.push({
      columnName: 'quickView',
      columnWidth: '10%',
      textAlign: 'left',
      header: '',
      sortDisabled: true,
      columnType: 'quick-view-button',
      columnTextAlign: 'Quick View',
      linkButtonColor: 'accent',
      linkButtonType: 'button',
    })
    columnDefinitions.push({
      columnName: 'assessmentDate',
      columnWidth: '10%',
      columnTextAlign: 'left',
      header: '',
      sortDisabled: true,
      columnType: 'string',
      linkText: 'Assessment Date',
    })

    columnDefinitions.push({
      columnName: 'score',
      columnWidth: '10%',
      columnTextAlign: 'left',
      header: '',
      sortDisabled: true,
      columnType: 'string',
      linkText: 'Score',
    })

    columnDefinitions.push({
      columnName: 'scoreWithinExpectedRange',
      columnWidth: '10%',
      columnTextAlign: 'left',
      header: '',
      sortDisabled: true,
      columnType: 'string',
      linkText: 'Score within expected range',
    })

    columnDefinitions.push({
      columnName: 'dataEnteredBy',
      columnWidth: '10%',
      columnTextAlign: 'left',
      header: '',
      sortDisabled: true,
      columnType: 'string',
      linkText: 'Data entered by',
    })
    columnDefinitions.push({
      columnName: 'notes',
      columnWidth: '30%',
      columnTextAlign: 'left',
      header: '',
      sortDisabled: true,
      columnType: 'string',
      linkText: 'Notes',
    })

    columnDefinitions.push({
      columnName: 'viewDetails',
      columnWidth: '10%',
      columnTextAlign: 'left',
      header: '',
      sortDisabled: true,
      columnType: 'link',
      linkText: 'View details',
      linkKind: 'icon',
      linkColor: 'primary',
      showIcon: true,
    })
    return columnDefinitions
  }

  private Table1_SetColumnsToDisplay(): string[] {
    const columnsToDisplay = [
      'preferredFullName',
      'dateOfBirth',
      'clientPrimaryCentreName',
      'age',
      'quickView',
    ]
    return columnsToDisplay
  }

  private Table2_SetColumnDefinitions(): Array<TableColumnDefinition> {
    const columnDefinitions = new Array<TableColumnDefinition>()
    columnDefinitions.push({
      columnName: 'assessmentDate',
      header: 'Assessment date',
      columnWidth: '20%',
      columnTextAlign: 'left',
      sortDisabled: true,
      columnType: 'string',
      linkText: 'Assessment Date',
    })

    columnDefinitions.push({
      columnName: 'score',
      columnWidth: '20%',
      columnTextAlign: 'left',
      header: 'Score',
      sortDisabled: true,
      columnType: 'string',
      linkText: 'Score',
    })

    columnDefinitions.push({
      columnName: 'scoreWithinExpectedRange',
      columnWidth: '30%',
      columnTextAlign: 'left',
      header: 'Score within expected range',
      sortDisabled: true,
      columnType: 'string',
      linkText: 'Score within expected range',
    })

    columnDefinitions.push({
      columnName: 'viewDetails',
      columnWidth: '10%',
      textAlign: 'left',
      header: '',
      sortDisabled: true,
      columnType: 'link',
      columnTextAlign: 'View details',
      linkKind: 'icon',
      linkColor: 'primary',
      showIcon: true,
    })
    return columnDefinitions
  }

  private Table2_SetColumnsToDisplay(): string[] {
    const columnsToDisplay = [
      'assessmentDate',
      'score',
      'scoreWithinExpectedRange',
      'viewDetails',
    ]
    return columnsToDisplay
  }

  private Table2_LoadData(): MatTableDataSource<ReddiAssessmentDTO> {
    const reddiAssessmentList: ReddiAssessmentDTO[] =
      new Array<ReddiAssessmentDTO>()
    let dataSource: MatTableDataSource<ReddiAssessmentDTO>

    for (let i = 0; i < 3; i++) {
      let reddiAssessment = new ReddiAssessmentDTO()
      reddiAssessment.assessmentDate = moment().format('D MMMM YYYY')
      reddiAssessment.score = '1' + i
      if (i % 2 == 0) reddiAssessment.scoreWithinExpectedRange = 'Below'
      else reddiAssessment.scoreWithinExpectedRange = 'Above'
      reddiAssessment.viewDetails = new TableLinkColumnDTO()
      reddiAssessment.viewDetails.linkText = 'View details'
      reddiAssessment.viewDetails.linkUrl = '/report'
      reddiAssessment.viewDetails.linkData = 'User ' + i
      reddiAssessmentList.push(reddiAssessment)
    }

    dataSource = new MatTableDataSource<ReddiAssessmentDTO>(reddiAssessmentList)
    return dataSource
  }

  private Table3_SetColumnDefinitions(): Array<TableColumnDefinition> {
    const columnDefinitions = new Array<TableColumnDefinition>()
    columnDefinitions.push({
      columnName: 'assessmentDate',
      columnWidth: '10%',
      columnTextAlign: 'left',
      header: 'Assessment Date',
      sortDisabled: true,
      columnType: 'string',
      linkText: 'Assessment Date',
    })

    columnDefinitions.push({
      columnName: 'score',
      columnWidth: '10%',
      columnTextAlign: 'center',
      header: 'Score',
      sortDisabled: true,
      columnType: 'string',
      linkText: 'Score',
    })

    columnDefinitions.push({
      columnName: 'scoreWithinExpectedRange',
      columnWidth: '20%',
      columnTextAlign: 'center',
      header: 'Score within expected range',
      sortDisabled: true,
      columnType: 'string',
      linkText: 'Score within expected range',
    })

    columnDefinitions.push({
      columnName: 'dataEnteredBy',
      columnWidth: '10%',
      columnTextAlign: 'center',
      header: 'Data entered by',
      sortDisabled: true,
      columnType: 'string',
    })

    columnDefinitions.push({
      columnName: 'notes',
      columnWidth: '40%',
      columnTextAlign: 'left',
      header: 'Notes',
      sortDisabled: true,
      columnType: 'string',
    })

    columnDefinitions.push({
      columnName: 'viewDetails',
      columnWidth: '10%',
      columnTextAlign: 'left',
      header: '',
      sortDisabled: true,
      columnType: 'link',
      linkText: 'View details',
      linkKind: 'icon',
      linkColor: 'primary',
      showIcon: true,
    })
    return columnDefinitions
  }

  private Table3_SetColumnsToDisplay(): string[] {
    const columnsToDisplay = [
      'assessmentDate',
      'score',
      'scoreWithinExpectedRange',
      'dataEnteredBy',
      'notes',
      'viewDetails',
    ]
    return columnsToDisplay
  }

  private Table3_LoadData(): MatTableDataSource<ReddiAssessmentDTO> {
    const reddiAssessmentList: ReddiAssessmentDTO[] =
      new Array<ReddiAssessmentDTO>()
    let dataSource: MatTableDataSource<ReddiAssessmentDTO>

    for (let i = 0; i < 120; i++) {
      let reddiAssessment = new ReddiAssessmentDTO()
      reddiAssessment.assessmentDate = moment().format('Do MMM, YYYY')
      reddiAssessment.score = '1'
      if (i % 2 == 0) reddiAssessment.scoreWithinExpectedRange = 'Below'
      else reddiAssessment.scoreWithinExpectedRange = 'Above'
      reddiAssessment.dataEnteredBy = 'User ' + i
      reddiAssessment.notes =
        'lorem ipsum lopem ipsum lorem ipsum lorem lorem ipsum lopem ipsum lorem ipsum lorem lorem ipsum lopem ipsum lorem ipsum lorem' +
        i
      reddiAssessment.viewDetails = new TableLinkColumnDTO()
      reddiAssessment.viewDetails.linkText = 'View details'
      reddiAssessment.viewDetails.linkUrl = '/report'
      reddiAssessment.viewDetails.linkData = 'User ' + i
      reddiAssessment.viewDetails.selected = false

      reddiAssessmentList.push(reddiAssessment)
    }
    dataSource = new MatTableDataSource<ReddiAssessmentDTO>(reddiAssessmentList)
    return dataSource
  }

  private Table4_SetColumnsToDisplay(): string[] {
    const columnsToDisplay = [
      'preferredFullName',
      'dateOfBirth',
      'clientPrimaryCentreName',
      'age',
      'quickView',
    ]
    return columnsToDisplay
  }

  private loadData() {
    const clientId: string = '8731a0b4-8032-ee11-bdf3-002248e41084' // this.router.snapshot.url[2].path
    const iterationId: string = '12c111f2-9c32-ee11-bdf3-002248e41084' // this.router.snapshot.url[3].path
    console.log(iterationId)
    let request = {
      iteration: this.reddiService.getReddiIteration(iterationId),
      client: this.clientService.Details(clientId),
      configuration: this.reddiService.getConfiguration(),
    }

    forkJoin(request).subscribe({
      next: ({ iteration, client, configuration }) => {
        this._iteration = iteration
        this._client = client
        this._configuration = configuration
        console.log(this._iteration)
        //this.processSidebarComponents(iteration)
        this._tableData = new MatTableDataSource<ReddiRoundTableDto>(
          this.processRounds(iteration),
        )

        this._tableColumnsToDisplay = this.getTableColumns(
          !this._configuration.ignoreSound2,
        )

        this._title = this._client.preferredFullName
        this._age = this._client.age
        this._dateOfBirth = this._client.dateOfBirth

        this._showClientDetails = true
      },
      error: () => {
        this.bannerService.showBanner(
          `Unable to retrieve data.`,
          'error',
          30000,
        )
      },
      complete: () => {},
    })
  }

  private Table4_SetColumnDefinitions(): Array<TableColumnDefinition> {
    const columnDefinitions = new Array<TableColumnDefinition>()
    columnDefinitions.push({
      columnName: 'preferredFullName',
      header: 'Full Name',
      sortDisabled: true,
      columnType: 'link',
      columnWidth: '30%',
      columnTextAlign: 'left',
      linkColor: 'primary',
      linkKind: 'standard',
      showIcon: false,
    })

    columnDefinitions.push({
      columnName: 'dateOfBirth',
      columnWidth: '10%',
      columnTextAlign: 'left',
      header: 'DOB',
      sortDisabled: true,
      columnType: 'string',
    })
    columnDefinitions.push({
      columnName: 'clientPrimaryCentreName',
      columnWidth: '30%',
      columnTextAlign: 'left',
      header: 'Location',
      sortDisabled: true,
      columnType: 'string',
    })
    columnDefinitions.push({
      columnName: 'age',
      columnWidth: '10%',
      columnTextAlign: 'left',
      header: 'Age',
      sortDisabled: true,
      columnType: 'string',
    })
    columnDefinitions.push({
      columnName: 'quickView',
      columnWidth: '10%',
      columnTextAlign: 'left',
      header: '',
      sortDisabled: true,
      columnType: 'quick-view-button',
      linkText: 'Quick View',
      linkButtonColor: 'accent',
      linkButtonType: 'button',
    })
    columnDefinitions.push({
      columnName: 'assessmentDate',
      columnWidth: '10%',
      columnTextAlign: 'left',
      header: '',
      sortDisabled: true,
      columnType: 'string',
      linkText: 'Assessment Date',
    })

    columnDefinitions.push({
      columnName: 'score',
      columnWidth: '10%',
      columnTextAlign: 'left',
      header: '',
      sortDisabled: true,
      columnType: 'string',
      linkText: 'Score',
    })

    columnDefinitions.push({
      columnName: 'scoreWithinExpectedRange',
      columnWidth: '10%',
      columnTextAlign: 'left',
      header: '',
      sortDisabled: true,
      columnType: 'string',
      linkText: 'Score within expected range',
    })

    columnDefinitions.push({
      columnName: 'dataEnteredBy',
      columnWidth: '10%',
      columnTextAlign: 'left',
      header: '',
      sortDisabled: true,
      columnType: 'string',
      linkText: 'Data entered by',
    })
    columnDefinitions.push({
      columnName: 'notes',
      columnWidth: '30%',
      columnTextAlign: 'left',
      header: '',
      sortDisabled: true,
      columnType: 'string',
      linkText: 'Notes',
    })

    columnDefinitions.push({
      columnName: 'viewDetails',
      columnWidth: '10%',
      columnTextAlign: 'left',
      header: '',
      sortDisabled: true,
      columnType: 'link',
      linkText: 'View details',
      linkKind: 'icon',
      linkColor: 'primary',
      showIcon: true,
    })
    return columnDefinitions
  }

  private SetClientDataTable(
    clientList: ClientDto[],
  ): MatTableDataSource<ClientTableDTO> {
    const clientTableList: ClientTableDTO[] = new Array<ClientTableDTO>()
    let dataSource: MatTableDataSource<ClientTableDTO> =
      new MatTableDataSource<ClientTableDTO>()

    // Create a new list that includes the extra fields
    clientList.forEach((cl) => {
      const client = new ClientTableDTO()
      client.age = cl.age
      client.clientPrimaryCentreName = cl.clientPrimaryCentreName
      client.dateOfBirth = moment(cl.dateOfBirth).format('Do MMM, YYYY')
      client.genderCodeName = cl.genderCodeName

      client.preferredFullName = new TableLinkColumnDTO()
      client.preferredFullName.linkText = cl.preferredFullName
      client.preferredFullName.linkUrl = '/courses?clientId=' + cl.contactId
      client.preferredFullName.linkData = cl.contactId
      client.preferredFullName.selected = false

      client.quickView = new TableQuickViewColumnDTO()
      client.quickView.linkText = 'Quick View'
      client.quickView.linkData = cl.contactId
      client.quickView.selected = false

      client.viewReport = new TableLinkColumnDTO()
      client.viewReport.linkColor = 'accent'
      client.viewReport.linkText = 'View details'
      client.viewReport.linkUrl = '/report?clientId=' + cl.contactId
      client.viewReport.linkData = cl.contactId
      client.viewReport.selected = false

      clientTableList.push(client)
    })

    dataSource = new MatTableDataSource<ClientTableDTO>(clientTableList)
    return dataSource
  }
}

export class ReddiAssessmentDTO {
  assessmentDate: string
  score: string
  scoreWithinExpectedRange: string
  dataEnteredBy?: string
  notes?: string
  viewDetails?: TableLinkColumnDTO
}
