import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ReddiDto } from 'src/app/models/reddi/reddi-dto'

@Component({
  selector: 'app-reddi-round-edit-button',
  templateUrl: './reddi-round-edit-button.component.html',
  styleUrls: ['./reddi-round-edit-button.component.scss'],
})
export class ReddiRoundEditButtonComponent {
  @Input() rowIndex: number // Accept row index as input
  @Output() editClick = new EventEmitter<number>() // Emit number

  onEditClick() {
    this.editClick.emit(this.rowIndex) // Emit the row index when clicked
  }
}
