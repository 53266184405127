import { ChangeDetectorRef, Component, ViewChild } from '@angular/core'
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'
import { forkJoin } from 'rxjs'
import { ClientDto } from 'src/app/models/client/client-dto'
import { Datum } from 'src/app/models/common/datum'
import { Step } from 'src/app/models/common/reddi-steps'
import { CheckBoxData } from 'src/app/models/reddi/checkbox-data.model'
import { ReddiConfigurationDto } from 'src/app/models/reddi/reddi-configuration-dto'
import {
  ReddiDto,
  ReddiIterationDto,
  ReddiRoundDto,
} from 'src/app/models/reddi/reddi-dto'
import { ReddiRoundTableDto } from 'src/app/models/reddi/reddi-round-table-dto'
import { ReddiInstanceTableColumnDefinition } from 'src/app/models/table-defs/reddi-instance-table-def'
import { TableColumnDefinition } from 'src/app/models/table/table-column-definition'
import { BannerService } from 'src/app/services/banner/banner.service'
import { ClientService } from 'src/app/services/client/client.service'
import {
  CreateReddiEditButtonDto,
  CreateReddiRoundTableDto,
  Cue,
  Distance,
  DistanceToDisplayName,
  ImpactingFactor,
  ListeningCondition,
  PresenterRole,
  Result,
  Volume,
  VolumeToDisplayName,
} from 'src/app/services/reddi/reddi-utils'
import { ReddiService } from 'src/app/services/reddi/reddi.service'
import { SpinnerService } from 'src/app/services/spinner/spinner.service'
import { UserService } from 'src/app/services/user-service/user-service.service'
import IsUUID from 'validator/es/lib/isUUID'
import { ReddiEditRoundModalComponent } from '../../complex/modals/reddi-edit-round-modal/reddi-edit-round-modal.component'
import { MatDialog } from '@angular/material/dialog'
import { AreYouSureModalComponent } from '../../complex/modals/are-you-sure-modal/are-you-sure-modal.component'
import { PreviousRouteService } from 'src/app/services/previous-route/previous-route.service'
import { ReddiRoundsStepperComponent } from '../../core/reddi-rounds-stepper/reddi-rounds-stepper.component'
import { LookupService } from 'src/app/services/lookup/lookup.service'
import { CaseManagerDto } from 'src/app/models/lookup/case-manager-dto'

interface ButtonConfig {
  id: number
  value: boolean
  type: string
  kind: string
}

interface TooltipConfig {
  id: number
  value: string
  showTooltip: boolean
  control: FormControl<string | null>
}

interface SoundRow {
  name: string
  rowButtons: ButtonConfig[]

  incorrectSoundTooltip: TooltipConfig
}

@Component({
  selector: 'app-reddi-assessment',
  templateUrl: './reddi-assessment.component.html',
  styleUrls: ['./reddi-assessment.component.scss'],
})
export class ReddiAssessmentComponent {
  @ViewChild(ReddiRoundsStepperComponent)
  private stepperComponent: ReddiRoundsStepperComponent
  reddiForm: FormGroup
  _clientId: string
  _clientName: string = 'Loading...'
  _title: string = '\u00A0'
  _age: string
  _dateOfBirth: Date = new Date()
  _dataEnteredBy: string
  _soundEnteredBy: string
  _soundEnteredByName: string
  _presentationDate: string
  _notes: string = 'No notes entered'
  _showClientDetails: boolean = false
  _tableColumnsToDisplay: string[] = []
  _noDataText = ''
  _iteration: ReddiDto = new ReddiDto()
  _dateControl = new FormControl(new Date())
  _selectedDistance: string
  _soundPresentedByControl = new FormControl('')
  _notesControl = new FormControl('')
  _considerationsControl = new FormControl('')
  _selectedsoundPresentedBy: string
  _notesValue: string
  _sounds: string[]
  isSound2: boolean
  isAnyButtonSelected: boolean = false
  sounds: SoundRow[]
  private _selectedListeningOptions: number
  private _selectedVolumeOptions: number
  private _selectedCuesOptions: number
  private _selectedConsiderationOptions: ImpactingFactor[]
  _caseManagers: CaseManagerDto[] = []
  allColumns: string[] = [
    'distance',
    'listening',
    'volume',
    'cues',
    'm',
    'or',
    'oo',
    'ah',
    'ee',
    'sh',
    's',
    'edit',
  ]

  exceptSound2: string[] = [
    'distance',
    'listening',
    'volume',
    'cues',
    'm',
    'oo',
    'ah',
    'ee',
    'sh',
    's',
    'edit',
  ]

  except2Sounds: string[] = ['m', 'oo', 'ah', 'ee', 'sh', 's']
  allSounds: string[] = ['m', 'or', 'oo', 'ah', 'ee', 'sh', 's']

  _checkboxesListening: CheckBoxData[] = [
    new CheckBoxData(1, 'Everyday', ListeningCondition.Everyday),
    new CheckBoxData(2, 'Left (R device off)', ListeningCondition.Left),
    new CheckBoxData(3, 'Right (L device off)', ListeningCondition.Right),
  ]

  _checkboxesCues: CheckBoxData[] = [
    new CheckBoxData(4, 'None', Cue.None),
    new CheckBoxData(5, 'Visual', Cue.Visual),
    new CheckBoxData(6, 'Auditory', Cue.Auditory),
  ]

  _checkboxesVolume: CheckBoxData[] = [
    new CheckBoxData(
      7,
      'Everyday conversation (<55 dBSPL)',
      Volume.EverydayConversation,
    ),
    new CheckBoxData(8, 'Loud (>=55 dBSPL)', Volume.Loud),
  ]

  public _considerations: CheckBoxData[] = [
    new CheckBoxData(
      9,
      'Confirmed middle ear pathology',
      ImpactingFactor.ConfirmedMiddleEarPathology,
      false,
    ),
    new CheckBoxData(
      10,
      'Suspected middle ear pathology',
      ImpactingFactor.SuspectedMiddleEarPathology,
      false,
    ),
    new CheckBoxData(
      11,
      'Child unsettled / unwell',
      ImpactingFactor.ChildUnsettledOrUnwell,
      false,
    ),
  ]
  _soundPresentedBy: Datum[] = [
    { label: 'Professional', value: PresenterRole.Professional.toString() },
    {
      label: 'Caregiver or Family',
      value: PresenterRole.CaregiverOrFamily.toString(),
    },
  ]

  _client: ClientDto
  _configuration: ReddiConfigurationDto

  _tableColumnDefinitions: TableColumnDefinition[] =
    ReddiInstanceTableColumnDefinition

  _tableData: MatTableDataSource<ReddiRoundTableDto> =
    new MatTableDataSource<ReddiRoundTableDto>()

  currentStep: Step = {
    setupTitle: `Round 1 set-up`,
    responseTitle: `Round 1 response`,
  }
  currentStepIndex: number = 0
  volumeArrayTouched: boolean = false
  private _dashboardUrl: string

  constructor(
    public formBuilder: FormBuilder,
    public userService: UserService,
    protected lookupService: LookupService,
    public router: Router,
    public spinnerService: SpinnerService,
    public activatedRouter: ActivatedRoute,
    public clientService: ClientService,
    public bannerService: BannerService,
    public reddiService: ReddiService,
    public dialog: MatDialog,
    public previousRouteService: PreviousRouteService,
    private changeDetctor: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isAnyButtonSelected = false
    this._dateControl = new FormControl(
      new Date(),
      Validators.compose([Validators.required, this.pastOrTodayDateValidator]),
    )
    this.reddiForm = this.formBuilder.group({
      listeningConditions: this.formBuilder.array(
        this._checkboxesListening.map(() => this.formBuilder.control(false)),
        this.minSelectedCheckboxes(1),
      ),
      volume: this.formBuilder.array(
        this._checkboxesVolume.map(() => this.formBuilder.control(false)),
        this.minSelectedCheckboxes(1),
      ),
      cues: this.formBuilder.array(
        this._checkboxesCues.map(() => this.formBuilder.control(false)),
        this.minSelectedCheckboxes(1),
      ),
      considerations: this.formBuilder.array(
        this._considerations.map(() => this.formBuilder.control(false)),
      ),
      distance: [null, Validators.required],
      _dateControl: this._dateControl,
    })

    this._soundPresentedByControl.setValue(this._soundPresentedBy[0].value)
    this._selectedsoundPresentedBy = this._soundPresentedByControl.value
    this._soundPresentedByControl.valueChanges.subscribe((selectedValue) => {
      this._selectedsoundPresentedBy = selectedValue
    })
    this._notesControl.valueChanges.subscribe((value) => {
      this._notesValue = value
    })
    this._clientId = this.activatedRouter.snapshot.url[2].path
    this._dashboardUrl = 'client-dashboard/' + this._clientId
    this._tableData = new MatTableDataSource<ReddiRoundTableDto>([])
    let request = {
      client: this.clientService.Details(this._clientId),
      configuration: this.reddiService.getConfiguration(),
      caseManagers: this.lookupService.CaseManagers(),
    }

    this.spinnerService.show()
    forkJoin(request).subscribe({
      next: ({ client, configuration, caseManagers }) => {
        this._client = client
        this._configuration = configuration
        this._clientName = client.preferredFullName
        this._age = this._client.age
        this._dateOfBirth = this._client.dateOfBirth
        this._soundEnteredBy = this.userService.getPreferredUsername()
        this._soundEnteredByName = this.userService.GetUserName()
        console.log('preferedusername: ' + this._soundEnteredBy)
        this.initializeComponentBasedOnConfiguration()
        this._title = this._client.preferredFullName
        this._age = this._client.age
        this._dateOfBirth = this._client.dateOfBirth
        this._showClientDetails = true
        this._tableColumnsToDisplay = this.getTableColumns(
          !this._configuration.ignoreSound2,
        )
        this._caseManagers = caseManagers
        this._soundEnteredByName = this.getFulNameByPreferredtName(
          this._soundEnteredBy,
        )
      },
      error: () => {
        this.bannerService.showBanner(
          `Unable to retrieve data.`,
          'error',
          30000,
        )
        this.spinnerService.hide()
      },
      complete: () => {
        this.spinnerService.hide()
      },
    })

    this.refreshTable()
  }

  pastOrTodayDateValidator(
    control: FormControl,
  ): { [key: string]: any } | null {
    const today = new Date()
    today.setHours(0, 0, 0, 0) // Set today to the start of the day

    const selectedDate = new Date(control.value)
    selectedDate.setHours(0, 0, 0, 0) // Set selectedDate to the start of the day

    if (selectedDate > today) {
      // If the selected date is in the future
      console.log('Invalid date: Future date selected')
      return { futureDate: true }
    }

    return null // If valid (today or in the past)
  }

  get considerationsControls() {
    return this.reddiForm.get('considerations') as FormArray
  }
  private minSelectedCheckboxes(min = 1): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // Ensure the control is a FormArray
      if (!(control instanceof FormArray)) {
        throw new Error('minSelectedCheckboxes must be used with a FormArray')
      }

      const totalSelected = control.controls
        .map((ctrl) => ctrl.value)
        .reduce((prev, next) => (next ? prev + next : prev), 0)

      return totalSelected >= min ? null : { required: true }
    }
  }

  initializeComponentBasedOnConfiguration(): void {
    this._sounds = this.getSounds(!this._configuration.ignoreSound2)
    this.sounds = this.createSoundRows() // Create rows after _sounds is initialized
  }

  createSoundRows(): SoundRow[] {
    return this._sounds?.map((name, rowIndex) => ({
      name: name,
      rowButtons: [
        // Assuming 4 buttons per row
        {
          id: rowIndex * 4 + 1,
          value: false,
          type: 'detected',
          kind: 'primary',
        },
        {
          id: rowIndex * 4 + 2,
          value: false,
          type: 'notdetected',
          kind: 'accent',
        },
        {
          id: rowIndex * 4 + 3,
          value: false,
          type: 'correct',
          kind: 'primary',
        },
        {
          id: rowIndex * 4 + 4,
          value: false,
          type: 'incorrect',
          kind: 'accent',
        },
      ],
      incorrectSoundTooltip: {
        id: rowIndex,
        value: '',
        showTooltip: false,
        control: new FormControl<string>(''),
      },
    }))
  }

  getTableColumns(sound2Enabled: boolean) {
    return sound2Enabled ? this.allColumns : this.exceptSound2
  }

  getSounds(sound2Enabled: boolean) {
    return sound2Enabled ? this.allSounds : this.except2Sounds
  }
  myclick(id: number) {
    // Determine the row index based on the button ID
    let rowIndex = Math.floor((id - 1) / 4)
    // Process only the row that contains the clicked button
    let row = this.sounds[rowIndex]
    if (row) {
      row.rowButtons.forEach((button) => {
        button.value = button.id === id // Activate only the clicked button
      })

      // Show tooltip if the last button in the row is clicked
      row.incorrectSoundTooltip.showTooltip = id % 4 === 0
    }

    this.isAnyButtonSelected = this.sounds.some((sound) =>
      sound.rowButtons.some((button) => button.value),
    )
  }

  handleButtonClick(button: ButtonConfig): void {
    button.value = !button.value // Toggle the value for the button
  }

  clearSoundData(rowId: number): void {
    // Find the specific sound row by its index
    const soundRow = this.sounds[rowId]

    if (!soundRow) {
      return // If the row is not found, exit the function
    }
    // Reset all button values to false for this specific row
    soundRow.rowButtons.forEach((button) => {
      button.value = false
    })

    // Hide the tooltip and clear the form control value for this specific row
    soundRow.incorrectSoundTooltip.showTooltip = false
    soundRow.incorrectSoundTooltip.control.setValue('')
    this.isAnyButtonSelected = this.sounds.some((sound) =>
      sound.rowButtons.some((button) => button.value),
    )
  }

  processRounds(reddi: ReddiDto): ReddiRoundTableDto[] {
    const tableRounds: ReddiRoundTableDto[] = []
    let round: ReddiRoundDto
    for (let r = 0; r < reddi.rounds.length; r++) {
      round = reddi.rounds[r]
      tableRounds.push({
        distance: DistanceToDisplayName(round.distance),
        listening: ListeningCondition[round.listeningCondition],
        volume: VolumeToDisplayName(round.volume),
        cues: Cue[round.cue],
        m: CreateReddiRoundTableDto(round.sound1Result, round.sound1Imitation),
        or: CreateReddiRoundTableDto(round.sound2Result, round.sound2Imitation),
        oo: CreateReddiRoundTableDto(round.sound3Result, round.sound3Imitation),
        ah: CreateReddiRoundTableDto(round.sound4Result, round.sound4Imitation),
        ee: CreateReddiRoundTableDto(round.sound5Result, round.sound5Imitation),
        sh: CreateReddiRoundTableDto(round.sound6Result, round.sound6Imitation),
        s: CreateReddiRoundTableDto(round.sound7Result, round.sound7Imitation),
        action: '',
      })
    }
    return tableRounds
  }

  onDistanceSelectionChange(selectedValue: string): void {
    this._selectedDistance = selectedValue
    this.reddiForm.get('distance').setValue(selectedValue)
  }
  private mapValuetoDistanceType(value: string): Distance {
    switch (value) {
      case '0.5 - 0.9':
        return Distance._0_5To0_9

      case '1 - 1.9':
        return Distance._1_0To1_9

      case '2 - 3.9':
        return Distance._2_0To3_9

      case '4 - 7.9':
        return Distance._4_0To7_9
      case '8+':
        return Distance._8Plus
      default:
        return null
    }
  }

  // method to handle checkbox changes
  handleCheckboxChange(checkboxData: CheckBoxData[], type: string) {
    let formArrayName: string
    switch (type) {
      case 'listening':
        formArrayName = 'listeningConditions'
        break
      case 'volume':
        formArrayName = 'volume'
        break
      case 'cues':
        formArrayName = 'cues'
        break
      default:
        console.error(`Unknown type: ${type}`)
        return
    }

    const formArray = this.reddiForm.get(formArrayName) as FormArray
    if (!formArray) {
      console.error(`FormArray not found for type: ${type}`)
      return
    }
    // Reset all controls to false
    formArray?.controls?.forEach((control) => control.setValue(false))

    const selectedCheckbox = checkboxData?.find((checkbox) => checkbox.checked)

    if (type === 'listening') {
      this._selectedListeningOptions = selectedCheckbox
        ? selectedCheckbox.value
        : null
    } else if (type === 'volume') {
      this._selectedVolumeOptions = selectedCheckbox
        ? selectedCheckbox.value
        : null
    } else if (type === 'cues') {
      this._selectedCuesOptions = selectedCheckbox
        ? selectedCheckbox.value
        : null
    }

    if (selectedCheckbox) {
      const index = checkboxData.indexOf(selectedCheckbox)
      formArray.controls[index].setValue(true)
    }
    this.changeDetctor.detectChanges()
  }

  isVolumeInvalid(): boolean {
    const volumeArray = this.reddiForm?.get('volume') as FormArray
    return volumeArray?.touched && volumeArray.errors?.['required']
  }

  getConsiderations(index: number, event: Event) {
    const checkbox = event.target as HTMLInputElement
    this._considerations[index].checked = checkbox.checked

    const considerationsArray = this.reddiForm.get(
      'considerations',
    ) as FormArray
    considerationsArray.at(index).setValue(checkbox.checked)

    // If you need to get all selected considerations
    this._selectedConsiderationOptions = this._considerations
      .filter((c) => c.checked)
      .map((c) => c.value)
  }

  getPresenterRole() {
    this._selectedsoundPresentedBy = this._soundPresentedByControl.value
  }

  IsImitatedIncorrectlyDetected(result: number): boolean {
    return (
      result === Result.Detected ||
      result === Result.NotDetected ||
      result == Result.ImitatedCorrectly
    )
  }

  addRoundData(): void {
    if (this.reddiForm.invalid) {
      this.reddiForm.markAllAsTouched()
      return // Exit the function if form is not valid
    }
    const newRound = new ReddiRoundDto()
    newRound.distance = this.mapValuetoDistanceType(this._selectedDistance) // Assuming this.selectedDistance holds the selected distance
    newRound.cue = this._selectedCuesOptions
    newRound.listeningCondition = this._selectedListeningOptions

    newRound.volume = this._selectedVolumeOptions

    // For sound results, iterate over your sounds array and populate the corresponding properties
    this.sounds.forEach((sound, index) => {
      let adjustedIndex = index
      if (this._configuration.ignoreSound2) {
        // Adjust the index if sound2 is ignored
        adjustedIndex = index >= 1 ? index + 1 : index
        newRound.sound2Result = null
        newRound.sound2Imitation = ''
      }

      const result = this.calculateResultForSound(sound)
      const imitationValue = this.IsImitatedIncorrectlyDetected(result)
        ? ''
        : sound.incorrectSoundTooltip.control.value

      // Assign the result to the appropriate property based on the index
      switch (adjustedIndex) {
        case 0:
          newRound.sound1Result = result
          newRound.sound1Imitation = imitationValue
          break

        case 1:
          newRound.sound2Result = result
          newRound.sound2Imitation = imitationValue
          break
        case 2:
          newRound.sound3Result = result
          newRound.sound3Imitation = imitationValue
          break
        case 3:
          newRound.sound4Result = result
          newRound.sound4Imitation = imitationValue
          break
        case 4:
          newRound.sound5Result = result
          newRound.sound5Imitation = imitationValue
          break
        case 5:
          newRound.sound6Result = result
          newRound.sound6Imitation = imitationValue
          break
        case 6:
          newRound.sound7Result = result
          newRound.sound7Imitation = imitationValue
          break
      }
    })

    this._iteration?.rounds.push(newRound)

    //populate the tabledata for reddi rounds display
    this._tableData = new MatTableDataSource<ReddiRoundTableDto>(
      this.processRounds(this._iteration),
    )
    this._tableData.data.forEach((element, index) => {
      element.rowId = index?.toString()
    })
    this._tableColumnsToDisplay = this.getTableColumns(
      !this._configuration.ignoreSound2,
    )

    this.resetAllButtons()
    this.isAnyButtonSelected = false
    this.addNewRound()
  }

  calculateResultForSound(sound: SoundRow): number {
    for (const button of sound.rowButtons) {
      if (button.value) {
        switch (button.id % 4) {
          case 1:
            return Result.Detected
          case 2:
            return Result.NotDetected
          case 3:
            return Result.ImitatedCorrectly
          case 0:
            return Result.ImitatedIncorrectly
          default:
            break
        }
      }
    }
    return null
  }

  addReddiData(): void {
    const reddiIteration = new ReddiIterationDto()
    reddiIteration.clientId = this._clientId
    reddiIteration.impactingFactors = this._selectedConsiderationOptions
    reddiIteration.clientDisplayName = this._clientName
    reddiIteration.presentationDate = this._dateControl?.value?.toISOString()
    reddiIteration.presenterRole = Number(this._selectedsoundPresentedBy)
    reddiIteration.presenter = this.getContactIdByPreferredtName(
      this._soundEnteredBy,
    )
    reddiIteration.name = this._clientName
    reddiIteration.triggerRules = true
    reddiIteration.notes = this._notesValue
    this._iteration.iteration = reddiIteration
  }

  resetAllButtons(): void {
    this.sounds.forEach((sound) => {
      sound.rowButtons.forEach((button) => {
        button.value = false
      })
      sound.incorrectSoundTooltip.showTooltip = false
      sound.incorrectSoundTooltip.control.setValue('')
    })
  }

  openEditModal(element: any, index: number): void {
    const dialogRef = this.dialog.open(ReddiEditRoundModalComponent, {
      width: '65%',
      data: {
        roundData: element,
        rowId: element.rowId,
        ignoreSound2: this._configuration.ignoreSound2,
      },
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.updatedData) {
        // Find the round in your table data and update it
        const rowIndex = this._tableData.data.findIndex(
          (row) => row.rowId === result.rowIndex,
        )
        if (rowIndex !== undefined) {
          console.log('before update reddi data: ' + this._iteration.rounds)
          this._tableData.data[rowIndex] = this.processRoundsUpdate(
            result.updatedData,
            result.rowIndex,
          )
          this.refreshTable()
        }
      }
    })
  }

  processRoundsUpdate(reddi: ReddiRoundDto, index: number): ReddiRoundTableDto {
    const editButtonCallback = (index: number) => {
      this.handleEditRound(reddi, index)
    }
    let round: ReddiRoundTableDto = new ReddiRoundTableDto()
    round.rowId = index?.toString()
    round.distance = DistanceToDisplayName(reddi.distance)
    round.listening = ListeningCondition[reddi.listeningCondition]
    round.volume = VolumeToDisplayName(reddi.volume)
    round.cues = Cue[reddi.cue]
    round.m = CreateReddiRoundTableDto(
      reddi.sound1Result,
      reddi.sound1Imitation,
    )
    round.or = CreateReddiRoundTableDto(
      reddi.sound2Result,
      reddi.sound2Imitation,
    )
    round.oo = CreateReddiRoundTableDto(
      reddi.sound3Result,
      reddi.sound3Imitation,
    )
    round.ah = CreateReddiRoundTableDto(
      reddi.sound4Result,
      reddi.sound4Imitation,
    )
    round.ee = CreateReddiRoundTableDto(
      reddi.sound5Result,
      reddi.sound5Imitation,
    )
    round.sh = CreateReddiRoundTableDto(
      reddi.sound6Result,
      reddi.sound6Imitation,
    )
    round.s = CreateReddiRoundTableDto(
      reddi.sound7Result,
      reddi.sound7Imitation,
    )
    round.edit = CreateReddiEditButtonDto(editButtonCallback, index)

    this._iteration.rounds[index] = reddi
    console.log('updated reddiiteration' + this._iteration.rounds[index].cue)
    return round
  }

  handleEditRound(round: ReddiRoundDto, index: number) {
    //
  }

  addNewRound(): void {
    const newRoundNumber = this._iteration.rounds?.length + 1
    this.stepperComponent.addstepperRound(newRoundNumber)
    this.currentStepIndex++ // Move to the new round
  }

  public getContactIdByPreferredtName(preferredName: string): string | null {
    for (const manager of this._caseManagers) {
      console.log(manager.preferredName)
      if (manager.preferredName.toLowerCase() === preferredName.toLowerCase()) {
        console.log('manager matched: ' + manager.contactId)
        return manager.contactId
      }
    }
    return null // Return null if no match is found
  }

  public getFulNameByPreferredtName(preferredName: string): string | null {
    for (const manager of this._caseManagers) {
      console.log(manager.preferredName)
      if (
        manager?.preferredName.toLowerCase() === preferredName?.toLowerCase()
      ) {
        console.log('manager matched: ' + manager.contactId)
        return manager.firstName + ' ' + manager.lastName
      }
    }
    return null // Return null if no match is found
  }

  refreshStepper() {
    this.changeDetctor.detectChanges()
  }

  private refreshTable() {
    this._tableData = new MatTableDataSource([...this._tableData.data]) // Refresh the table data source
  }

  finishSession(): void {
    this.addReddiData()
    if (this.reddiForm.invalid) {
      this.reddiForm.markAllAsTouched()
      return // Exit the function if form is not valid
    }

    if (this._tableData.data.length === 0) {
      this.bannerService.showBanner(
        'No Round Data entered. Cannot finish session.',
        'error',
        5000,
      )
      return // Exit the function if no data has been entered
    }

    this.spinnerService.show() // Show spinner
    this.reddiService.createReddi(this._iteration).subscribe({
      next: (response) => {
        this.spinnerService.hide() // Hide spinner
        this.bannerService.showBanner(
          'REDDI Assessment Created Successfully.',
          'success',
          5000,
        )
        setTimeout(() => {
          this.router.navigateByUrl(this._dashboardUrl)
        }, 5000) //
      },
      error: (error) => {
        console.error('Error creating REDDI Assessment:', error)
        this.spinnerService.hide() // Hide spinner on error as well
        this.bannerService.showBanner(
          'error occured in creating REDDI assessment',
          'error',
          10000,
        )
        setTimeout(() => {
          this.router.navigateByUrl(this._dashboardUrl)
        }, 5000) //
      },
    })
  }

  cancelAssessment(): void {
    const dialogRef = this.dialog.open(AreYouSureModalComponent, {
      width: '605px',
      height: '316px',
      panelClass: 'are-you-sure-panel',
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.previousRouteService.goToPreviousUrl(this._dashboardUrl)
      } else {
      }
    })
  }
}
