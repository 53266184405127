import { Component, Input, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { LabelToId } from 'src/app/common/input-utils'

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent implements OnInit {
  @Input() control: FormControl
  @Input() label: string
  @Input() required: boolean = false
  @Input() placeholder: string
  @Input() errorText: string
  @Input() mask: string
  id: string

  ngOnInit() {
    this.id = LabelToId(this.label)
  }
}
