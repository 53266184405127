enum OrderBy {
  Ascending,
  Descending,
}

export class SearchParametersDto {
  public CaseManagerContactId: string
  public SearchByColumns: string
  public SearchString: string
  public OrderByColumn: string
  public OrderBy: OrderBy
  public MaxRecords: number
  public PageMode: string

  TscPreferredFullName: string = 'tsc_preferredfullname'
  DefaultMaxRecords: number = 500

  public constructor() {
    this.SearchByColumns = this.TscPreferredFullName
    this.OrderByColumn = this.TscPreferredFullName
    this.OrderBy = OrderBy.Ascending
    this.MaxRecords = this.DefaultMaxRecords
  }
}
