import { Component, Inject, inject } from '@angular/core'
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar'
import { MatSnackBarRef } from '@angular/material/snack-bar'
@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent {
  class: string[] = ['']
  snackBarRef = inject(MatSnackBarRef)
  showButton: boolean = true
  public type: string
  public message: string // Here is your message variable

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.type = data.type || 'success' // default type is 'primary'
    this.message = data.message // your message is stored here
    this.showButton = this.type === 'warn' || this.type === 'error'
  }

  ngOnInit(): void {
    this.class.push(this.getBannerVariant())
  }

  getBannerVariant(): string {
    switch (this.type) {
      case 'success':
        return 'banner-success-container'
      case 'warning':
        return 'banner-warning-container'
      case 'error':
        return 'banner-error-container'
      default:
        return 'banner-success-container'
    }
  }
}
