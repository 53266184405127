import { Component } from '@angular/core'
import { ConfigService } from 'src/app/services/config/config.service'
@Component({
  selector: 'app-faqs-and-forms',
  templateUrl: './faqs-and-forms.component.html',
  styleUrls: ['./faqs-and-forms.component.scss'],
})
export class FaqsAndFormsComponent {
  constructor(private config: ConfigService) {}
  backDestination: string = ''
  title: string = 'Support Hub'
  showDate: boolean = false
  showClientDetails: boolean = false
  age: string = ''
  dob: Date

  //forms Urls
  protected formsFolderPath: string = this.config.getSettings('spa').formFolder
  protected flipAdministrationForm: string =
    this.formsFolderPath + '/Download FLI-P administration form.pdf'
  protected reddiAdministrationForm7sounds: string =
    this.formsFolderPath + '/REDDI administration form (7 sounds).pdf'
  protected reddiAdministrationForm6sounds: string =
    this.formsFolderPath + '/REDDI administration form (6 sounds).pdf'
  //Web URLS
  protected generalHearhubFaq: string =
    this.config.getSettings('spa').generalHearHubFaqLink
  protected generalFlipFaq: string =
    this.config.getSettings('spa').generalFlipFaqLink
  protected generalReddiFaq: string =
    this.config.getSettings('spa').generalReddiFaqLink
  protected generalCoursesFaq: string =
    this.config.getSettings('spa').generalCoursesFaqLink

  openPdf(url: string) {
    window.open(url, '_blank')
  }
  openPage(url: string) {
    window.open(url, '_blank')
  }
}
