import { Component, OnInit } from '@angular/core'
import { CourseCardDto } from 'src/app/models/subscriptions/course-card-dto'
import { CourseDto } from 'src/app/models/subscriptions/course-dto'
import { CourseImageDto } from 'src/app/models/subscriptions/course-image-dto'
import { BannerService } from 'src/app/services/banner/banner.service'
import { ConfigService } from 'src/app/services/config/config.service'
import { SpinnerService } from 'src/app/services/spinner/spinner.service'
import { SubscriptionService } from 'src/app/services/subscription/subscription.service'

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
})
export class CoursesComponent implements OnInit {
  _courses: CourseCardDto[] = new Array<CourseCardDto>()
  _noData: boolean = true
  _images: CourseImageDto[] = new Array<CourseImageDto>()

  constructor(
    protected spinnerService: SpinnerService,
    private subscriptionService: SubscriptionService,
    private bannerService: BannerService,
    private config: ConfigService,
  ) {}

  ngOnInit(): void {
    this.spinnerService.show()

    this._images = this.config.getSettings('spa').courseImages

    this.subscriptionService.Courses().subscribe({
      next: (result: CourseDto[]) => {
        for (let i = 0; i < result.length; i++) {
          this._courses.push(this.mapApiToCourseCardDto(result[i]))
        }

        this._noData = this._courses.length == 0
        this.spinnerService.hide()
      },
      error: () => {
        this.spinnerService.hide()

        this.bannerService.showBanner(
          `Unable to retrieve course details.`,
          'error',
          3000,
        )
      },
    })
  }

  mapApiToCourseCardDto(course: CourseDto): CourseCardDto {
    const result: CourseCardDto = new CourseCardDto()

    result.completed = course.percentageComplete === 100
    result.imageLink = this.getImageLink(
      this._images,
      course.coassembleCourseId,
    )
    result.percentage = course.percentageComplete
    result.title = course.name
    result.todo = course.percentageComplete === 0
    result.url = course.coassembleUrl

    return result
  }

  getImageLink(images: CourseImageDto[], id: number): string {
    let courseImageDto = images.find((c) => c.id === id.toString())

    // If can't find then default
    if (courseImageDto === undefined) {
      courseImageDto = images.find((c) => c.id === 'default')

      // If default isn't set show an error
      if (courseImageDto === undefined) {
        this.bannerService.showBanner(
          'Unable to determine course image',
          'error',
          3000,
        )
        throw new Error('Unable to determine course image')
      }
    }

    return courseImageDto.link
  }
}
