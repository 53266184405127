import { Injectable } from '@angular/core'
import { MsalService } from '@azure/msal-angular'
import { ConfigService } from '../config/config.service'

@Injectable({
  providedIn: 'root',
})
export class AuthUtilsService {
  constructor(
    private config: ConfigService,
    private authService: MsalService,
  ) {}

  public logout() {
    //Remove trailing  / in baseUri
    let baseUri = this.config.getSettings('spa').baseUri
    if (this.config.getSettings('spa').baseUri.endsWith('/')) {
      baseUri = this.config
        .getSettings('spa')
        .baseUri.substring(0, this.config.getSettings('spa').baseUri.length - 1)
    }
    this.authService.logoutRedirect({
      account: this.authService.instance.getActiveAccount(),
      postLogoutRedirectUri:
        baseUri + this.config.getSettings('msal').auth.postLogoutRedirectUri,
    })
  }
}
