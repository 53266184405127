import { Component, Input, OnInit, SimpleChanges } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { PreviousRouteService } from 'src/app/services/previous-route/previous-route.service'

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {
  @Input() link: string = ''
  @Input() useRouter: boolean = false
  private routerEventsSubscription: Subscription

  constructor(
    private previousRouterService: PreviousRouteService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (this.useRouter) {
      this.routerEventsSubscription = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.updateLink()
        }
      })
    } else {
      this.updateLink()
    }
  }

  ngOnDestroy(): void {
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe()
    }
  }

  private updateLink(): void {
    if (this.useRouter && this.previousRouterService.hasPreviousUrl()) {
      this.link = this.previousRouterService.getPreviousUrl()
    }
  }
}
