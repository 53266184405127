import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-sidebar-secondary-button',
  templateUrl: './sidebar-secondary-button.component.html',
  styleUrls: ['./sidebar-secondary-button.component.scss'],
})
export class SidebarSecondaryButtonComponent {
  @Input() link: string = ''
}
