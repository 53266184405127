export class FlipScores {
  ageinMonths: number;
  tscUpperLimit: number;
  tscLowerLimit: number;

  constructor(data?: any) {
    this.ageinMonths = data?.ageinMonths ?? 0;
    this.tscUpperLimit = data?.tscUpperLimit ?? 0;
    this.tscLowerLimit = data?.tscLowerLimit ?? 0;
  }
}