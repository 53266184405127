import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core'
import * as moment from 'moment'

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
})
export class HeaderBarComponent implements OnInit, OnChanges {
  @Input() backDestination: string = ''
  @Input() title: string = ''
  @Input() showDate: boolean = false
  @Input() showClientDetails: boolean = false
  @Input() age: string = ''
  @Input() dob: Date
  @Input() showButton: boolean = true
  @Output() routerLinkClick = new EventEmitter<void>()

  today: string
  formattedDob: string

  configureHeaderBar() {
    const format = 'Do MMMM, YYYY'

    if (this.showDate) {
      this.today = moment().format(format)
    }

    if (this.showClientDetails) {
      if (this.dob == null) {
        throw new Error('dob must be supplied if showing client details')
      }
      if (this.age == null || this.age == '') {
        throw new Error('age must be supplied if showing client details')
      }
      this.formattedDob = moment(this.dob).format(format)
    }
  }

  ngOnInit() {
    this.configureHeaderBar()
  }

  ngOnChanges() {
    this.configureHeaderBar()
  }

  onRouterLinkClick(): void {
    // Emit the event to notify the parent component (wrapper component)
    this.routerLinkClick.emit()
  }
}
