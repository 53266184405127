import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import {  CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, LineController, Legend } from 'chart.js';
import { Observable, map, tap } from 'rxjs';
import { ClientScore } from 'src/app/models/client/client-score-dto';
import { FlipScores } from 'src/app/models/flip/flip-score-dto';
import { ClientService } from 'src/app/services/client/client.service';
Chart.register(LineController,CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
interface DetailsAndScoreResponse {
  flipGraphDatas: ClientScore[]; 
  flipUpperAndLowerLimits: FlipScores[]; 
}
@Component({
  selector: 'app-flip-overview-graph',
  templateUrl: './flip-overview-graph.component.html',
  styleUrls: ['./flip-overview-graph.component.scss']
})



export class FlipOverviewGraphComponent {
  @Input() clientId: string ;
  @ViewChild('flipGraph') skillsChart: ElementRef<HTMLCanvasElement>;
  private chart: Chart;
  clientScore$: Observable<ClientScore[]>;
  flipScore$: Observable<FlipScores[]>;

  constructor(
    private cdr: ChangeDetectorRef,
    private clientService: ClientService) {}

    ngOnInit() {
      console.log('Received clientId:', this.clientId);
      if (this.clientId) {
        this.clientService.DetailsAndSCore(this.clientId).pipe(
          tap(response => console.log('Service response:', response)),
          map(response => {
            if (!response) {
              console.error('Service response is undefined.');
              return { flipGraphDatas: [], flipUpperAndLowerLimits: [] }; // Provide default empty arrays
            }
            
            return {flipGraphDatas: response.clientScore?.map(item => new ClientScore(item)) ?? [],
            flipUpperAndLowerLimits: response.flipScores?.map(item => new FlipScores(item)) ?? []
          } as DetailsAndScoreResponse; // Cast to DetailsAndScoreResponse
          }),
          tap({
            next: data => {
              // Check if the expected properties exist
              if (!data.flipGraphDatas || !data.flipUpperAndLowerLimits) {
                console.error('One of the expected properties is missing:', data);
              }
            },
            error: error => console.error('Error after mapping response:', error)
          }),
        ).subscribe({
          next: data => {
            // map function to create new instances if arrays are not empty
            
            const clientScores = data.flipGraphDatas?.map(item => new ClientScore(item)) ?? [];
            console.log("clientscores: "+clientScores)
            const flipScores = data.flipUpperAndLowerLimits?.map(item => new FlipScores(item)) ?? [];
            this.createChart(clientScores, flipScores);
          },
          error: error => {
            console.error('Error in final subscription:', error);
            // If there is an error, call createChart with empty arrays
            this.createChart([], []);
          }
        });
      }
    }
     

  createChart(clientScores: any[], flipScores: any[]) {
    const context = this.skillsChart.nativeElement.getContext('2d');
    const labels = [0,1,2,3,4,5,6];
    const upperLimits = flipScores
  ?.sort((a, b) => a.ageinMonths - b.ageinMonths)
  ?.map(score => ({
    x: parseFloat((score.ageinMonths / 12).toFixed(2)),
    y: score.tscUpperLimit
  }));

    const lowerLimits = flipScores?.map(score => ({ x: parseFloat((score.ageinMonths / 12).toFixed(2)), y: score.tscLowerLimit }));
    clientScores.sort((a, b) => a.ageinMonths - b.ageinMonths);
    const professionalScores = clientScores?.map(score => ({ x: score.ageinMonths / 12, y: score?.totalScoreProfessional }));
    const comparisonScores = clientScores?.map(score => ({ x: score.ageinMonths / 12, y: score?.totalScoreComparision }));
    const allZeros = professionalScores.every(score => score.y === 0);
    const processedProfessionalScores = this.preprocessData(professionalScores);
    
    // Now checking if there's any data to plot
    const hasValidData = processedProfessionalScores.length > 0;
   const  datasets= [{
      label: 'Expected Range(Upper)',
      data: upperLimits,
      borderColor: 'rgba(0, 144, 152, 1)',
      backgroundColor: 'rgba(0, 144, 152, 1)',
      fill: false,
      borderDash: [5, 5],
      pointRadius: 0, // Smaller points
      borderWidth: 2,
    },  {
      label: 'Adapted Score',
      data: comparisonScores,
      borderColor: 'rgba(250, 166, 52, 1)',
      backgroundColor: 'rgba(250, 166, 52, 1)',
      fill: false,
    },
    {
      label: 'Expected Range(Lower)',
      data: lowerLimits,
      borderColor: 'rgba(0, 144, 152, 1)',
      backgroundColor: 'rgba(0, 144, 152, 1)',
      fill: false,
      borderDash: [5, 5],
      pointRadius: 0, // Smaller points
      borderWidth: 2,
    },
  ]

  if (hasValidData) {
    datasets.splice(1, 0, {
      label: 'Professional Score',
      data: processedProfessionalScores,
      borderColor: 'rgba(36, 51, 131, 1)',
      backgroundColor: 'rgba(36, 51, 131, 1)',
      fill: true,
    });
  }
    this.chart = new Chart(context, {
      type: 'line',
      data: {
        labels: labels,
        datasets: datasets
      },

      
      // ... the rest of chart configuration 
      options: {
            plugins: {
              legend: {
                position: 'top', // legend position
                 // This will use the point style for the legend symbols
                
            labels: {      
              usePointStyle: true,
              pointStyle: 'circle', //  'circle' for  circular points
             
            }
              },
              tooltip: {
               //
              }
            },
            scales: {
                y: {
                  type: 'linear',
                   
                  beginAtZero: true,
                  
                  ticks: {
                    callback: function(value, index, values) {
                      return value;
                    }
                  }
                    }
                ,
                x: {
                  type: 'linear',
                  min: 0,
                max: 6,
                  title: {
                    display: true,
                    text: 'Age in years'
                  },
                  ticks: {
                    callback: function(value, index, values) {
                        return Number.isInteger(value) ? value : '';
                    }
                  }
            }
        }        
        }});
    
  }
  
  preprocessData(scores: any) {
    let startIndex = scores.findIndex((score: { y: number; }) => score.y !== 0);
    return startIndex !== -1 ? scores.slice(startIndex) : [];
  }
  
  // Applying the preprocessing to the professionalScores data

  

}
