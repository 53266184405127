import { TableColumnDefinition } from '../table/table-column-definition'

export const ClientDashboardTableColumnDefinitions: TableColumnDefinition[] = [
  {
    columnName: 'assessmentDate',
    header: 'Assessment Date',
    sortDisabled: true,
    columnType: 'truncated-string',
    columnWidth: '300px',
    columnTextAlign: 'left',
  },
  {
    columnName: 'score',
    columnWidth: '170px',
    columnTextAlign: 'left',
    header: 'Score',
    sortDisabled: true,
    columnType: 'truncated-string',
  },
  {
    columnName: 'viewReport',
    columnWidth: '80px',
    columnTextAlign: 'left',
    header: '',
    sortDisabled: true,
    columnType: 'link',
    linkText: 'View details',
    linkKind: 'icon',
    linkColor: 'primary',
    showIcon: true,
  },
]
