import { Component, Input } from '@angular/core'
import { FormControl } from '@angular/forms'

@Component({
  selector: 'app-incorrect-sound-tooltip',
  templateUrl: './incorrect-sound-tooltip.component.html',
  styleUrls: ['./incorrect-sound-tooltip.component.scss'],
})
export class IncorrectSoundTooltipComponent {
  @Input() id: string
  @Input() top: number = 0
  @Input() left: number = 0
  @Input() showTooltip: boolean
  @Input() control: FormControl = new FormControl('')

  displayOrHide(): string {
    return this.showTooltip ? 'block' : 'none'
  }
}
