import { HttpBackend, HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private settings: any
  private http: HttpClient

  constructor(httpHandler: HttpBackend) {
    this.http = new HttpClient(httpHandler)
  }

  load(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.http
        .get('assets/configuration/configuration.json')
        .pipe(map((result) => result))
        .subscribe({
          next: (value: any) => {
            this.settings = value
            resolve(true)
          },
          error: (error: any) => {
            reject(error)
          },
        })
    })
  }

  init(endpoint: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.http
        .get(endpoint)
        .pipe(map((result) => result))
        .subscribe({
          next: (value: any) => {
            this.settings = value
            resolve(true)
          },
          error: (error: any) => {
            reject(error)
          },
        })
    })
  }

  getSettings(key?: string | Array<string>): any {
    if (!key || (Array.isArray(key) && !key[0])) {
      return this.settings
    }

    if (!Array.isArray(key)) {
      key = key.split('.')
    }

    let result = key.reduce(
      (account: any, current: string) => account && account[current],
      this.settings,
    )

    return result
  }
}
