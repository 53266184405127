export class ClientScore {
    ageinMonths: number;
    totalScoreComparision: number | null;
    totalScoreProfessional: number;
  
    constructor(data?: any) {
      this.ageinMonths = data?.ageinMonths ?? 0;
      this.totalScoreComparision = data?.totalScoreComparision ?? null;
      this.totalScoreProfessional = data?.totalScoreProfessional ?? 0;
    }
  }
  