import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-link-button',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.scss'],
})
export class LinkButtonComponent implements OnInit {
  @Input() color: 'primary' | 'accent'
  @Input() type: 'submit' | 'reset' | 'button'
  @Input() showIcon: boolean = false
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>()
  _classes: string[] = ['link-button']

  ngOnInit(): void {
    const prefix = 'link-button-'
    this._classes.push(prefix + this.color)
  }
}
