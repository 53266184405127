import { Component } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'
import * as moment from 'moment'
import { forkJoin } from 'rxjs'
import { ClientDto } from 'src/app/models/client/client-dto'
import { Filter } from 'src/app/models/power-bi/filter'
import { ReddiHistoryTableDTO } from 'src/app/models/reddi/reddi-history-table-dto'
import { ReddiListDto } from 'src/app/models/reddi/reddi-list-dto'
import { ReddiHistoryTableColumnDefinitions } from 'src/app/models/table-defs/reddi-history-table-def'
import { TableColumnDefinition } from 'src/app/models/table/table-column-definition'
import { TableLinkColumnDTO } from 'src/app/models/table/table-link-column-dto'
import { BannerService } from 'src/app/services/banner/banner.service'
import { ClientService } from 'src/app/services/client/client.service'
import { PreviousRouteService } from 'src/app/services/previous-route/previous-route.service'
import { ReddiService } from 'src/app/services/reddi/reddi.service'
import { SpinnerService } from 'src/app/services/spinner/spinner.service'
import IsUUID from 'validator/es/lib/isUUID'

@Component({
  selector: 'app-reddi-history',
  templateUrl: './reddi-history.component.html',
  styleUrls: ['./reddi-history.component.scss'],
})
export class ReddiHistoryComponent {
  _clientId: string
  _title = ''
  _age: string = ''
  _dob: Date
  _dateFormat: string = 'Do MMMM, YYYY'
  _fallbackUrl = '/dashboard'

  _clientDetails: ClientDto
  _reddiHistory: ReddiListDto[]
  _reddiFilters: Filter[]
  _tableData: MatTableDataSource<ReddiHistoryTableDTO> =
    new MatTableDataSource<ReddiHistoryTableDTO>()
  _tableColumnsToDisplay: string[] = [
    'presentationDate',
    'presenterType',
    'presenterName',
    'notes',
    'viewReport',
  ]
  _tableColumnDefinitions: TableColumnDefinition[] =
    ReddiHistoryTableColumnDefinitions

  constructor(
    private route: ActivatedRoute,
    private bannerService: BannerService,
    protected spinnerService: SpinnerService,
    private clientService: ClientService,
    private reddiService: ReddiService,
    private previousRoute: PreviousRouteService,
    public router: Router,
  ) {}

  ngOnInit() {
    this.spinnerService.show()

    this._clientId = this.route.snapshot.paramMap.get('clientId')
    if (!IsUUID(this._clientId)) {
      this.bannerService.showBanner(`Invalid client requested.`, 'error', 3000)
      this.previousRoute.goToPreviousUrl(this._fallbackUrl)
    }

    this._reddiFilters = [
      {
        attribute: 'Client/contactid',
        operation: 'eq',
        value: this._clientId,
      },
    ]

    let requests = {
      clientDetails: this.clientService.Details(this._clientId),
      reddiHistory: this.reddiService.getList(this._clientId),
    }

    forkJoin(requests).subscribe({
      next: ({ clientDetails, reddiHistory }) => {
        this._clientDetails = clientDetails
        this._age = this._clientDetails.age
        this._dob = this._clientDetails.dateOfBirth
        this._title = this._clientDetails.preferredFullName

        this._reddiHistory = reddiHistory
        this._tableData = this.MapHistoryToTable(
          this._clientId,
          this._reddiHistory,
        )

        this.spinnerService.hide()
      },
      error: () => {
        this.bannerService.showBanner(
          `Unable to retrieve details.`,
          'error',
          3000,
        )

        this.spinnerService.hide()
      },
    })
  }

  redirect(destination: string) {
    this.router.navigate([destination])
  }

  routerLinkClicked() {
    this.router.navigate([`/client-dashboard/${this._clientId}`])
  }

  navigateTo(target: string) {
    switch (target) {
      case 'newFlip':
        this.redirect('/flip-assessment/' + this._clientId)
        break

      case 'newReddi':
        this.redirect('/reddi/assessment/' + this._clientId)
        break

      default:
        break
    }
  }

  MapHistoryToTable(
    clientId: string,
    history: ReddiListDto[],
  ): MatTableDataSource<ReddiHistoryTableDTO> {
    const tableData: ReddiHistoryTableDTO[] = new Array<ReddiHistoryTableDTO>()
    history.sort((a, b) => {
      // Sorting in ascending order
      return (
        moment(b.presentationDate).valueOf() -
        moment(a.presentationDate).valueOf()
      )
    })
    for (let i = 0; i < history.length; i++) {
      let source = history[i]

      let target = new ReddiHistoryTableDTO()
      target.notes = source.notes
      target.presentationDate = moment(source.presentationDate).format(
        this._dateFormat,
      )
      target.presenterName = source.presenterName
      target.presenterType = source.presenterRole

      target.viewReport = new TableLinkColumnDTO()
      target.viewReport.linkText = 'View details'
      target.viewReport.linkUrl = `/reddi/instance/${clientId}/${source.reddiId}`

      tableData.push(target)
    }

    return new MatTableDataSource<ReddiHistoryTableDTO>(tableData)
  }
}
