import { ClientToolRecord } from './client-tool-record'

export class ClientDto {
  public age?: string
  public clientCaseManagerId?: string
  public clientCaseManagerName?: string
  public clientPrimaryCentreId?: string
  public clientPrimaryCentreName?: string
  public contactId?: string
  public contactTypeCode?: number
  public contactTypeCodeName?: string
  public currentlyReceivingServices?: boolean
  public dateOfBirth?: Date
  public firstname?: string
  public genderCode?: number
  public genderCodeName?: string
  public lastname?: string
  public preferredFullName?: string
  public preferredName?: string
  public tools?: ClientToolRecord[]

  public constructor(init?: Partial<ClientDto>) {
    Object.assign(this, init)
  }
}
