import { Component, Input, OnInit } from '@angular/core'

export type Borders = 'top' | 'bottom' | 'both' | 'none'

@Component({
  selector: 'app-client-information-entry',
  templateUrl: './client-information-entry.component.html',
  styleUrls: ['./client-information-entry.component.scss'],
})
export class ClientInformationEntryComponent implements OnInit {
  @Input() key: string = ''
  @Input() value: string = ''
  @Input() borders: Borders = 'none'
  @Input() placeholder: string = ''

  _containerClass: string = ''

  ngOnInit(): void {
    this._containerClass = 'client-information-entry-border-' + this.borders
  }

  showPlaceHolder(): boolean {
    return this.value === ''
  }
}
