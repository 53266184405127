export class AssessmentItemDto {
  description?: string
  groupId?: string // GUID as string in TypeScript
  helpTip?: string
  itemId?: string // GUID as string
  name?: string
  orderNumber?: number
  presentedToClient?: boolean
  score?: boolean
  templateId?: string // GUID as string
  selected?: boolean = false
  itemNumber?: string

  constructor(init?: Partial<AssessmentItemDto>) {
    Object.assign(this, init)
  }
}
