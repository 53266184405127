import { Component, Input, OnInit } from '@angular/core'
import { ReportDto } from 'src/app/models/configuration/report-dto'
import { Filter } from 'src/app/models/power-bi/filter'
import { Page, Report } from 'src/app/models/power-bi/report'
import { ConfigService } from 'src/app/services/config/config.service'
import { ConfigurationService } from 'src/app/services/configuration/configuration.service'

@Component({
  selector: 'app-power-bi-graph',
  templateUrl: './power-bi-graph.component.html',
  styleUrls: ['./power-bi-graph.component.scss'],
})
export class PowerBiGraphComponent implements OnInit {
  @Input() height: number
  @Input() width: number
  @Input() title: string
  @Input() filters: Filter[]
  @Input() reportName: string
  @Input() pageName: string

  _url: string
  _reports: ReportDto[]
  _ready: boolean = false
  _error: boolean = false

  constructor(
    private config: ConfigService,
    private configuration: ConfigurationService,
  ) {}

  ngOnInit(): void {
    this.configuration.getReports().subscribe({
      next: (response: ReportDto[]) => {
        this._reports = response
        this._url = this.buildReportUrl(this.reportName)
        this._ready = true
      },
      error: (error: any) => {
        this._error = true
        throw error
      },
      complete: () => {},
    })
  }

  buildReportUrl(reportName: string): string {
    let result: string

    const powerbiBaseUrl = 'https://app.powerbi.com/reportEmbed?'
    const autoAuth = 'autoAuth=true'
    const config = this.getReportConfig(reportName)

    result = powerbiBaseUrl
    result += 'reportId=' + config.id

    if (this.hasFilter()) {
      result += '&' + 'filter=' + this.buildReportFilter(this.filters)
    }

    result += '&' + autoAuth + '&ctid=' + this.getTenantId()

    if (this.hasPage()) {
      result +=
        '&pageName=ReportSection' + this.getPageId(config, this.pageName)
    }

    // This hides the page selector
    result += '&navContentPaneEnabled=false'

    return result
  }

  hasFilter(): boolean {
    return (
      this.filters !== null &&
      this.filters !== undefined &&
      this.filters.length > 0
    )
  }

  hasPage(): boolean {
    return (
      this.pageName !== '' &&
      this.pageName !== null &&
      this.pageName !== undefined
    )
  }

  getTenantId(): string {
    return this.config.getSettings('spa').tenantId as string
  }

  getPageId(report: Report, pageName: string): string {
    let page: Page | undefined = report.pages.find(
      (page) => page.name === pageName,
    )

    if (page === undefined) {
      throw new Error(`Invalid Page Name ${pageName}`)
    }

    return page.id
  }

  getReportConfig(reportName: string): ReportDto {
    return this._reports.find((report) => report.name === reportName)
  }

  buildReportFilter(filters: Filter[]): string {
    let result: string = ''
    let filter: Filter

    //ReddiIteration/tsc_client eq 'fd599f67-6d9b-4ef5-a654-a5f8d3aa3c8c'
    for (let f = 0; f < filters.length; f++) {
      filter = filters[f]
      result += `${filter.attribute} ${filter.operation} '${filter.value}'`

      if (f + 1 < filters.length) {
        result += ' and '
      }
    }

    return result
  }
}
