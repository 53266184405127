import { Component, Input, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { LabelToId } from 'src/app/common/input-utils'

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit {
  @Input() control: FormControl
  @Input() label: string
  @Input() required: boolean = false
  @Input() placeholder: string
  @Input() errorText: string
  @Input() id: string
  @Input() date: string

  ngOnInit(): void {
    this.id = LabelToId(this.label)
  }

  clearInput() {
    // Clear the input as the parser has issues with the formatting
    this.control.setValue('')
  }
}
