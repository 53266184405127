import { TableLinkColumnDTO } from '../table/table-link-column-dto'
import { TableQuickViewColumnDTO } from '../table/table-quick-view-column-dto'

export class ClientTableDTO {
  age: string
  clientPrimaryCentreName: string
  dateOfBirth: string
  genderCodeName: string
  preferredFullName: TableLinkColumnDTO
  quickView: TableQuickViewColumnDTO
  viewReport?: TableLinkColumnDTO
}
