import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-flip-button',
  templateUrl: './flip-button.component.html',
  styleUrls: ['./flip-button.component.scss'],
})
export class FlipButtonComponent implements OnInit {
  @Input() kind: 'primary' | 'accent'
  @Input() isDisabled = false
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>()

  _displayText: string = ''
  _buttonClasses: string[] = ['flip-button']
  _containerClass: string[] = ['flip-button-container']

  ngOnInit(): void {
    const prefix = 'flip-button-'
    this._displayText = this.kind == 'primary' ? 'Mostly' : 'Rarely'
    this._buttonClasses.push(prefix + this.kind)
  }
}
