import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-flip-view-main-card',
  templateUrl: './flip-view-main-card.component.html',
  styleUrls: ['./flip-view-main-card.component.scss'],
})
export class FlipViewMainCardComponent {
  @Input() lastAssessmentDetails: string = ''
  @Input() assessmentStatus: string = 'This assessment is locked'
  @Input() size: 'medium' | 'large' = 'medium'
  @Input() paddingSize: 'medium' | 'large' = 'medium'
  @Input() items: any[]
  class: string[] = ['flip-view-main-primary-card']
  cardSizePrefix = 'flip-view-main-primary-card flip-view-main-primary-card-'
  paddingPrefix: string = 'flip-view-main-primary-card-padding-'
  ngOnInit() {
    this.class.push(this.cardSizePrefix + this.size)

    this.class.push(this.paddingPrefix + this.paddingSize)
  }
}
