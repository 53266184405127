import { Component } from '@angular/core'
import { ConfigService } from 'src/app/services/config/config.service'

@Component({
  selector: 'app-flip-logo',
  templateUrl: './flip-logo.component.html',
  styleUrls: ['./flip-logo.component.scss'],
})
export class FlipLogoComponent {
  constructor(private config: ConfigService) {}

  protected logoFolderPath: string =
    this.config.getSettings('spa').imageFolder + '/flip-logo/'

  protected flipLogoPath: string =
    this.logoFolderPath + 'TSC_FLI-P_logo_web_64X42.png'
  protected path: string

  ngOnInit() {
    this.path = this.flipLogoPath
  }
}
