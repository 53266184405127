import { HttpErrorResponse } from '@angular/common/http'
import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { noop } from 'rxjs'
import { AppInsightsSeverity } from 'src/app/common/app-insights-severity'
import { LogLevel } from 'src/app/common/log-level'
import { ApplicationInsightsService } from '../application-insights/application-insights.service'
import { LogService } from '../logger/log.service'
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
  serviceName = 'ErrorHandlingService'
  appInsights: ApplicationInsights

  // Error handler is a special case. It is loaded before any of the providers, so cannot use dependency injection the same way.
  // We have to use the injector to get the services we need.
  // see https://stackoverflow.com/questions/49148781/custom-error-handler-throw-error-cannot-read-property-get-of-undefined-inj
  constructor(@Inject(Injector) private injector: Injector) {
    super()
  }

  private get logService(): LogService {
    return this.injector.get(LogService)
  }

  private get appInsightsService(): ApplicationInsightsService {
    return this.injector.get(ApplicationInsightsService)
  }

  override handleError(error: any): void {
    this.logService.error(
      'Error caught in the error-handler.service service and re-thrown' +
        error.message +
        '; ' +
        error.stack,
      LogLevel.Error,
      error,
    )

    // Always log errors to Application Insights
    this.appInsightsService.logException(error, AppInsightsSeverity.Error)

    // A. HANDLE ERRORS FROM HTTP
    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // A.1: A client-side or network error occurred. Handle it accordingly.
        // e.g. const formattedError = `${error.name}; ${error.message}`
        // console.log(
        //  'Client Side Error Error caught in the error-handler.service service and re-thrown:' +
        //    `${formattedError}`,
        // )
      } else {
        // A.2: The API returned an unsuccessful response (i.e., 400, 401, 403, etc.).
        /**
         * The [HttpService] should return a response that is consumable by the caller
         * of the API. The response should include relevant information and error messages
         * in a format that is known and consumable by the caller of the API.
         * This section will handle the error after it has been handled by a component
         */
        // e.g.
        // const formattedError = `${error.name}; ${error.message}`
        // console.log(
        //  'API Error Error caught in the error-handler.service service and re-thrown:' +
        //    `${formattedError}`,
        // )
        noop()
      }
    } else {
      // B. HANDLE A GENERALIZED ERROR FROM THE APPLICATION/CLIENT;
      // e.g.
      // const formattedError = `${error.name}; ${error.message}}`
      // console.log(
      //  'Generalized error from the application/client caught in the error-handler.service service and re-thrown:' +
      //    `${formattedError}`,
      //)
    }
  }
}
