import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-are-you-sure-modal',
  templateUrl: './are-you-sure-modal.component.html',
  styleUrls: ['./are-you-sure-modal.component.scss'],
})
export class AreYouSureModalComponent {
  constructor(public dialogRef: MatDialogRef<AreYouSureModalComponent>) {}

  close(value: 'cancel' | 'continue') {
    this.dialogRef.close(value == 'cancel')
  }
}
