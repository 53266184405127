import { Component } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'
import * as moment from 'moment'
import { forkJoin } from 'rxjs'
import { ReviewModalComponent } from 'src/app/components/complex/modals/review-modal/review-modal.component'
import { ClientInformationAttribute } from 'src/app/models/client-information/ClientInformationAttribute'
import { ClientDto } from 'src/app/models/client/client-dto'
import { FeatureStatusRecord } from 'src/app/models/features/feature-status-record'
import { FlipEntriesTableDTO } from 'src/app/models/flip/flip-entries-table-dto'
import { FlipHistoryDto } from 'src/app/models/flip/flip-history-dto'
import { Filter } from 'src/app/models/power-bi/filter'
import { QuickViewEventDTO } from 'src/app/models/quickview/quick-view-event-dto'
import { ReddiEntriesTableDTO } from 'src/app/models/reddi/reddi-entries-table-dto'
import { ReddiListDto } from 'src/app/models/reddi/reddi-list-dto'
import { ReviewDto } from 'src/app/models/reddi/review-dto'
import { ClientDashboardReddiTableColumnDefinitions } from 'src/app/models/table-defs/client-dashboard-reddi-table-def'
import { ClientDashboardTableColumnDefinitions } from 'src/app/models/table-defs/client-dashboard-table-def'
import { ReviewTableDTO } from 'src/app/models/table-defs/review-table-dto'
import { TableCategoryLinkColumnDTO } from 'src/app/models/table/table-category-link-column-dto'
import { TableColumnDefinition } from 'src/app/models/table/table-column-definition'
import { TableLinkColumnDTO } from 'src/app/models/table/table-link-column-dto'
import { BannerService } from 'src/app/services/banner/banner.service'
import { ClientService } from 'src/app/services/client/client.service'
import { FeaturesService } from 'src/app/services/features/features.service'
import { FlipService } from 'src/app/services/flip/flip.service'
import { PreviousRouteService } from 'src/app/services/previous-route/previous-route.service'
import { ReddiService } from 'src/app/services/reddi/reddi.service'
import { ReviewService } from 'src/app/services/review/review.service'
import { SpinnerService } from 'src/app/services/spinner/spinner.service'
import IsUUID from 'validator/es/lib/isUUID'

@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.scss'],
})
export class ClientDashboardComponent {
  _clientEditEnabled: boolean
  _flipHistoryLink: string
  _flipGraphUrl: string
  _flipCreateReportUrl: string
  _flipHasAccess: boolean = false
  _reddiHistoryLink: string
  _reddiGraphUrl: string
  _clientInformationTitle = ''
  _clientInformationDisplayText = ''
  _clientInformationUrl = ''
  _clientInformationAttributes: ClientInformationAttribute[] =
    this.createEmptyAttributeList()
  _clientInFocus: string = ''
  _reviewsTitle: string = 'Loading reviews'
  _reviewsLinkText: string = 'View open & archived reviews'
  _reviewsLinkDestination: string = ''
  _welcomeMessage: string = ''
  _clientDetails: ClientDto
  _latestFlipEntries: FlipHistoryDto[] = new Array<FlipHistoryDto>()
  _latestReddiEntries: ReddiListDto[] = new Array<ReddiListDto>()
  _latestReviews: ReviewTableDTO[] = new Array<ReviewTableDTO>()
  clientId: string = ''
  _age: string = ''
  _dob: Date
  _flipFilters: Filter[]
  _reddiFilters: Filter[]
  //fli-p history table
  _flipHistoryTableData: MatTableDataSource<FlipEntriesTableDTO> =
    new MatTableDataSource<FlipEntriesTableDTO>()
  _flipHistoryTableColumnsToDisplay: string[] = [
    'assessmentDate',
    'score',
    'viewReport',
  ]
  _flipHistoryTableColumnDefinitions: TableColumnDefinition[] =
    ClientDashboardTableColumnDefinitions
  //reddi history table
  _reddiHistoryTableData: MatTableDataSource<ReddiEntriesTableDTO> =
    new MatTableDataSource<ReddiEntriesTableDTO>()
  _reddiHistoryTableColumnsToDisplay: string[] = [
    'presentationDate',
    'presenterName',
    'viewReport',
  ]
  _reddiHistoryTableColumnDefinitions: TableColumnDefinition[] =
    ClientDashboardReddiTableColumnDefinitions

  constructor(
    private route: ActivatedRoute,
    private clientService: ClientService,
    private flipService: FlipService,
    private reddiService: ReddiService,
    private reviewService: ReviewService,
    public featureService: FeaturesService,
    protected spinnerService: SpinnerService,
    public router: Router,
    private bannerService: BannerService,
    private dialog: MatDialog,
    private previousRoute: PreviousRouteService,
  ) {}

  ngOnInit(): void {
    this.spinnerService.show()

    this.route.paramMap.subscribe((paramMap) => {
      this.clientId = paramMap.get('clientId')
      if (!IsUUID(this.clientId)) {
        this.bannerService.showBanner(
          `Invalid clientId ${this.clientId}.`,
          'error',
          30000,
        )
        this.spinnerService.hide()
        throw new Error('Invalid ClientId')
      }
      this._flipFilters = [
        {
          attribute: 'Client/contactid',
          operation: 'eq',
          value: this.clientId,
        },
      ]

      this._reddiFilters = [
        {
          attribute: 'Client/contactid',
          operation: 'eq',
          value: this.clientId,
        },
      ]

      this._flipHistoryLink = '/flip/history/' + this.clientId
      this._flipGraphUrl = '/flip/graph/' + this.clientId
      this._flipCreateReportUrl = '/flip/flip-report-form/' + this.clientId
      this._reddiHistoryLink = '/reddi/history/' + this.clientId
      this._reddiGraphUrl = '/reddi/graph/' + this.clientId

      let requests = {
        clientDetails: this.clientService.Details(this.clientId),
        features: this.featureService.GetFeatures(),
        latestFlipEntries: this.flipService.History(this.clientId),
        latestReddiEntries: this.reddiService.getList(this.clientId),
        latestReviews: this.reviewService.ListUnreviewed(this.clientId),
      }

      forkJoin(requests).subscribe({
        next: ({
          clientDetails,
          features,
          latestFlipEntries,
          latestReddiEntries,
          latestReviews,
        }) => {
          this._clientEditEnabled = this.getFeatureValue(features, 'clientEdit')
          this._clientDetails = clientDetails
          this._flipHistoryTableData =
            this.mapFlipHistoryData(latestFlipEntries)
          this._reddiHistoryTableData =
            this.mapReddiListData(latestReddiEntries)
          this._latestReviews = this.mapReviews(latestReviews)
          this._reviewsTitle =
            'Reviews (' +
            this._latestReviews.length +
            ' of ' +
            latestReviews.length +
            ')'

          this._welcomeMessage = this._clientDetails.preferredFullName
          this._age = this._clientDetails.age
          this._dob = this._clientDetails.dateOfBirth
          this._flipHasAccess = this.clientHasAccessToFlip(this._clientDetails)
          this._clientInformationTitle = this._clientDetails.preferredFullName
          this._reviewsLinkDestination = '/client-reviews/' + this.clientId
          this.setClientInformation(clientDetails)
        },
        error: (error: Error) => {
          this.spinnerService.hide()
          this.bannerService.showBanner(
            'An error occured while retrieving the reference data.',
            'error',
            30000,
          )

          throw error
        },
        complete: () => {
          this.spinnerService.hide()
        },
      })
    })
  }

  routerLinkClicked() {
    this.previousRoute.clearPreviousUrl()
    this.previousRoute.goToPreviousUrl('/dashboard')
  }

  getFeatureValue(features: FeatureStatusRecord[], featureName: string) {
    if (
      features.find((feature) => feature.name === featureName).enabled == null
    ) {
      return false
    } else {
      return features.find((feature) => feature.name === featureName).enabled
    }
  }

  setClientInformation(client: ClientDto) {
    this._clientInFocus = client.contactId
    this._clientInformationDisplayText = this._clientEditEnabled
      ? 'Edit profile'
      : ''
    this._clientInformationUrl = this._clientEditEnabled
      ? '/edit-client/' + client.contactId
      : ''

    this.setAttribute(this._clientInformationAttributes, 'Age', client.age)
    this.setAttribute(
      this._clientInformationAttributes,
      'DOB',
      moment(client.dateOfBirth).format('Do MMMM, YYYY'),
    )
    this.setAttribute(
      this._clientInformationAttributes,
      'Gender',
      client.genderCodeName,
    )
    this.setAttribute(
      this._clientInformationAttributes,
      'Case Manager',
      client.clientCaseManagerName,
    )
  }

  clientHasAccessToFlip(client: ClientDto): boolean {
    const flipTool = client.tools.find(
      (tool: any) =>
        tool.toolName === 'Functional Listening Index - Paediatric (FLI™-P)',
    )
    return flipTool !== undefined
  }

  createAttribute(
    display: string,
    value: string = '',
    placeholder: string = '',
  ): ClientInformationAttribute {
    return {
      display: display,
      value: value,
      placeholder: placeholder,
    }
  }

  createEmptyAttributeList(): ClientInformationAttribute[] {
    return [
      this.createAttribute('Age', '', ''),
      this.createAttribute('DOB', '', ''),
      this.createAttribute('Gender', '', ''),
      this.createAttribute('Case Manager', '', ''),
    ]
  }

  getAttribute(
    attributes: ClientInformationAttribute[],
    display: string,
  ): ClientInformationAttribute {
    return attributes.find((attribute) => attribute.display === display)
  }

  setAttribute(
    attributes: ClientInformationAttribute[],
    display: string,
    value: string,
  ) {
    this.getAttribute(attributes, display).value = value
  }

  redirect(destination: string) {
    this.router.navigate([destination])
  }

  onQuickViewButtonClick(event: QuickViewEventDTO) {}

  mapFlipHistoryData(
    flipHistory: FlipHistoryDto[],
  ): MatTableDataSource<FlipEntriesTableDTO> {
    const tableData: FlipEntriesTableDTO[] = new Array<FlipEntriesTableDTO>()

    //Sort the source
    flipHistory.sort((a, b) => {
      return (
        moment(b.assessmentDate).valueOf() - moment(a.assessmentDate).valueOf()
      )
    })

    // take the top 3 only
    const result = flipHistory.slice(0, 3)
    result.forEach((element) => {
      const target = new FlipEntriesTableDTO()
      target.assessmentDate = moment(element.assessmentDate).format(
        'Do MMMM, YYYY',
      )
      target.score = element.score
      target.scoreWithinExpectedRange = ''

      target.viewReport = new TableLinkColumnDTO()
      target.viewReport.linkText = 'View details'
      target.viewReport.linkColor = 'accent'
      target.viewReport.linkData = element.instanceId
      target.viewReport.linkUrl =
        '/flip/instance/' + this.clientId + '/' + element.instanceId

      tableData.push(target)
    })

    let dataSource = new MatTableDataSource<FlipEntriesTableDTO>(tableData)
    return dataSource
  }

  mapReddiListData(
    reddiList: ReddiListDto[],
  ): MatTableDataSource<ReddiEntriesTableDTO> {
    const tableData: ReddiEntriesTableDTO[] = new Array<ReddiEntriesTableDTO>()
    reddiList.sort((a, b) => {
      return (
        moment(b.presentationDate).valueOf() -
        moment(a.presentationDate).valueOf()
      )
    })

    // take the top 3 only
    const result = reddiList.slice(0, 3)
    result.forEach((element) => {
      const target = new ReddiEntriesTableDTO()
      target.presentationDate = moment(element.presentationDate).format(
        'Do MMMM, YYYY',
      )
      target.presenterName = element.presenterName

      target.viewReport = new TableLinkColumnDTO()
      target.viewReport.linkText = 'View details'
      target.viewReport.linkColor = 'accent'
      target.viewReport.linkData = element.reddiId
      target.viewReport.linkUrl =
        '/reddi/instance/' + this.clientId + '/' + element.reddiId

      tableData.push(target)
    })
    let dataSource = new MatTableDataSource<ReddiEntriesTableDTO>(tableData)

    return dataSource
  }

  mapReviews(reviews: ReviewDto[]): ReviewTableDTO[] {
    const tableData: ReviewTableDTO[] = new Array<ReviewTableDTO>()

    reviews.sort((a, b) => {
      return moment(b.createdOn).valueOf() - moment(a.createdOn).valueOf()
    })

    // take the top 4 only
    const result = reviews.slice(0, 4)
    result.forEach((element) => {
      const target = new ReviewTableDTO()

      target.category = new TableCategoryLinkColumnDTO()
      let temp = element.severityName.replace('For ', '')
      if (temp.toUpperCase().includes('ACTION')) {
        target.category.linkColor = 'warn'
      } else {
        target.category.linkColor = 'primary'
      }
      target.category.linkText = temp
      target.comments = element.comments
      target.createdOn = moment(element.createdOn).format('Do MMMM, YYYY')
      target.fullName = new TableLinkColumnDTO()
      target.fullName.linkText = this._clientDetails.preferredFullName
      target.reviewId = element.reviewId
      target.reviewRule = element.ruleName
      target.reviewType = element.typeName
      target.reviewed = element.reviewed

      tableData.push(target)
    })
    return tableData
  }

  navigateTo(target: string) {
    switch (target) {
      case 'newFlip':
        this.redirect('/flip-assessment/' + this.clientId)
        break

      case 'newReddi':
        this.redirect('/reddi/assessment/' + this.clientId)
        break

      default:
        break
    }
  }

  onLinkButtonClick(event: ReviewDto) {
    this.openDialog(event)
  }

  async openDialog(selected: any) {
    const dr = this.dialog.open(ReviewModalComponent, {
      width: '704px',
      height: '484px',
      panelClass: 'review-panel',
      data: {
        dataKey: selected,
      },
    })
    dr.afterClosed().subscribe((result) => {
      if (result) {
        this.loadReviews()
      }
    })
  }

  loadReviews() {
    let requests = {
      latestReviews: this.reviewService.ListUnreviewed(this.clientId),
    }

    forkJoin(requests).subscribe({
      next: ({ latestReviews }) => {
        this._latestReviews = this.mapReviews(latestReviews)
        this._reviewsTitle =
          'Reviews (' +
          this._latestReviews.length +
          ' of ' +
          latestReviews.length +
          ')'
      },
      error: (error: Error) => {
        this.spinnerService.hide()
        this.bannerService.showBanner(
          'An error occured while retrieving the reference data.',
          'error',
          30000,
        )

        throw error
      },
      complete: () => {
        this.spinnerService.hide()
      },
    })
  }
}
