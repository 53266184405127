import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ReviewTableDTO } from 'src/app/models/table-defs/review-table-dto'
import { BannerService } from 'src/app/services/banner/banner.service'
import { ReviewService } from 'src/app/services/review/review.service'
import { SpinnerService } from 'src/app/services/spinner/spinner.service'

@Component({
  selector: 'app-review-modal',
  templateUrl: './review-modal.component.html',
  styleUrls: ['./review-modal.component.scss'],
})
export class ReviewModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReviewModalComponent>,
    private reviewService: ReviewService,
    public spinnerService: SpinnerService,
    private bannerService: BannerService,
  ) {}

  showActions: boolean = true
  reviewDetail: ReviewTableDTO = new ReviewTableDTO()

  ngOnInit(): void {
    //Get the Review and populate the form
    this.reviewDetail = this.data.dataKey
    this.showActions = !this.reviewDetail.reviewed
  }

  markAsReviewed(value: 'cancel' | 'continue') {
    // Update the review as reviewed
    if (value == 'cancel') {
      this.dialogRef.close(false)
      return
    } else {
      this.spinnerService.show()
      this.reviewService.MarkReviewed(this.reviewDetail.reviewId).subscribe({
        next: (result) => {
          this.bannerService.showBanner(
            'Review marked as reviewed',
            'success',
            3000,
          )
          this.spinnerService.hide()
          this.dialogRef.close(true)
        },
        error: (error) => {
          this.bannerService.showBanner(
            'An error occurred when marking as reviewed.',
            'error',
          )
          this.spinnerService.hide()
          throw error
        },
        complete: () => {
          this.spinnerService.hide()
        },
      })
    }
  }
}
