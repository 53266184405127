import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import * as moment from 'moment'
import { Observable, Subscription, switchMap, timer } from 'rxjs'
import { ClientDto } from 'src/app/models/client/client-dto'
import { ClientTableDTO } from 'src/app/models/client/client-table-dto'
import { ClientSearchTableColumnDefinitions } from 'src/app/models/table-defs/client-search-table-def'
import { TableColumnDefinition } from 'src/app/models/table/table-column-definition'
import { TableLinkColumnDTO } from 'src/app/models/table/table-link-column-dto'
import { ClientService } from '../../../services/client/client.service'
import { distinctUntilChanged } from 'rxjs/operators'
import { SpinnerService } from '../../../services/spinner/spinner.service'

@Component({
  selector: 'app-client-search',
  templateUrl: './client-search.component.html',
  styleUrls: ['./client-search.component.scss'],
})
export class ClientSearchComponent implements OnInit, OnDestroy {
  @Input() pageMode: string
  _searchValue: string = ''

  _dataLoaded: boolean = false
  _panelVisible: boolean = false
  _tableColumnsToDisplay: string[] = [
    'preferredFullName',
    'age',
    'dateOfBirth',
    'clientPrimaryCentreName',
  ]
  _tableColumnDefinitions: TableColumnDefinition[] =
    ClientSearchTableColumnDefinitions
  _tableData: MatTableDataSource<ClientTableDTO>
  debounceTimeValue = 300
  private searchResultsSubscription: Subscription

  constructor(
    public spinnerService: SpinnerService,
    public clientService: ClientService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this._tableData = this.mapClientData([])
    this._dataLoaded = true
  }

  ngOnDestroy() {
    if (this.searchResultsSubscription) {
      this.searchResultsSubscription.unsubscribe()
    }
  }

  @HostListener('document:click', ['$event'])
  clickOut() {
    this._panelVisible = false
    this.clearFilter()
  }

  async applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value

    if (this.searchResultsSubscription) {
      this.searchResultsSubscription.unsubscribe()
    }

    if (!filterValue) {
      return // no need to process if filter is empty
    }

    this.spinnerService.show()
    this.searchResultsSubscription = timer(this.debounceTimeValue) // Introduce a delay
      .pipe(
        switchMap(() => this.searchData(filterValue.trim().toLowerCase())),
        distinctUntilChanged(), // Only proceed if the value has changed since the last emission
      )
      .subscribe((results) => {
        this._tableData = this.mapClientData(results)
        this.cdr.detectChanges()
        this._dataLoaded = true
        this.spinnerService.hide()
      })
  }

  clearFilter() {
    this._searchValue = ''
    this._tableData = this.mapClientData([])
    this._tableData.filter = ''
  }

  searchData(text: string): Observable<ClientDto[]> {
    return this.clientService.SearchList(null, text)
  }

  mapClientData(clients: ClientDto[]): MatTableDataSource<ClientTableDTO> {
    const tableData: ClientTableDTO[] = new Array<ClientTableDTO>()

    clients.forEach((source) => {
      const target = new ClientTableDTO()
      target.age = source.age
      target.clientPrimaryCentreName = source.clientPrimaryCentreName
      target.dateOfBirth = moment(source.dateOfBirth).format('Do MMMM, YYYY')
      target.genderCodeName = source.genderCodeName

      target.preferredFullName = new TableLinkColumnDTO()
      target.preferredFullName.linkText = source.preferredFullName
      target.preferredFullName.linkUrl = '/client-dashboard/' + source.contactId
      target.preferredFullName.linkData = source.contactId
      target.preferredFullName.selected = false

      tableData.push(target)
    })

    const dataSource = new MatTableDataSource<ClientTableDTO>(tableData)
    dataSource.filterPredicate = this.searchFilter

    return dataSource
  }

  searchFilter(data: ClientTableDTO, filter: string): boolean {
    return data.preferredFullName.linkText.toLowerCase().trim().includes(filter)
  }

  showPanel() {
    this._panelVisible = true
  }
}
