import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError, map, Observable, tap } from 'rxjs'
import { ClientDto } from 'src/app/models/client/client-dto'
import { ToolSummaryDto } from 'src/app/models/client/client-toolsummary-dto'
import { ClientUpdateDto } from 'src/app/models/client/client-update-dto'
import { ApplicationInsightsService } from '../application-insights/application-insights.service'
import { ConfigService } from '../config/config.service'
import { SearchParametersDto } from '../../models/Search/SearchParametersDto'
import { ClientScore } from 'src/app/models/client/client-score-dto'
import { FlipScores } from 'src/app/models/flip/flip-score-dto'

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private readonly clientBaseUrl =
    this.config.getSettings('webApi').baseUri + 'client/'
  private readonly clientListUrl = this.clientBaseUrl + 'list'
  private readonly clientOrgListUrl = this.clientBaseUrl + 'list/org'
  private readonly clientDetailUrl = this.clientBaseUrl + 'details'
  private readonly clientToolSummaryUrl = this.clientBaseUrl + 'toolsummaries'
  private readonly clientCreateClientUrl = this.clientBaseUrl + 'create'
  private readonly clientUpdateClientUrl = this.clientBaseUrl + 'update'
  private readonly clientSearchUrl = this.clientBaseUrl + 'list'
  
  private readonly getClientDetailsAndScore = this.clientBaseUrl + 'getclientdetailsandscore'
  private readonly pollingAmount: number =
    this.config.getSettings('webApi').creationPollingAmount

  constructor(
    private http: HttpClient,
    private appInsights: ApplicationInsightsService,
    private config: ConfigService,
  ) {}

  List(
    caseManagerContactId?: string,
    orderByColumnName?: string,
    orderBy?: string,
    maxRecords?: number,
  ): Observable<ClientDto[]> {
    const params: Record<string, any> = {}
    if (!caseManagerContactId) {
      params[caseManagerContactId] = caseManagerContactId
    }
    if (!orderByColumnName) {
      params[orderByColumnName] = orderByColumnName
    }
    if (!orderBy) {
      params[orderBy] = orderBy
    }
    if (!maxRecords) {
      params[maxRecords] = maxRecords
    }

    return this.http
      .get<ClientDto[]>(this.clientListUrl, { params: params })
      .pipe(
        map((response: any) => {
          return response as ClientDto[]
        }),
        tap((_) => this.appInsights.logEvent('Retrieved  DataVerse data')),
        catchError(async (err) => {
          //Let the top level component handle the error
          throw err
        }),
      )
  }

  SearchList(
    caseManagerContactId?: string,
    searchString?: string,
  ): Observable<ClientDto[]> {
    let searchParametersDto = new SearchParametersDto()
    searchParametersDto.PageMode = 'all-clients'
    if (caseManagerContactId) {
      searchParametersDto.CaseManagerContactId = caseManagerContactId
    }
    if (searchString) {
      searchParametersDto.SearchString = searchString
    }

    return this.http
      .post<ClientDto[]>(this.clientSearchUrl, searchParametersDto)
      .pipe(
        map((response: any) => {
          return response as ClientDto[]
        }),
        tap((_) =>
          this.appInsights.logEvent('Retrieved DataVerse search data'),
        ),
        catchError(async (err) => {
          //Let the top level component handle the error
          throw err
        }),
      )
  }

  OrgClients(
    orderByColumnName?: string,
    orderBy?: string,
    maxRecords?: number,
  ): Observable<ClientDto[]> {
    const params: Record<string, any> = {}
    if (orderByColumnName) {
      params['orderByColumnName'] = orderByColumnName
    }
    if (orderBy) {
      params['orderBy'] = orderBy
    }
    if (maxRecords) {
      params['maxRecords'] = maxRecords
    }

    return this.http.get<any>(this.clientOrgListUrl, { params: params }).pipe(
      map((response: any) => {
        return response as ClientDto[]
      }),
      tap((_) => this.appInsights.logEvent('Retrieved Org Clients')),
      catchError(async (err) => {
        throw err
      }),
    )
  }

  Details(contactId: string): Observable<ClientDto> {
    const params: Record<string, any> = { contactId: contactId }

    return this.http.get<any>(this.clientDetailUrl, { params: params }).pipe(
      tap((_) => this.appInsights.logEvent('Retrieved Client Details')),
      catchError(async (err) => {
        throw err
      }),
    )
  }

  DetailsAndSCore(contactId: string): Observable<{clientScore: ClientScore[], flipScores: FlipScores[]}> {
    const params: Record<string, any> = { contactId: contactId };
  
    return this.http.get<{flipGraphDatas: any[], flipUpperAndLowerLimits: any[]}>(this.getClientDetailsAndScore, { params: params }).pipe(
      map(response => {
        const clientScores = response?.flipGraphDatas?.map(item => new ClientScore({
          ageinMonths: Number(item.ageInMonths), // Convert years to months
          totalScoreComparision: item.scoreComparision ? Number(item.scoreComparision) : null, // Nullable number
          totalScoreProfessional: Number(item.totalScoreProfessional) // Ensure number
        })) ?? [];
  
        const flipScores = response?.flipUpperAndLowerLimits?.map(item => new FlipScores({
          ageinMonths: Number(item.ageInMonths),
          tscUpperLimit: Number(item.upperLimit), // Ensure number
          tscLowerLimit: Number(item.lowerLimit) // Ensure number
        })) ?? [];
  
        return { clientScore: clientScores, flipScores: flipScores };
      }),
      tap(_ => this.appInsights.logEvent('Retrieved Client Score and Limits Data')),
      catchError(async (err) => {
        throw err;
      }),
    );
  }
  
  
  GetToolSummaries(
    contactId: string,
    toolType: string,
  ): Observable<ToolSummaryDto[]> {
    const params: Record<string, any> = {
      contactId: contactId,
      toolType: toolType,
    }

    return this.http
      .get<any>(this.clientToolSummaryUrl, { params: params })
      .pipe(
        tap((_) =>
          this.appInsights.logEvent('RetrievedClient Tool Summaries.'),
        ),
        catchError(async (err) => {
          throw err
        }),
      )
  }

  CreateClient(client: ClientDto): Observable<any> {
    const params = new HttpParams().set('pollingAmount', this.pollingAmount)

    return this.http
      .post<any>(this.clientCreateClientUrl, client, { params: params })
      .pipe(
        tap((_) => this.appInsights.logEvent('Client Created.')),
        catchError(async (err) => {
          throw err
        }),
      )
  }

  UpdateClient(client: ClientUpdateDto): Observable<any> {
    return this.http.patch<any>(this.clientUpdateClientUrl, client).pipe(
      tap((_) => this.appInsights.logEvent('Client Updated')),
      catchError(async (err) => {
        throw err
      }),
    )
  }
}
