import { Component, Input, OnInit, ViewChild } from '@angular/core'
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import * as moment from 'moment/moment'
import { Moment } from 'moment/moment'
import { FlipService } from '../../../services/flip/flip.service'
import { AssessmentDto } from '../../../models/flip/assessment-dto'
import { AssessmentGroupDto } from '../../../models/flip/assessment-group-dto'
import { AssessmentItemDto } from '../../../models/flip/assessment-item-dto'
import { LogLevel } from '../../../common/log-level'
import { LogService } from '../../../services/logger/log.service'
import { PreviousRouteService } from '../../../services/previous-route/previous-route.service'
import { ClientService } from '../../../services/client/client.service'
import { ClientDto } from '../../../models/client/client-dto'
import { PresenterRole } from '../../../services/reddi/reddi-utils'
import { AreYouSureModalComponent } from '../../complex/modals/are-you-sure-modal/are-you-sure-modal.component'
import { finalize, firstValueFrom, forkJoin } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'
import { FlipAssessmentSideCardComponent } from '../../core/flip-assessment-side-card/flip-assessment-side-card.component'
import { ActivatedRoute, Router } from '@angular/router'
import { SpinnerService } from '../../../services/spinner/spinner.service'
import { FlipItem } from '../../../models/flip/flip-item'
import { formatDate } from '@angular/common'
import { BannerService } from '../../../services/banner/banner.service'

@Component({
  selector: 'app-flip-assessment',
  templateUrl: './flip-assessment.component.html',
  styleUrls: ['./flip-assessment.component.scss'],
})
export class FlipAssessmentComponent implements OnInit {
  @Input() clientId: string
  @Input() type: string = 'FLI_P'
  maxLength: number = 500

  _clientForm = new FormGroup({
    assessmentDate: new FormControl<Moment>(null, [
      Validators.required,
      this.dateValidator(),
    ]),
    textArea: new FormControl<string>('', [
      Validators.maxLength(this.maxLength),
    ]),
  })

  @ViewChild(FlipAssessmentSideCardComponent, { static: false })
  flipAssessmentSideCardComponent: FlipAssessmentSideCardComponent
  _flipAssessmentDataDto: AssessmentDto
  _currentFlipAssessmentGroup: AssessmentGroupDto
  _helpTipTitle = 'Help tip'
  _clientName: string
  _age: string
  _dateOfBirth: Date = new Date()
  _stillProcessing: boolean = true
  _ceilingMet: boolean = false
  _hideFlipAssessmentActionButton: boolean = false
  _actionsTaken: string[] = []
  _currItem: FlipItem
  _showClientDetails: boolean = false
  _scrollIndex: number
  _dashboardUrl: string
  private _clientDto: ClientDto
  private _basalMet: boolean = false
  private _basalCount: number = 0
  private _basalMax: number = 4
  private _ceilingMax: number = 6
  private _items: FlipItem[]
  private _lastAssessmentDate: Date

  constructor(
    public flipService: FlipService,
    public router: Router,
    private logger: LogService,
    private clientService: ClientService,
    private dialog: MatDialog,
    private previousRouteService: PreviousRouteService,
    private route: ActivatedRoute,
    public spinnerService: SpinnerService,
    public bannerService: BannerService,
  ) {}

  ngOnInit() {
    this.spinnerService.show()

    this.clientId = this.route.snapshot.params['clientId']
    this._dashboardUrl = 'client-dashboard/' + this.clientId
    forkJoin([
      this.clientService.Details(this.clientId),
      this.flipService.GetAssessment(this.clientId, this.type),
      this.flipService.GetLastAssessment(this.clientId),
    ])
      .pipe(
        finalize(() => {
          this.spinnerService.hide()
          this._stillProcessing = false
        }),
      )
      .subscribe(
        ([clientDto, assessmentDto, assessmentDto2]) => {
          this._clientDto = clientDto
          this._clientName = this._clientDto.preferredFullName
          this._age = this._clientDto.age
          this._dateOfBirth = this._clientDto.dateOfBirth
          this._showClientDetails = true
          this._flipAssessmentDataDto = assessmentDto
          this._basalMax = this._flipAssessmentDataDto.basal
          this._ceilingMax = this._flipAssessmentDataDto.ceiling
          this._flipAssessmentDataDto.assessmentDate =
            this._flipAssessmentDataDto.assessmentDate ?? moment().toDate()
          this._flipAssessmentDataDto.clientId = this.clientId
          const flipAssessmentAllItems =
            this._flipAssessmentDataDto.groups.flatMap((a) => a.items)
          this.extractItems(flipAssessmentAllItems)
          this._currItem = this.getInitialItem()
          this._currentFlipAssessmentGroup =
            this._flipAssessmentDataDto.groups.find(
              (a) => a.groupId == this._currItem.assessmentItem.groupId,
            )
          this._currItem.assessmentItem.selected = true
          this._scrollIndex = this._currItem.index
          const controls = this._clientForm.controls
          controls.assessmentDate.setValue(
            moment(this._flipAssessmentDataDto.assessmentDate),
          )

          const lastad = new Date(assessmentDto2.assessmentDate)
          if (this.isFirstAssessment(lastad)) {
            this._lastAssessmentDate = this._dateOfBirth
          } else {
            this._lastAssessmentDate = new Date(
              lastad.getFullYear(),
              lastad.getMonth(),
              lastad.getDate(),
            )
          }
        },
        (error) => {
          this.spinnerService.hide()
          console.log(error)
          throw error
        },
      )
  }

  isFirstAssessment(assessmentDate: Date): boolean {
    return (
      assessmentDate.getFullYear() < new Date(this._dateOfBirth).getFullYear()
    )
  }

  extractItems(flipAssessmentAllItems: AssessmentItemDto[]) {
    this._items = []
    for (let count = 0; count < flipAssessmentAllItems.length; count++) {
      let item = flipAssessmentAllItems[count]

      let flipItem: FlipItem = {
        id: item.orderNumber,
        index: count,
        prevAnswer: item.score,
        currAnswer: null,
        assessmentItem: item,
      }

      this._items.push(flipItem)
    }
  }

  getAssessmentErrorText(): string {
    const from = formatDate(this._lastAssessmentDate, 'dd/MM/yyyy', 'en')
    const to = formatDate(new Date(), 'dd/MM/yyyy', 'en')
    return 'Date should be between ' + from + ' and ' + to
  }

  submit() {
    if (!this._clientForm.valid) {
      return
    }
    this.spinnerService.show()
    this._hideFlipAssessmentActionButton = true
    this._flipAssessmentDataDto.assessmentDate =
      this._clientForm.controls.assessmentDate.value.toDate()
    this._flipAssessmentDataDto.notes = this._clientForm.controls.textArea.value
    this._flipAssessmentDataDto.finishingSession = true
    this._flipAssessmentDataDto.presenterRole = PresenterRole.Professional
    this.flipService.Submit(this._flipAssessmentDataDto).subscribe({
      next: (response: any) => {
        this.logger.log(
          'Submit Assessment completed successfully',
          LogLevel.Debug,
          this._flipAssessmentDataDto,
        )
      },
      error: (error: any) => {
        this.spinnerService.hide()
        this.bannerService.showBanner(
          'Error creating FLI-P Assessment.',
          'error',
          5000,
        )
        this._hideFlipAssessmentActionButton = false
        throw error
      },
      complete: () => {
        this.spinnerService.hide()
        this.bannerService.showBanner(
          'FLI-P Assessment Created Successfully.',
          'success',
          5000,
        )
        this._clientForm.markAsPristine()
        // go back to dashboard
        this.router.navigateByUrl(this._dashboardUrl)
      },
    })
  }

  async areYouSureDialog(): Promise<boolean> {
    const dr = this.dialog.open(AreYouSureModalComponent, {
      width: '605px',
      height: '316px',
      panelClass: 'are-you-sure-panel',
    })

    dr.afterClosed().subscribe((result) => {
      if (result) {
        // redirect to caller
        this.previousRouteService.goToPreviousUrl(this._dashboardUrl)
      }
    })

    return await firstValueFrom(dr.afterClosed())
  }

  showConfirmationDialog(): Promise<boolean> {
    return Promise.resolve(this.areYouSureDialog())
  }

  dateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value

      if (!value) {
        return null
      }

      if (this._lastAssessmentDate !== undefined) {
        const isBefore = moment(value).isBefore(this._lastAssessmentDate)
        if (isBefore) {
          return { inPast: true }
        }
      }

      // isAfter() will default to current date time and the field doesn't use time
      // so value = 1/1/2020 00:00:00 now = 1/1/2020 01:38:21
      return moment(value).isAfter() ? { inFuture: true } : null
    }
  }

  appendAction(response: boolean) {
    this._actionsTaken.push(response ? 'Mostly' : 'Rarely')
  }

  recordActivity(response: boolean) {
    this.appendAction(response)
    this._currItem.currAnswer = response
    this._currItem.assessmentItem.score = response

    this.processAssessmentLogic(response)

    if (this._ceilingMet) {
      // all done
    } else if (!this._basalMet) {
      // select new item
      this._currItem.assessmentItem.selected = false // old item deselected
      this._currItem = this.searchForBasalItem(this._currItem.index - 1)
      this._currItem.assessmentItem.selected = true // new item selected
    } else {
      this._currItem.assessmentItem.selected = false // old item deselected
      this._currItem = this.getNextItem()
      this._currItem.assessmentItem.selected = true // new item selected
    }

    this.flipAssessmentSideCardComponent.scrollToItem(this._currItem.index) // scroll to the right place
    this._currentFlipAssessmentGroup = this._flipAssessmentDataDto.groups.find(
      (a) => a.groupId == this._currItem.assessmentItem.groupId,
    )
  }

  processAssessmentLogic(response: boolean) {
    // If we are still establishing basal
    if (!this._basalMet) {
      if (response) {
        this._basalCount++
        this._basalMet = this._basalCount >= this._basalMax
      } else {
        this._basalCount = 0
      }

      // In the main assessment
    } else {
      this._ceilingMet = this.isAssessmentComplete()
    }
  }

  areAllQuestionsAnswered(): boolean {
    // Exit if we have reached the end of the channel
    const index = this._items.findIndex((a) => a.id == this._currItem.id)
    if (index == this._items.length - 1) {
      return true
    }

    // If all questions have been answered lets enable save
    return this._items.filter((a) => a.currAnswer == null).length == 0
  }

  isAssessmentComplete(): boolean {
    let rarelys = 0

    if (this.areAllQuestionsAnswered()) {
      return true
    }

    for (let i = this._items.length - 1; i >= 0; i--) {
      let item = this._items[i]

      if (item.currAnswer || (item.prevAnswer && item.currAnswer === null)) {
        rarelys = 0
      } else if (item.currAnswer === false) {
        rarelys += 1
      }

      if (rarelys >= 6) {
        return true
      }
    }

    return false
  }

  getInitialItem(): FlipItem {
    return this.searchForBasalItem(this._items.length - 1)
  }

  searchForBasalItem(startPos: number): FlipItem {
    for (let i = startPos; i >= 0; i--) {
      let item = this._items[i]
      if (item.prevAnswer) {
        return item
      }
    }

    this._basalMet = true
    return this.getNextItem()
  }

  getNextItem(): FlipItem {
    let item: FlipItem

    for (let i = 0; i < this._items.length; i++) {
      item = this._items[i]
      if (item.currAnswer == null && !item.prevAnswer) {
        return item
      }
    }

    return item
  }

  clear() {
    this._basalCount = 0
    this._basalMet = false
    this._ceilingMet = false

    for (let i = 0; i < this._items.length; i++) {
      this._items[i].currAnswer = null
    }

    this._actionsTaken = []

    this._currItem = this.getInitialItem()
  }

  getAssessmentText() {
    return this._ceilingMet
      ? 'Assessment has ended'
      : 'Assessment in progress....'
  }
}
