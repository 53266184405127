import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { CheckBoxData } from '../../../models/reddi/checkbox-data.model'

@Component({
  selector: 'app-reddi-tabular-checkbox',
  templateUrl: './reddi-tabular-checkbox.component.html',
  styleUrls: ['./reddi-tabular-checkbox.component.scss'],
})
export class ReddiTabularCheckboxComponent {
  @Input() checkboxes: CheckBoxData[]
  @Input() type: 'normal' | 'large'
  @Output() checkboxChange = new EventEmitter<CheckBoxData[]>()
  constructor(private changeDetector: ChangeDetectorRef) {}

  onCheckboxChange(checkbox: CheckBoxData) {
    this.checkboxes.forEach((item) => {
      if (item.id !== checkbox.id) {
        item.checked = false
      }
    })
    this.checkboxChange.emit(this.checkboxes)
    this.changeDetector.detectChanges()
  }
}
