import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute } from '@angular/router'
import * as moment from 'moment'
import { Moment } from 'moment'
import { firstValueFrom, forkJoin, of } from 'rxjs'
import { LogLevel } from 'src/app/common/log-level'
import { AreYouSureModalComponent } from 'src/app/components/complex/modals/are-you-sure-modal/are-you-sure-modal.component'
import { ClientDto } from 'src/app/models/client/client-dto'
import { ClientUpdateDto } from 'src/app/models/client/client-update-dto'
import { Datum } from 'src/app/models/common/datum'
import { CaseManagerDto } from 'src/app/models/lookup/case-manager-dto'
import { GenderDto } from 'src/app/models/lookup/gender-dto'
import { LocationDto } from 'src/app/models/lookup/location-dto'
import { BannerService } from 'src/app/services/banner/banner.service'
import { ClientService } from 'src/app/services/client/client.service'
import { LogService } from 'src/app/services/logger/log.service'
import { LookupService } from 'src/app/services/lookup/lookup.service'
import { PreviousRouteService } from 'src/app/services/previous-route/previous-route.service'
import { SpinnerService } from 'src/app/services/spinner/spinner.service'
import IsUUID from 'validator/es/lib/isUUID'

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
})
export class ClientComponent implements OnInit {
  _clientForm = new FormGroup({
    firstName: new FormControl(null),
    clientType: new FormControl('standard', [Validators.required]),
    preferredName: new FormControl(null),
    lastName: new FormControl(null, [Validators.required]),
    gender: new FormControl(null),
    dateOfBirth: new FormControl<Moment>(null, [
      Validators.required,
      this.futureDateValidator(),
    ]),
    caseManager: new FormControl<Datum>(null),
    primaryLocation: new FormControl(null),
  })

  _mode: 'add' | 'edit'
  _pageTitle: string = 'Client'

  _clientId: string
  _client: ClientDto

  _caseManagers: Datum[] = []
  _clientTypes: Datum[] = [
    { label: 'Standard', value: 'standard' },
    { label: 'De-Identified', value: 'de-identified' },
  ]
  _genders: Datum[] = []
  _locations: Datum[] = []

  _dialogResult: boolean

  constructor(
    protected spinnerService: SpinnerService,
    protected lookupService: LookupService,
    private clientService: ClientService,
    private logger: LogService,
    private dialog: MatDialog,
    private bannerService: BannerService,
    private previousRouteService: PreviousRouteService,
    private router: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    // Turn on Spinner
    this.spinnerService.show()

    const routeName: string = this.router.snapshot.url[0].path

    if (routeName === 'add-client') {
      this.configurePageForAdd()
    } else if (routeName === 'edit-client') {
      this.configurePageForEdit()
    } else {
      throw new Error(`Invalid route ${routeName}`)
    }
  }

  routerLinkClicked() {
    const prevUrl = this.previousRouteService.getPreviousUrl()
    if (prevUrl) {
      this.previousRouteService.goToPreviousUrl(prevUrl)
    }
  }

  hasLocations(): boolean {
    return this._locations.length > 0
  }

  configurePageForAdd() {
    this._mode = 'add'
    this._pageTitle = 'Add Client'
    this.fetchData(false, '')
  }

  configurePageForEdit() {
    this._mode = 'edit'
    this._pageTitle = 'Edit Client'

    this._clientId = this.router.snapshot.paramMap.get('clientId')
    if (!IsUUID(this._clientId)) {
      this.bannerService.showBanner(
        `Invalid clientId ${this._clientId}.`,
        'error',
        30000,
      )
      throw new Error('Invalid ClientId')
    }

    this._clientForm.controls.clientType.disable()
    this.fetchData(true, this._clientId)
  }

  fetchData(fetchClient: boolean, clientId: string) {
    let requests = {
      genders: this.lookupService.Genders(),
      locations: this.lookupService.Locations(),
      caseManagers: this.lookupService.CaseManagers(),
      client: fetchClient ? this.clientService.Details(clientId) : of(null),
    }

    forkJoin(requests).subscribe({
      next: ({ genders, locations, caseManagers, client }) => {
        this._genders = this.processGenders(genders)
        this._locations = this.processLocations(locations)
        this._caseManagers = this.processCaseManagers(caseManagers)
        this._client = client
        if (fetchClient) {
          this.mapClientToControls()
        }
      },
      error: (error: Error) => {
        this.spinnerService.hide()
        this.bannerService.showBanner(
          'An error occured while retrieving the reference data.',
          'error',
          30000,
        )

        throw error
      },
      complete: () => {
        this.spinnerService.hide()
      },
    })
  }

  mapClientToControls() {
    const controls = this._clientForm.controls

    let caseManager = this.findDatumByValue(
      this._caseManagers,
      this._client.clientCaseManagerId,
    )
    controls.caseManager.setValue(caseManager)

    controls.dateOfBirth.setValue(moment(this._client.dateOfBirth))
    controls.firstName.setValue(this._client.firstname)

    let clientGender = this.findDatumByValue(
      this._genders,
      this._client.genderCode.toString(),
    )
    controls.gender.setValue(
      clientGender != null ? clientGender.value : clientGender,
    )

    controls.lastName.setValue(this._client.lastname)
    controls.preferredName.setValue(this._client.preferredName)

    let location = this.findDatumByValue(
      this._locations,
      this._client.clientPrimaryCentreId,
    )
    controls.primaryLocation.setValue(
      location != null ? location.value : location,
    )
  }

  findDatumByValue(arr: Datum[], target: string): Datum {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].value == target) {
        return arr[i]
      }
    }
    return null
  }

  processGenders(genders: GenderDto[]): Datum[] {
    let result: Datum[] = []

    // Push an empty value as a clearable option
    if (genders.length > 0) {
      result.push({ label: '', value: null })
    }

    genders.forEach((gender) =>
      result.push({
        label: gender.label,
        value: gender.value.toString(),
      }),
    )

    return result
  }

  processLocations(locations: LocationDto[]): Datum[] {
    let result: Datum[] = []

    // Push an empty value as a clearable option
    if (locations.length > 0) {
      result.push({ label: '', value: null })
    }

    locations.forEach((location) => {
      result.push({
        label: location.name,
        value: location.locationId,
      })
    })

    return result
  }

  processCaseManagers(caseManagers: CaseManagerDto[]): Datum[] {
    let result: Datum[] = []

    caseManagers.forEach((caseManager) => {
      result.push({
        label: caseManager.firstName + ' ' + caseManager.lastName,
        value: caseManager.contactId,
      })
    })

    return result
  }

  futureDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value

      if (!value) {
        return null
      }

      // isAfter() will default to current date time and the field doesn't use time
      // so value = 1/1/2020 00:00:00 now = 1/1/2020 01:38:21
      return moment(value).isAfter() ? { inFuture: true } : null
    }
  }

  GetDateOfBirthErrorText(): string {
    if (this._clientForm.controls.dateOfBirth.getError('inFuture') != null) {
      return 'Date of Birth cannot be in the future.'
    }
    return 'Date of Birth is invalid.'
  }

  async CreateClient() {
    let client: ClientDto = this.MapControlsToClientDto()

    await firstValueFrom(this.clientService.CreateClient(client)).then(
      () => {
        this.logger.log(
          'Create Client completed successfully',
          LogLevel.Debug,
          client,
        )

        this.bannerService.showBanner(
          'Successfully created client.',
          'success',
          1000,
        )

        this._clientForm.markAsPristine()
        this.previousRouteService.goToPreviousUrl('/dashboard')
      },
      (error: HttpErrorResponse) => {
        this.spinnerService.hide()

        if (error.status === 404) {
          this.bannerService.showBanner(
            'Client has been submitted but is still processing. Please refresh the page.',
            'warning',
            5000,
          )
          this._clientForm.markAsPristine()
          this.previousRouteService.goToPreviousUrl('/dashboard')
        } else {
          this.bannerService.showBanner(
            'An error occured submitting the request.',
            'error',
            5000,
          )
        }

        throw error
      },
    )
  }

  MapControlsToClientDto(): ClientDto {
    const controls = this._clientForm.controls
    let client: ClientDto = new ClientDto()

    if (controls.firstName.value != null) {
      client.firstname = controls.firstName.value
    }

    // Last name is required so will throw error
    client.lastname = controls.lastName.value

    if (controls.gender.value != null) {
      client.genderCode = parseInt(controls.gender.value)
    }

    // Date of Birth is required so will throw error
    client.dateOfBirth = controls.dateOfBirth.value.toDate()

    if (controls.caseManager.value != null) {
      client.clientCaseManagerId = controls.caseManager.value.value
    }

    if (controls.primaryLocation.value != null) {
      client.clientPrimaryCentreId = controls.primaryLocation.value
    }

    if (controls.preferredName.value != null) {
      client.preferredName = controls.preferredName.value
    }

    return client
  }

  UpdateClient() {
    let client: ClientUpdateDto = this.MapControlstoClientUpdateDto()

    return this.clientService.UpdateClient(client).subscribe({
      next: (response: any) => {
        this.logger.log(
          'Update Client completed successfully',
          LogLevel.Debug,
          client,
        )

        this.bannerService.showBanner(
          'Successfully updated client details.',
          'success',
          1000,
        )
      },
      error: (error: any) => {
        this.spinnerService.hide()
        this.bannerService.showBanner(
          'An error occured submitting the request.',
          'error',
          5000,
        )

        throw error
      },
      complete: () => {
        this._clientForm.markAsPristine()
        this.previousRouteService.goToPreviousUrl('/dashboard')
      },
    })
  }

  MapControlstoClientUpdateDto(): ClientUpdateDto {
    const controls = this._clientForm.controls
    let client: ClientUpdateDto = new ClientUpdateDto()

    client.clientCaseManagerId =
      controls.caseManager.value === null
        ? null
        : controls.caseManager.value.value

    client.clientPrimaryCentreId = controls.primaryLocation.value
    client.contactId = this._clientId
    client.dateOfBirth = controls.dateOfBirth.value.toDate()
    client.firstname = controls.firstName.value
    client.genderCode = controls.gender.value
    client.lastname = controls.lastName.value
    client.preferredName = controls.preferredName.value

    return client
  }

  GetLastNameErrorText(): string {
    return this.GetLastNameText() + ' is invalid.'
  }

  GetLastNameText(): string {
    return this._clientForm.controls.clientType.value == 'standard'
      ? 'Last Name or Identifier'
      : 'Identifier'
  }

  CancelClicked() {
    this.previousRouteService.goToPreviousUrl('/dashboard')
  }

  Submit() {
    this._clientForm.markAllAsTouched()

    if (this._clientForm.valid) {
      this.spinnerService.show()
      if (this._mode == 'add') {
        this.CreateClient()
      } else {
        this.UpdateClient()
      }
    }
  }

  async openDialog(): Promise<boolean> {
    const dr = this.dialog.open(AreYouSureModalComponent, {
      width: '605px',
      height: '316px',
      panelClass: 'are-you-sure-panel',
    })

    return await firstValueFrom(dr.afterClosed())
  }

  public canDeactivate(): Promise<boolean> {
    return this.isNavigationAllowed()
  }

  private isNavigationAllowed(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this._clientForm.pristine) {
        resolve(true)
      } else {
        resolve(this.openDialog())
      }
    })
  }
}
