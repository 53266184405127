import { TableColumnDefinition } from '../table/table-column-definition'

export const ClientDashboardReddiTableColumnDefinitions: TableColumnDefinition[] =
  [
    {
      columnName: 'presentationDate',
      header: 'Presentation Date',
      sortDisabled: true,
      columnType: 'truncated-string',
      columnWidth: '300px',
      columnTextAlign: 'left',
    },
    {
      columnName: 'presenterName',
      columnWidth: '170px',
      columnTextAlign: 'left',
      header: 'Data entered by',
      sortDisabled: true,
      columnType: 'truncated-string',
    },
    {
      columnName: 'viewReport',
      columnWidth: '80px',
      columnTextAlign: 'left',
      header: '',
      sortDisabled: true,
      columnType: 'link',
      linkText: 'View details',
      linkKind: 'icon',
      linkColor: 'primary',
      showIcon: true,
    },
  ]
