import { AfterViewInit, Component, ElementRef, Input } from '@angular/core'
import { ClientInformationAttribute } from 'src/app/models/client-information/ClientInformationAttribute'

@Component({
  selector: 'app-client-information',
  templateUrl: './client-information.component.html',
  styleUrls: ['./client-information.component.scss'],
})
export class ClientInformationComponent implements AfterViewInit {
  @Input() attributes: ClientInformationAttribute[] = []
  @Input() overflowValues: { title: string; subtitle: string } = null
  @Input() subtitle: string = ''
  @Input() title: string = ''
  @Input() url: string = ''
  @Input() urlDisplayText: string = ''

  constructor(public element: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.isTitleOverflowing() && this.overflowValues !== null) {
      this.title = this.overflowValues.title
      this.subtitle = this.overflowValues.subtitle
    }
  }

  isTitleOverflowing() {
    let elements =
      this.element.nativeElement.getElementsByClassName('side-card-title')

    if (elements.length === 0) {
      return false
    }

    if (elements.length > 1) {
      throw 'Unable to determine title element'
    }

    let element = elements[0] as HTMLElement
    return element.offsetWidth < element.scrollWidth
  }

  getBorder(index: number) {
    return index === 0 ? '' : 'top'
  }
}
