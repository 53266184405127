import { Component, Input, OnChanges, OnInit } from '@angular/core'

export type ReddiResponseType = 'detection' | 'imitation' | 'none'
export type Outcome = 'positive' | 'negative' | 'neutral'

@Component({
  selector: 'app-reddi-response-icon',
  templateUrl: './reddi-response-icon.component.html',
  styleUrls: ['./reddi-response-icon.component.scss'],
})
export class ReddiResponseIconComponent implements OnInit, OnChanges {
  @Input() type: ReddiResponseType
  @Input() outcome: Outcome
  @Input() size: number

  _icon: string = ''
  _classes: string[] = []

  ngOnInit(): void {
    this._icon = this.getIcon(this.type)
    this._classes = this.getStyles(this.type, this.outcome)
  }

  ngOnChanges(): void {
    this._icon = this.getIcon(this.type)
    this._classes = this.getStyles(this.type, this.outcome)
  }

  getIcon(type: ReddiResponseType): string {
    switch (type) {
      case 'detection':
        return 'wifi'
      case 'imitation':
        return 'autorenew'
      case 'none':
        return ''
    }
  }

  getStyles(type: ReddiResponseType, outcome: Outcome): string[] {
    const baseClass = 'reddi-response-icon'
    const result: string[] = [baseClass]

    if (type === 'imitation') {
      result.push('rotate-ninety-degrees')
    }

    result.push(baseClass + '-' + outcome)

    return result
  }
}
