import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'
import { FeatureStatusRecord } from '../../models/features/feature-status-record'
import { ApplicationInsightsService } from '../application-insights/application-insights.service'
import { ConfigService } from '../config/config.service'

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  constructor(
    private http: HttpClient,
    private appInsights: ApplicationInsightsService,
    private config: ConfigService,
  ) {}

  private readonly featuresBaseUrl =
    this.config.getSettings('webApi').baseUri + 'features/'
  private readonly featuresUrl = this.featuresBaseUrl + 'list'

  public GetFeatures(): Observable<FeatureStatusRecord[]> {
    const params = new HttpParams()

    const result: Observable<FeatureStatusRecord[]> = this.http
      .get<any>(this.featuresUrl, { params })
      .pipe(
        map((response: any) => {
          const features = response as FeatureStatusRecord[]
          return features
        }),
        tap((_) => this.appInsights.logEvent('Retrieved Features')),
        catchError((err) => {
          throw err
        }),
      )

    return result
  }
}
