import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'

@Component({
  selector: 'app-notes-side-card',
  templateUrl: './notes-side-card.component.html',
  styleUrls: ['./notes-side-card.component.scss'],
})
export class NotesSideCardComponent implements OnInit {
  @Input() textAreaControl: FormControl
  @Output() textChange: EventEmitter<string> = new EventEmitter<string>() // Output to emit text changes
  @Input() initialText: string = ''
  showAlert: boolean = false
  hasData: boolean = false
  private MAX_LENGTH = 500
  @Input() length: number = this.MAX_LENGTH

  ngOnInit() {
    if (this.length < 0) {
      this.length = this.MAX_LENGTH
    }
    this.textAreaControl.setValue(this.initialText)
    this.checkMaxLength()
  }

  checkMaxLength() {
    this.showAlert = this.textAreaControl.value.length >= this.length
    this.hasData = this.textAreaControl.value.length > 0
  }

  getTextValue(): string {
    return this.textAreaControl.value
  }

  clearText() {
    this.textAreaControl.setValue('')
    this.showAlert = false
    this.hasData = false
  }
}
