import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-help-tip',
  templateUrl: './help-tip.component.html',
  styleUrls: ['./help-tip.component.scss'],
})
export class HelpTipComponent {
  @Input() title: string
  @Input() value: string
}
