import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
})
export class ActionButtonComponent implements OnInit {
  @Input() kind: 'filled' | 'outline'
  @Input() color: 'primary' | 'accent'
  @Input() size:
    | 'standard'
    | 'slightly-wider'
    | 'wide'
    | 'wider'
    | 'extra-wide'
    | 'extra-large'
    | 'outlined'
  @Input() type: 'submit' | 'reset' | 'button'
  @Input() isDisabled = false
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>()

  _buttonClasses: string[] = ['action-button']
  _containerClass: string[] = ['action-button-container']

  ngOnInit(): void {
    const prefix = 'action-button-'
    this._buttonClasses.push(prefix + this.kind)
    this._buttonClasses.push(prefix + this.color)
    this._buttonClasses.push(prefix + this.size)

    if (this.size === 'extra-large') {
      this._containerClass.push('action-button-container-extra-large')
    }
  }
}
