import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { CourseDto } from 'src/app/models/subscriptions/course-dto'
import { ApplicationInsightsService } from '../application-insights/application-insights.service'
import { ConfigService } from '../config/config.service'
@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(
    private http: HttpClient,
    private appInsights: ApplicationInsightsService,
    private config: ConfigService,
  ) {}

  private readonly SubscriptionBaseUrl =
    this.config.getSettings('webApi').baseUri + 'subscription/'
  private readonly SubscriptionHasToolsUrl =
    this.SubscriptionBaseUrl + 'hastools'
  private readonly SubscriptionCoursesUrl = this.SubscriptionBaseUrl + 'courses'

  public HasTools(): Observable<boolean> {
    const params = new HttpParams()

    const result: Observable<boolean> = this.http
      .get<boolean>(this.SubscriptionHasToolsUrl, { params })
      .pipe(
        tap((_) => this.appInsights.logEvent('Retrieved HasTools')),
        catchError((err) => {
          throw err
        }),
      )

    return result
  }

  public Courses(): Observable<CourseDto[]> {
    const params = new HttpParams()

    const result: Observable<CourseDto[]> = this.http
      .get<CourseDto[]>(this.SubscriptionCoursesUrl, { params })
      .pipe(
        tap((_) => this.appInsights.logEvent('Retrieved Courses')),
        catchError((err) => {
          throw err
        }),
      )

    return result
  }
}
