import { Component, Input, OnInit } from '@angular/core'

export type SideCardSize = 'small' | 'medium'

@Component({
  selector: 'app-side-card',
  templateUrl: './side-card.component.html',
  styleUrls: ['./side-card.component.scss'],
})
export class SideCardComponent implements OnInit {
  @Input() title: string = null
  @Input() subtitle: string = null
  @Input() linkText: string = null
  @Input() linkDestination: string = null
  @Input() size: SideCardSize = 'medium'
  @Input() height: number

  _sideCardClasses: string[] = ['side-card-card']

  ngOnInit(): void {
    this._sideCardClasses.push('side-card-' + this.size)
  }

  hasTitleSection() {
    return this.hasTitle() || this.hasLink()
  }

  hasTitle() {
    return this.title !== null
  }

  hasLink() {
    return this.linkText !== null && this.linkDestination !== null
  }

  hasSubtitle() {
    return this.subtitle !== null
  }
}
