export class ClientUpdateDto {
  public clientCaseManagerId?: string
  public clientPrimaryCentreId?: string
  public contactId?: string
  public dateOfBirth?: Date
  public firstname?: string
  public genderCode?: number
  public lastname?: string
  public preferredName?: string
  constructor() {}
}
