import {
  Cue,
  Distance,
  ImpactingFactor,
  ListeningCondition,
  PresenterRole,
  Result,
  Score,
  Volume,
} from 'src/app/services/reddi/reddi-utils'

export class ReddiDto {
  public iteration: ReddiIterationDto
  public rounds: ReddiRoundDto[]

  constructor() {
    this.rounds = []
  }
}

export class ReddiIterationDto {
  public clientId: string
  public clientDisplayName: string
  public impactingFactors: ImpactingFactor[]
  public iterationId: string
  public name: string
  public notes: string
  public orderCounterCalculater: boolean
  public ownerId: string
  public presentationDate: string
  public presenter: string
  public presenterDisplayName: string
  public presenterRole: PresenterRole
  public triggerRules: boolean
}

export class ReddiRoundDto {
  public roundId: string
  public cue: Cue
  public distance: Distance
  public iterationId: string
  public iterationData: string
  public listeningCondition: ListeningCondition
  public name: string
  public orderCounter: number
  public ownerId: string
  public sorter: string
  public volume: Volume

  public sound1Imitation: string
  public sound1Result: Result
  public sound1Score: Score
  public sound2Imitation: string
  public sound2Result: Result
  public sound2Score: Score
  public sound3Imitation: string
  public sound3Result: Result
  public sound3Score: Score
  public sound4Imitation: string
  public sound4Result: Result
  public sound4Score: Score
  public sound5Imitation: string
  public sound5Result: Result
  public sound5Score: Score
  public sound6Imitation: string
  public sound6Result: Result
  public sound6Score: Score
  public sound7Imitation: string
  public sound7Result: Result
  public sound7Score: Score
}
