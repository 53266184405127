import {
  Outcome,
  ReddiResponseType,
} from 'src/app/components/core/reddi-response-icon/reddi-response-icon.component'
import { TableReddiRoundEditButtonColumnDto } from 'src/app/models/table/table-reddi-round-edit-button-column.dto'
import { TableReddiResponseIconColumnDto } from 'src/app/models/table/table-reddi-response-icon-column-dto'

export enum Cue {
  None = 866080000,
  Visual = 866080001,
  Auditory = 866080002,
}

export enum Distance {
  _0_5To0_9 = 866080000,
  _1_0To1_9 = 866080001,
  _2_0To3_9 = 866080002,
  _4_0To7_9 = 866080003,
  _8Plus = 866080004,
}

export enum ImpactingFactor {
  ConfirmedMiddleEarPathology = 866080000,
  SuspectedMiddleEarPathology = 866080001,
  ChildUnsettledOrUnwell = 866080002,
  None = 866080003,
}

export enum ListeningCondition {
  Everyday = 866080000,
  Left = 866080001,
  Right = 866080002,
}

export enum PresenterRole {
  Professional = 866080000,
  CaregiverOrFamily = 866080001,
}

export enum Result {
  NotDetected = 866080000,
  Detected = 866080001,
  ImitatedIncorrectly = 866080002,
  ImitatedCorrectly = 866080003,
}

export enum Score {
  D0 = 866080000,
  D1 = 866080001,
  D2 = 866080002,
  D3 = 866080003,
  D4 = 866080004,
  D5 = 866080005,
  D6 = 866080006,
  D7 = 866080007,
  I0 = 866080008,
  I1 = 866080009,
  I2 = 866080010,
  I3 = 866080011,
  I4 = 866080012,
  I5 = 866080013,
  I6 = 866080014,
  I7 = 866080015,
}

export enum Volume {
  EverydayConversation = 866080000,
  Loud = 866080001,
}

export function ImpactingFactorToDisplayName(
  impactingFactor: ImpactingFactor,
): string {
  switch (impactingFactor) {
    case ImpactingFactor.ChildUnsettledOrUnwell:
      return 'Unsettled or Unwell'
    case ImpactingFactor.ConfirmedMiddleEarPathology:
      return 'Confirmed Middle Ear Pathology'
    case ImpactingFactor.SuspectedMiddleEarPathology:
      return 'Suspected Middle Ear Pathology'
    case ImpactingFactor.None:
      return 'None'
  }
}

export function DistanceToDisplayName(distance: Distance): string {
  switch (distance) {
    case Distance._0_5To0_9:
      return '0.5 - 0.9'
    case Distance._1_0To1_9:
      return '1.0 - 1.9'
    case Distance._2_0To3_9:
      return '2.0 - 3.9'
    case Distance._4_0To7_9:
      return '4.0 - 7.9'
    case Distance._8Plus:
      return '8+'
  }
}

export function ResultToDisplayName(result: Result): string {
  switch (result) {
    case Result.NotDetected:
      return 'Not Detected'
    case Result.Detected:
      return 'Detected'
    case Result.ImitatedIncorrectly:
      return 'Imitated Incorrectly'
    case Result.ImitatedCorrectly:
      return 'Imitated Correctly'
  }
}

export function VolumeToDisplayName(volume: Volume): string {
  switch (volume) {
    case Volume.EverydayConversation:
      return 'Conv'
    case Volume.Loud:
      return 'Loud'
  }
}

export function VolumeToDropDownDisplayName(volume: Volume): string {
  switch (volume) {
    case Volume.EverydayConversation:
      return 'Everyday Conversation (<55 dBSPL)'
    case Volume.Loud:
      return 'Loud (>+55 dBSPL)'
  }
}

export function ListeningToDropDownDisplayName(
  listening: ListeningCondition,
): string {
  switch (listening) {
    case ListeningCondition.Everyday:
      return 'Everyday'
    case ListeningCondition.Left:
      return 'Left (R device off)'
    case ListeningCondition.Right:
      return 'Right (L device off)'
  }
}

export function CreateReddiRoundTableDto(
  result: Result,
  imitation: string,
): TableReddiResponseIconColumnDto {
  return {
    type: GetTypeFromResult(result),
    outcome: GetOutcomFromResult(result),
    tooltip: imitation,
  }
}

export function GetTypeFromResult(result: Result): ReddiResponseType {
  switch (result) {
    case Result.Detected:
    case Result.NotDetected:
      return 'detection'
    case Result.ImitatedCorrectly:
    case Result.ImitatedIncorrectly:
      return 'imitation'
    default:
      return 'none'
  }
}

export function GetOutcomFromResult(result: Result): Outcome {
  switch (result) {
    case Result.Detected:
    case Result.ImitatedCorrectly:
      return 'positive'
    case Result.NotDetected:
    case Result.ImitatedIncorrectly:
      return 'negative'
    default:
      return 'neutral'
  }
}

export function CreateReddiEditButtonDto(
  callback: (index: number) => void,
  index: number,
): TableReddiRoundEditButtonColumnDto {
  return {
    label: 'Edit', // The label of the button
    onClick: () => callback(index), // The callback function to execute when the button is clicked
  }
}
