import { TableColumnDefinitionInterface } from 'src/app/models/table/table-column-definition'

export const _reviewsTableColumnDefinition: TableColumnDefinitionInterface[] = [
  {
    columnName: 'category',
    header: 'Category',
    sortDisabled: true,
    columnType: 'category',
    columnWidth: '20%',
    columnTextAlign: 'center',
  },
  {
    columnName: 'reviewType',
    header: 'Tool type',
    sortDisabled: true,
    columnType: 'string',
    columnWidth: '20%',
    columnTextAlign: 'left',
  },
  {
    columnName: 'reviewRule',
    header: 'Review rule',
    sortDisabled: true,
    columnType: 'string',
    columnWidth: '30%',
    columnTextAlign: 'left',
  },
  {
    columnName: 'createdOn',
    header: 'Created on',
    sortDisabled: true,
    columnType: 'string',
    columnWidth: '20%',
    columnTextAlign: 'left',
  },
  {
    columnName: 'readMore',
    header: '',
    sortDisabled: true,
    columnType: 'link-button',
    columnWidth: '10%',
    columnTextAlign: 'left',
    linkButtonColor: 'primary',
    linkColor: 'primary',
    linkButtonType: 'button',
  },
]
