import { Component, Input } from '@angular/core'
import { Step } from 'src/app/models/common/reddi-steps'

@Component({
  selector: 'app-reddi-rounds-stepper',
  templateUrl: './reddi-rounds-stepper.component.html',
  styleUrls: ['./reddi-rounds-stepper.component.scss'],
})
export class ReddiRoundsStepperComponent {
  @Input() steps: Step
  @Input() currentStep: number = 0 // Index of the current step

  // You can use this function to add new rounds if necessary
  addstepperRound(roundNumber: number): void {
    ;(this.steps.setupTitle = `Round ${roundNumber} set-up`),
      (this.steps.responseTitle = `Round ${roundNumber} response`)
  }
}
