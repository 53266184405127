import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-category-tile',
  templateUrl: './category-tile.component.html',
  styleUrls: ['./category-tile.component.scss'],
})
export class CategoryTileComponent {
  @Input() color: 'primary' | 'warn'

  _containerClass: string[] = ['category-tile-content']

  ngOnInit(): void {
    const prefix = 'category-tile-content-'
    this._containerClass.push(prefix + this.color)
  }
}
