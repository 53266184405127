import { Component, Inject, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Datum } from 'src/app/models/common/datum'
import { ReddiRoundDto } from 'src/app/models/reddi/reddi-dto'
import {
  Cue,
  Distance,
  DistanceToDisplayName,
  ListeningCondition,
  ListeningToDropDownDisplayName,
  Result,
  ResultToDisplayName,
  Volume,
  VolumeToDropDownDisplayName,
} from 'src/app/services/reddi/reddi-utils'
import { SubscriptionService } from 'src/app/services/subscription/subscription.service'

@Component({
  selector: 'app-reddi-edit-round-modal',
  templateUrl: './reddi-edit-round-modal.component.html',
  styleUrls: ['./reddi-edit-round-modal.component.scss'],
})
export class ReddiEditRoundModalComponent implements OnInit {
  reddiData: ReddiRoundDto
  updatedReddiData: ReddiRoundDto
  //form Controls
  distanceControl = new FormControl('')
  listeningConditionsControl = new FormControl('')
  volumeControl = new FormControl('')
  cuesControl = new FormControl('')
  mResponseControl = new FormControl('')
  ooResponseControl = new FormControl('')
  orResponseControl = new FormControl('')
  ahResponseControl = new FormControl('')
  eeResponseControl = new FormControl('')
  shResponseControl = new FormControl('')
  sResponseControl = new FormControl('')

  distances: Datum[]
  listeningConditions: Datum[]
  volumes: Datum[]
  cues: Datum[]
  responses: Datum[]
  ignoreSound2: boolean = false
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReddiEditRoundModalComponent>,
  ) {}

  ngOnInit(): void {
    this.updatedReddiData = new ReddiRoundDto()
    //populate datums
    this.distances = this.enumToDatums(Distance, 'Distance', false)
    this.listeningConditions = this.enumToDatums(
      ListeningCondition,
      'ListeningCondition',
      false,
    )
    this.volumes = this.enumToDatums(Volume, 'Volume', false)
    this.cues = this.enumToDatums(Cue, 'Cue', false)
    this.responses = this.enumToDatums(Result, 'Result')

    if (this.data.roundData) {
      this.ignoreSound2 = this.data?.ignoreSound2
      this.reddiData = this.data.roundData
      const matchingDatum = this.distances.find(
        (datum) => datum.label === this.reddiData.distance?.toString().trim(),
      )
      if (matchingDatum) {
        this.distanceControl.setValue(matchingDatum.value)
      }

      const matchingConditionsDatum = this.listeningConditions.find(
        (datum) =>
          datum.label ===
          this.mapLCLabeltoDisplayName(
            this.data.roundData.listening?.trim().toString(),
          ),
      )
      if (matchingConditionsDatum) {
        this.listeningConditionsControl.setValue(matchingConditionsDatum.value)
      }

      const matchingVolumeDatum = this.volumes.find(
        (datum) =>
          datum.label ===
          this.mapVolumeLabeltoDisplayName(this.reddiData.volume?.toString()),
      )
      if (matchingVolumeDatum) {
        this.volumeControl.setValue(matchingVolumeDatum.value)
      }

      const matchingCueDatum = this.cues.find(
        (datum) => datum.label === this.data.roundData.cues?.trim().toString(),
      )
      if (matchingCueDatum) {
        this.cuesControl.setValue(matchingCueDatum.value)
      }

      const matchingMResponseDatum = this.responses.find(
        (datum) =>
          datum.label ===
          this.mapTypeOutcomeToResult(
            this.data.roundData.m.type,
            this.data.roundData.m.outcome,
          )?.toString(),
      )
      if (matchingMResponseDatum) {
        this.mResponseControl.setValue(matchingMResponseDatum.value)
      }

      const matchingOOResponseDatum = this.responses.find(
        (datum) =>
          datum.label ===
          this.mapTypeOutcomeToResult(
            this.data.roundData.oo.type,
            this.data.roundData.oo.outcome,
          )?.toString(),
      )
      if (this.data.roundData && this.data.roundData.or) {
        const matchingORResponseDatum = this.responses.find(
          (datum) =>
            datum.label ===
            this.mapTypeOutcomeToResult(
              this.data.roundData.or.type,
              this.data.roundData.or.outcome,
            )?.toString(),
        )
        if (matchingORResponseDatum) {
          this.orResponseControl.setValue(matchingORResponseDatum.value)
        }
      }
      if (matchingOOResponseDatum) {
        this.ooResponseControl.setValue(matchingOOResponseDatum.value)
      }

      const matchingAHResponseDatum = this.responses.find(
        (datum) =>
          datum.label ===
          this.mapTypeOutcomeToResult(
            this.data.roundData.ah.type,
            this.data.roundData.ah.outcome,
          )?.toString(),
      )
      if (matchingAHResponseDatum) {
        this.ahResponseControl.setValue(matchingAHResponseDatum.value)
      }

      const matchingEEResponseDatum = this.responses.find(
        (datum) =>
          datum.label ===
          this.mapTypeOutcomeToResult(
            this.data.roundData.ee.type,
            this.data.roundData.ee.outcome,
          )?.toString(),
      )
      if (matchingEEResponseDatum) {
        this.eeResponseControl.setValue(matchingEEResponseDatum.value)
      }

      const matchingSHResponseDatum = this.responses.find(
        (datum) =>
          datum.label ===
          this.mapTypeOutcomeToResult(
            this.data.roundData.sh.type,
            this.data.roundData.sh.outcome,
          )?.toString(),
      )
      if (matchingSHResponseDatum) {
        this.shResponseControl.setValue(matchingSHResponseDatum.value)
      }

      const matchingSResponseDatum = this.responses.find(
        (datum) =>
          datum.label ===
          this.mapTypeOutcomeToResult(
            this.data.roundData.s.type,
            this.data.roundData.s.outcome,
          )?.toString(),
      )
      if (matchingSResponseDatum) {
        this.sResponseControl.setValue(matchingSResponseDatum.value)
      }
    }
  }

  private enumToDatums(
    enumObj: any,
    enumType: string,
    includeBlank: boolean = true,
  ): Datum[] {
    const displayNameFunc = this.enumDisplayNameFunctions[enumType]
    let datums: Datum[] = Object.keys(enumObj)
      .filter((key) => isNaN(Number(key)))
      .map((key) => {
        const value = enumObj[key]
        const label = displayNameFunc ? displayNameFunc(value) : key
        return { value, label }
      })

    if (includeBlank) {
      const blankOption: Datum = { value: '', label: '' } // or use null for value if that works better with your setup
      datums.unshift(blankOption)
    }

    return datums
  }

  private enumDisplayNameFunctions: {
    [enumType: string]: (label: any) => string
  } = {
    Distance: DistanceToDisplayName,
    Volume: VolumeToDropDownDisplayName,
    Result: ResultToDisplayName,
    ListeningCondition: ListeningToDropDownDisplayName,
  }

  private mapTypeOutcomeToResult(type: string, outcome: string): string {
    if (type === 'detection' && outcome === 'negative') {
      return 'Not Detected'
    } else if (type === 'detection' && outcome === 'positive') {
      return 'Detected'
    } else if (type === 'imitation' && outcome === 'negative') {
      return 'Imitated Incorrectly'
    } else if (type === 'imitation' && outcome === 'positive') {
      return 'Imitated Correctly'
    }
    return null
  }

  private getImitationResult(type: string, outcome: string): Result {
    if (type === 'detection' && outcome === 'negative') {
      return Result.NotDetected
    } else if (type === 'detection' && outcome === 'positive') {
      return Result.Detected
    } else if (type === 'imitation' && outcome === 'negative') {
      return Result.ImitatedIncorrectly
    } else if (type === 'imitation' && outcome === 'positive') {
      return Result.ImitatedIncorrectly
    }
    return null
  }

  private mapValuetoVolumeType(value: number): Volume {
    switch (value) {
      case 866080000:
        return Volume.EverydayConversation

      case 866080001:
        return Volume.Loud

      default:
        return null
    }
  }

  private mapCueValuetoDisplay(value: number): Cue {
    switch (value) {
      case 866080002:
        return Cue.Auditory

      case 866080000:
        return Cue.None

      case 866080001:
        return Cue.Visual

      default:
        return null
    }
  }

  private mapVolumeLabeltoDisplayName(label: string): string {
    switch (label) {
      case 'Conv':
        return 'Everyday Conversation (<55 dBSPL)'

      case 'Loud':
        return 'Loud (>+55 dBSPL)'

      default:
        return null
    }
  }

  private mapLCLabeltoDisplayName(label: string): string {
    switch (label) {
      case 'Everyday':
        return 'Everyday'

      case 'Left':
        return 'Left (R device off)'
      case 'Right':
        return 'Right (L device off)'
      default:
        return null
    }
  }

  private hasImitatedIncorrectlyChanged(
    lastValue: number,
    currentValue: number,
  ): boolean {
    const imitatedIncorrectlyValue = Number(Result.ImitatedIncorrectly)
    return (
      lastValue === imitatedIncorrectlyValue &&
      currentValue !== imitatedIncorrectlyValue
    )
  }

  private getclearImitation() {
    return ''
  }

  onSubmit(): void {
    const distanceEnumValue = Number(this.distanceControl.value)
    const listeningEnumValue = Number(this.listeningConditionsControl.value)
    const cueEnumValue = Number(this.cuesControl.value)
    const volumeEnumValue = Number(this.volumeControl.value)

    //Responses Mapping
    const mResultValue = Number(this.mResponseControl.value)
    const mResultEnum = Result[mResultValue]
    const mResultLastImitation = this.getImitationResult(
      this.data.roundData.m.type,
      this.data.roundData.m.outcome,
    )
    const ooResultValue = Number(this.ooResponseControl.value)
    const ooResultEnum = Result[ooResultValue]
    const ooResultLastImitation = this.getImitationResult(
      this.data.roundData.oo.type,
      this.data.roundData.oo.outcome,
    )
    if (this.data.roundData && this.data.roundData.or) {
      const orResultValue = Number(this.orResponseControl.value)
      const orResultEnum = Result[orResultValue]
      const orResultLastImitation = this.getImitationResult(
        this.data.roundData.or.type,
        this.data.roundData.or.outcome,
      )
      if (this.orResponseControl.value) {
        this.updatedReddiData.sound2Result = Number(
          this.orResponseControl?.value,
        )
        this.updatedReddiData.sound2Imitation =
          !this.hasImitatedIncorrectlyChanged(
            Number(orResultLastImitation?.toString()),
            Number(orResultValue?.toString()),
          )
            ? this.data.roundData.or?.tooltip?.toString()
            : this.getclearImitation()
      }
    }

    const ahResultValue = Number(this.ahResponseControl.value)
    const ahResultEnum = Result[ahResultValue]
    const ahResultLastImitation = this.getImitationResult(
      this.data.roundData.ah.type,
      this.data.roundData.ah.outcome,
    )
    const eeResultValue = Number(this.eeResponseControl.value)
    const eeResultEnum = Result[eeResultValue]
    const eeResultLastImitation = this.getImitationResult(
      this.data.roundData.ee.type,
      this.data.roundData.ee.outcome,
    )
    const shResultValue = Number(this.shResponseControl.value)
    const shesultEnum = Result[shResultValue]
    const shResultLastImitation = this.getImitationResult(
      this.data.roundData.sh.type,
      this.data.roundData.sh.outcome,
    )
    const sResultValue = Number(this.sResponseControl.value)
    const sResultEnum = Result[sResultValue]
    const sResultLastImitation = this.getImitationResult(
      this.data.roundData.s.type,
      this.data.roundData.s.outcome,
    )

    this.updatedReddiData.distance = distanceEnumValue
    this.updatedReddiData.cue = cueEnumValue
    this.updatedReddiData.listeningCondition = listeningEnumValue
    this.updatedReddiData.volume = volumeEnumValue
    if (this.mResponseControl.value) {
      this.updatedReddiData.sound1Result = Number(this.mResponseControl.value)
      this.updatedReddiData.sound1Imitation =
        !this.hasImitatedIncorrectlyChanged(
          Number(mResultLastImitation?.toString()),
          Number(mResultValue?.toString()),
        )
          ? this.data.roundData.m?.tooltip?.toString()
          : this.getclearImitation()
    }
    if (this.ooResponseControl.value) {
      this.updatedReddiData.sound3Result = Number(this.ooResponseControl.value)
      this.updatedReddiData.sound3Imitation =
        !this.hasImitatedIncorrectlyChanged(
          Number(ooResultLastImitation?.toString()),
          Number(ooResultValue?.toString()),
        )
          ? this.data.roundData.oo?.tooltip?.toString()
          : this.getclearImitation()
    }
    if (this.ahResponseControl.value) {
      this.updatedReddiData.sound4Result = Number(this.ahResponseControl.value)
      this.updatedReddiData.sound4Imitation =
        !this.hasImitatedIncorrectlyChanged(
          Number(ahResultLastImitation?.toString()),
          Number(ahResultValue?.toString()),
        )
          ? this.data.roundData.ah?.tooltip?.toString()
          : this.getclearImitation()
    }
    if (this.eeResponseControl.value) {
      this.updatedReddiData.sound5Result = Number(this.eeResponseControl.value)
      this.updatedReddiData.sound5Imitation =
        !this.hasImitatedIncorrectlyChanged(
          Number(eeResultLastImitation?.toString()),
          Number(eeResultValue?.toString()),
        )
          ? this.data.roundData.ee?.tooltip?.toString()
          : this.getclearImitation()
    }
    if (this.shResponseControl.value) {
      this.updatedReddiData.sound6Result = Number(this.shResponseControl.value)
      this.updatedReddiData.sound6Imitation =
        !this.hasImitatedIncorrectlyChanged(
          Number(shResultLastImitation?.toString()),
          Number(shResultValue?.toString()),
        )
          ? this.data.roundData.ss?.tooltip?.toString()
          : this.getclearImitation()
    }
    if (this.sResponseControl.value) {
      this.updatedReddiData.sound7Result = Number(this.sResponseControl.value)
      this.updatedReddiData.sound7Imitation =
        !this.hasImitatedIncorrectlyChanged(
          Number(sResultLastImitation?.toString()),
          Number(sResultValue?.toString()),
        )
          ? this.data.roundData.s?.tooltip?.toString()
          : this.getclearImitation()
    }
    this.dialogRef.close({
      updatedData: this.updatedReddiData,
      rowIndex: this.data.rowId,
    })
  }

  onCancel(): void {
    this.dialogRef.close()
  }
}
