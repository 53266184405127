import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { filter, pairwise } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class PreviousRouteService {
  private previousUrl: string

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof NavigationEnd),
        pairwise(),
      )
      .subscribe((events: NavigationEnd[]) => {
        this.previousUrl = events[0].urlAfterRedirects
      })
  }

  public getPreviousUrl() {
    return this.previousUrl
  }

  public goToPreviousUrl(fallbackLink: string) {
    this.router.navigateByUrl(
      this.hasPreviousUrl() ? this.getPreviousUrl() : fallbackLink,
    )
  }

  public hasPreviousUrl() {
    return this.previousUrl !== undefined
  }

  public clearPreviousUrl() {
    this.previousUrl = undefined
  }
}
