export class CheckBoxData {
  id: number
  label: string
  value: number
  checked: boolean

  constructor(
    id: number,
    label: string,
    value: number,
    checked: boolean = false,
  ) {
    this.id = id
    this.label = label
    this.value = value
    this.checked = checked
  }
}
