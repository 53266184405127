import { TableColumnDefinition } from '../table/table-column-definition'

export const ReddiInstanceTableColumnDefinition: TableColumnDefinition[] = [
  {
    columnName: 'distance',
    columnWidth: '98px',
    columnTextAlign: 'left',
    header: 'Distance',
    sortDisabled: true,
    columnType: 'string',
  },
  {
    columnName: 'listening',
    columnWidth: '98px',
    columnTextAlign: 'left',
    header: 'Listening',
    sortDisabled: true,
    columnType: 'string',
  },
  {
    columnName: 'volume',
    columnWidth: '98px',
    columnTextAlign: 'left',
    header: 'Volume',
    sortDisabled: true,
    columnType: 'string',
  },
  {
    columnName: 'cues',
    columnWidth: '98px',
    columnTextAlign: 'left',
    header: 'Cues',
    sortDisabled: true,
    columnType: 'string',
  },
  {
    columnName: 'm',
    columnWidth: '98px',
    columnTextAlign: 'left',
    header: 'm',
    sortDisabled: true,
    columnType: 'reddi-response-icon',
  },
  {
    columnName: 'or',
    columnWidth: '98px',
    columnTextAlign: 'left',
    header: 'or',
    sortDisabled: true,
    columnType: 'reddi-response-icon',
  },
  {
    columnName: 'oo',
    columnWidth: '98px',
    columnTextAlign: 'left',
    header: 'oo',
    sortDisabled: true,
    columnType: 'reddi-response-icon',
  },
  {
    columnName: 'ah',
    columnWidth: '98px',
    columnTextAlign: 'left',
    header: 'ah',
    sortDisabled: true,
    columnType: 'reddi-response-icon',
  },
  {
    columnName: 'ee',
    columnWidth: '98px',
    columnTextAlign: 'left',
    header: 'ee',
    sortDisabled: true,
    columnType: 'reddi-response-icon',
  },
  {
    columnName: 'sh',
    columnWidth: '98px',
    columnTextAlign: 'left',
    header: 'sh',
    sortDisabled: true,
    columnType: 'reddi-response-icon',
  },
  {
    columnName: 's',
    columnWidth: '98px',
    columnTextAlign: 'left',
    header: 's',
    sortDisabled: true,
    columnType: 'reddi-response-icon',
  },
  {
    columnName: 'edit',
    columnWidth: '98px',
    columnTextAlign: 'left',
    header: 'Action',
    sortDisabled: true,
    columnType: 'reddi-round-edit-button',
  },
]
