import { TableLinkColumnDTO } from '../table/table-link-column-dto'

export class FlipHistoryTableDto {
  date: Date
  assessmentDate: string
  score: string
  dataEnteredBy: string
  notes: string
  view: TableLinkColumnDTO
}
