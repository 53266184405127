import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular'
import { EventMessage, EventType } from '@azure/msal-browser'
import { Subject, filter, takeUntil } from 'rxjs'
import { createClaimsTable } from './claim-utils'
import { SnackBarComponent } from './components/core/snack-bar/snack-bar.component'
import { BannerService } from './services/banner/banner.service'

import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core'
import { ConfigService } from 'src/app/services/config/config.service'
import { LogOutWarningModalComponent } from './components/complex/modals/log-out-warning-modal/log-out-warning-modal.component'
import { AuthUtilsService } from './services/auth-utils/auth-utils-service'
import { UserService } from './services/user-service/user-service.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'angularapp'
  dataSource: any = []
  dr: MatDialogRef<LogOutWarningModalComponent, any> = null

  private readonly _destroying$ = new Subject<void>()

  constructor(
    @Inject(MSAL_GUARD_CONFIG)
    private msalGuardConfig: MsalGuardConfiguration, //DO NOT REMOVE: REQUIRED FOR MSAL TO WORK
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private bannerService: BannerService,
    private snackbar: MatSnackBar,
    public userService: UserService,
    private config: ConfigService,
    private idle: Idle,
    private dialog: MatDialog,
    private authUtils: AuthUtilsService,
    private configService: ConfigService,
    private renderer: Renderer2,
  ) {
    // Convert idleTimeoutMinutes to seconds

    idle.setIdle(config.getSettings('spa').idleTimeoutMinutes * 60) // how long can they be inactive before considered idle, in seconds
    idle.setTimeout(config.getSettings('spa').idleTimeoutWarningSeconds) // how long can they be idle before considered timed out and will be logged off, in seconds
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES) // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle - show a modal warning they are about to be logged off
    idle.onIdleStart.subscribe(() => {
      //Check if we already have a modal open and close it so we do not have > 1 open at a time
      if (this.userService.IsLoggedIn()) {
        if (this.dr != null) {
          this.dr.close()
        }
        this.dr = this.dialog.open(LogOutWarningModalComponent, {
          width: '704px',
          height: '284px',
          panelClass: 'log-out-warning-panel',
          data: {
            dataKey: '',
          },
        })

        this.dr.afterClosed().subscribe((result) => {
          // This should close all of the modal windows that are open
          if (result) {
            //log the user out
            this.authUtils.logout()
          } else {
            // do nothing as the user has indicated they want to continue
          }
        })
      }
    })

    // do something when the user has timed out - log them out
    idle.onTimeout.subscribe(() => {
      if (this.userService.IsLoggedIn()) {
        this.authUtils.logout()
      }
    })
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch()
  }

  ngOnInit(): void {
    this.reset()
    this.authService.instance.enableAccountStorageEvents() // Register the storage listener that will be emitting the events
    this.msalBroadcastService.msalSubject$
      .pipe(
        // Optional filtering of events
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          // Account logged out in a different tab, redirect to homepage
          window.location.pathname = '/login'
        } else {
          this.reset()
          // Update UI to show user is signed in. result.payload contains the account that was logged in
        }
      })

    this.bannerService.banner$.subscribe((bannerMessage) => {
      this.snackbar.openFromComponent(SnackBarComponent, {
        data: { message: bannerMessage.message, type: bannerMessage.type },
        duration: bannerMessage.duration,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        announcementMessage: 'Service message - ' + bannerMessage.message,
        panelClass: ['banner-' + bannerMessage.type],
      })
    })
    this.configService
      .load()
      .then(() => {
        const settings = this.configService.getSettings()
        this.addLuckyOrangeScript(settings)
      })
      .catch((error) => {
        console.error('Failed to load settings:', error)
      })
  }

  private addLuckyOrangeScript(settings: any): void {
    const luckyOrangePath = settings.spa.luckyOrangeUrl
    const luckyOrangeID = settings.spa.luckyOrangeSiteID
    const luckySource = `${luckyOrangePath}?site-id=${luckyOrangeID}`
    const script = this.renderer.createElement('script')
    script.type = 'text/javascript'
    script.src = luckySource
    script.async = true
    script.defer = true

    this.renderer.appendChild(document.head, script)
  }

  getClaims(claims: any) {
    if (claims) {
      const claimsTable = createClaimsTable(claims)
      this.dataSource = [...claimsTable]
    }
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this.userService.loginStatus$.unsubscribe()
    this._destroying$.next(undefined)
    this._destroying$.complete()
  }
}
