import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, catchError, map, Observable, tap } from 'rxjs'
import { AssessmentDto } from 'src/app/models/flip/assessment-dto'
import { FlipHistoryDto } from 'src/app/models/flip/flip-history-dto'
import { ApplicationInsightsService } from '../application-insights/application-insights.service'
import { ConfigService } from '../config/config.service'

@Injectable({
  providedIn: 'root',
})
export class FlipService {
  private readonly flipInstanceUrl =
    this.config.getSettings('webApi').baseUri + 'flip/'
  private readonly flipInstanceItemsUrl =
    this.flipInstanceUrl + 'getassessmentbyid'
  private readonly assessmentItemsUrl = this.flipInstanceUrl + 'getassessment'
  private readonly submitUrl = this.flipInstanceUrl + 'submit'
  private readonly getLastAssessmentUrl =
    this.flipInstanceUrl + 'getlastassessment'
  private readonly historyUrl = this.flipInstanceUrl + 'history'


  private notesValueSource = new BehaviorSubject<string>('')
  notesValue$ = this.notesValueSource.asObservable()
  private readonly flipHistoryUrl = this.flipInstanceUrl + 'history'

  constructor(
    private http: HttpClient,
    private appInsights: ApplicationInsightsService,
    private config: ConfigService,
  ) {}

  SetNoteValue(value: string): void {
    this.notesValueSource.next(value)
  }

  GetLastAssessment(clientId: string): Observable<AssessmentDto> {
    let params: Record<string, any> = { clientId: clientId }
    let result = this.http
      .get<any>(this.getLastAssessmentUrl, { params: params })
      .pipe(
        tap((_) =>
          this.appInsights.logEvent(
            'Retrieved FLI-P Assessment from Dataverse.',
          ),
        ),
        catchError(async (err) => {
          throw err
        }),
      )
    return result
  }

  GetAssessmentInstanceById(
    instanceId: string,
    type: string,
  ): Observable<AssessmentDto> {
    let params: Record<string, any> = { flipInstanceId: instanceId, type: type }
    let result = this.http
      .get<any>(this.flipInstanceItemsUrl, { params: params })
      .pipe(
        tap((_) =>
          this.appInsights.logEvent(
            'Retrieved flip Instance Detail from DataVerse.',
          ),
        ),
        catchError(async (err) => {
          throw err
        }),
      )
    return result
  }

  GetAssessment(clientId: string, type: string): Observable<AssessmentDto> {
    let params: Record<string, any> = { clientId: clientId, type: type }
    return this.http.get<any>(this.assessmentItemsUrl, { params: params }).pipe(
      tap((_) =>
        this.appInsights.logEvent(
          'Retrieved assessment details from DataVerse.',
        ),
      ),
      catchError(async (err) => {
        throw err
      }),
    )
  }

  Submit(assessmentDto: AssessmentDto) {
    return this.http.post<any>(this.submitUrl, assessmentDto).pipe(
      tap((_) => this.appInsights.logEvent('Assessment saved.')),
      catchError(async (err) => {
        throw err
      }),
    )
  }

  History(clientId: string): Observable<FlipHistoryDto[]> {
    let params: Record<string, any> = { clientId: clientId }
    let result = this.http
      .get<any>(this.flipHistoryUrl, { params: params })
      .pipe(
        map((response: any) => {
          const flipHistory = response as FlipHistoryDto[]
          return flipHistory
        }),
        tap((_) =>
          this.appInsights.logEvent('Retrieved FLI-P History from Dataverse'),
        ),
        catchError(async (err) => {
          throw err
        }),
      )
    return result
  }
}
