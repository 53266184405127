import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core'

@Component({
  selector: 'app-category-pill',
  templateUrl: './category-pill.component.html',
  styleUrls: ['./category-pill.component.scss'],
})
export class CategoryPillComponent implements OnInit, OnChanges {
  @Input() buttonType: 'Mostly' | 'Rarely' | 'Blank'
  private _hovered: boolean = false

  @Input() set hovered(value: boolean) {
    this._hovered = value
    this.updateContainerClass()
  }

  _containerClass: string[] = ['category-pill-content']

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.updateButtonTypeClass()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['buttonType']) {
      this.updateButtonTypeClass()
    }
  }

  private updateButtonTypeClass(): void {
    const prefix = 'category-pill-content-'
    // Remove old button type class
    this._containerClass = this._containerClass.filter(
      (cls) => !cls.startsWith(prefix),
    )
    // Add new button type class
    this._containerClass.push(prefix + this.buttonType.toLowerCase())
    this.changeDetectorRef.detectChanges()
  }

  private updateContainerClass(): void {
    const hoveredClassIndex = this._containerClass.indexOf('hovered')
    if (this._hovered) {
      if (hoveredClassIndex === -1) {
        this._containerClass.push('hovered')
      }
    } else {
      if (hoveredClassIndex !== -1) {
        this._containerClass.splice(hoveredClassIndex, 1)
      }
    }
    this.changeDetectorRef.detectChanges()
  }
}
