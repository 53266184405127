import { Component } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { firstValueFrom } from 'rxjs'
import { AreYouSureModalComponent } from '../are-you-sure-modal/are-you-sure-modal.component'

export class SubmitEntryModalResponse {
  save: boolean
  value: string
}

@Component({
  selector: 'app-submit-entry-modal',
  templateUrl: './submit-entry-modal.component.html',
  styleUrls: ['./submit-entry-modal.component.scss'],
})
export class SubmitEntryModalComponent {
  notes: FormControl<string> = new FormControl('')

  constructor(
    public dialogRef: MatDialogRef<SubmitEntryModalComponent>,
    private dialog: MatDialog,
  ) {}

  async close(saveAndSubmit: boolean) {
    let closeAreYouSure: boolean = true

    if (!this.notes.pristine && !saveAndSubmit) {
      const dr = this.dialog.open(AreYouSureModalComponent, {
        width: '605px',
        height: '316px',
        panelClass: 'are-you-sure-panel',
      })

      closeAreYouSure = await firstValueFrom(dr.afterClosed())
    }

    if (!closeAreYouSure) {
      // Don't do anything
    } else {
      let result: SubmitEntryModalResponse = {
        save: saveAndSubmit,
        value: this.notes.value,
      }

      this.dialogRef.close(result)
    }
  }
}
