import { Component, HostListener } from '@angular/core'
import { PreviousRouteService } from 'src/app/services/previous-route/previous-route.service'

@Component({
  selector: 'app-flip-population',
  templateUrl: './flip-population.component.html',
  styleUrls: ['./flip-population.component.scss'],
})
export class FlipPopulationComponent {
  constructor(private previousRoute: PreviousRouteService) {}
  @HostListener('window:resize', ['$event'])
  getGraphHeight(): number {
    return (
      Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0,
      ) * 0.6
    )
  }

  routerLinkClicked() {
    const prevUrl = this.previousRoute.getPreviousUrl()
    if (prevUrl) {
      this.previousRoute.goToPreviousUrl(prevUrl)
    }
  }
}
