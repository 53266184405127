import { Component, Input } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { filter } from 'rxjs'

@Component({
  selector: 'app-sidebar-primary-button',
  templateUrl: './sidebar-primary-button.component.html',
  styleUrls: ['./sidebar-primary-button.component.scss'],
})
export class SidebarPrimaryButtonComponent {
  @Input() icon: string = ''
  @Input() rotate: boolean = false
  @Input() link: string = ''
  colors: string = 'primary'

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e) => {
        if (e instanceof NavigationEnd) {
          this.colors = e.url == '/' + this.link ? 'accent' : 'primary'
        }
      })
  }
}
