import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, catchError, tap } from 'rxjs'
import { CaseManagerDto } from 'src/app/models/lookup/case-manager-dto'
import { GenderDto } from 'src/app/models/lookup/gender-dto'
import { LocationDto } from 'src/app/models/lookup/location-dto'
import { ApplicationInsightsService } from '../application-insights/application-insights.service'
import { ConfigService } from '../config/config.service'

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  constructor(
    private http: HttpClient,
    private appInsights: ApplicationInsightsService,
    private config: ConfigService,
  ) {}

  private readonly LookupBaseUrl =
    this.config.getSettings('webApi')?.baseUri + 'lookup/'

  public CaseManagers(): Observable<CaseManagerDto[]> {
    const params = new HttpParams()
    const url = this.LookupBaseUrl + 'casemanagers'

    const result: Observable<CaseManagerDto[]> = this.http
      .get<CaseManagerDto[]>(url, { params })
      .pipe(
        tap((_) => this.appInsights.logEvent('Retrieved CaseManagers')),
        catchError((err) => {
          throw err
        }),
      )

    return result
  }

  public Locations(): Observable<LocationDto[]> {
    const params = new HttpParams()
    const url = this.LookupBaseUrl + 'locations'

    const result: Observable<LocationDto[]> = this.http
      .get<LocationDto[]>(url, { params })
      .pipe(
        tap((_) => this.appInsights.logEvent('Retrieved Locations')),
        catchError((err) => {
          throw err
        }),
      )

    return result
  }

  public Genders(): Observable<GenderDto[]> {
    const params = new HttpParams()
    const url = this.LookupBaseUrl + 'genders'

    const result: Observable<GenderDto[]> = this.http
      .get<GenderDto[]>(url, { params })
      .pipe(
        tap((_) => this.appInsights.logEvent('Retrieved Genders')),
        catchError((err) => {
          throw err
        }),
      )

    return result
  }
}
