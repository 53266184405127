import { Injectable } from '@angular/core'
import { MatPaginatorIntl } from '@angular/material/paginator'

@Injectable()
export class MatPaginatorIntlTsc extends MatPaginatorIntl {
  override itemsPerPageLabel = ''
  override nextPageLabel = 'Next'
  override previousPageLabel = 'Previous'

  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return '0 of ' + length
    }

    length = Math.max(length, 0)
    let startIndex = page * pageSize
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize
    startIndex = startIndex + 1
    return ' Showing ' + startIndex + ' - ' + endIndex + ' of ' + length
  }
}
