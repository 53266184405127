// src/app/pdf.service.ts
import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  
  constructor() { }

  public generatePdf(filename: string): Promise<void> {
    const contentPage1: HTMLElement = document.getElementById('content-page-1');
    const contentPage2: HTMLElement = document.getElementById('content-page-2');
    const scale = 1; // Increase the scale for a higher resolution canvas
    const a4WidthPx = 250 * 4; // A4 width in mm converted to pixels at 96 DPI * scale
    const a4HeightPx = 357 * 4; // A4 height in mm converted to pixels at 96 DPI * scale
    
    contentPage1.style.width = `${a4WidthPx / scale}px`;
    contentPage1.style.height = `${a4HeightPx / scale}px`;
    const contentPages = [contentPage1].filter(Boolean); // Ensure only existing pages are added
  
    if (contentPage2) {
      contentPage2.style.width = `${a4WidthPx / scale}px`;
      contentPage2.style.height = `${a4HeightPx / scale}px`;
      contentPages.push(contentPage2); // Add contentPage2 only if it exists
    }
  
    const canvasPromises = contentPages.map(content => {
      return html2canvas(content, {
        scale: scale, // Use the increased scale here for better resolution
        useCORS: true // This is to handle any cross-origin images
      });
    });
  
    return Promise.all(canvasPromises).then(canvases => {
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: 'a4'
      });
  
      canvases.forEach((canvas, index) => {
        if (index > 0) {
          doc.addPage();
        }
        // Convert canvas to image data at a higher quality
        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        // Adjust to fit the width of the A4 page in the PDF
        const imgWidth = doc.internal.pageSize.getWidth();
        const imgHeight = (canvas.height / scale) * (imgWidth / (canvas.width / scale));
        doc.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
      });
  
      doc.save(filename);
    });
  }
  
  
}
