import { Component, EventEmitter, Input, Output } from '@angular/core'
import { QuickViewEventDTO } from 'src/app/models/quickview/quick-view-event-dto'
@Component({
  selector: 'app-quick-view-button',
  templateUrl: './quick-view-button.component.html',
  styleUrls: ['./quick-view-button.component.scss'],
})
export class QuickViewButtonComponent {
  @Input() color: 'primary' | 'accent'
  @Input() selected: boolean = false
  @Input() data: string = ''
  @Input() fieldName: string = ''
  @Output() onClick: EventEmitter<QuickViewEventDTO> =
    new EventEmitter<QuickViewEventDTO>()

  _classes: string[] = ['quick-view-button']

  prefix = 'quick-view-button-'

  constructor() {}

  ngOnInit(): void {
    this._classes.push(this.prefix + this.color)
  }

  /**
   *
   * @description This method is called when the user clicks on the quick view button
   * It raises the onClick event and passes the data and field name to the parent component
   * so that it can be handled   *
   */
  onClickIcon() {
    const response = new QuickViewEventDTO()

    // work out which icon is showing and set to the opposite
    this.selected = !this.selected
    // Raise the onchange event here
    if (this.selected) {
      response.fieldName = this.fieldName
      response.data = this.data
    }
    this.onClick.emit(response)
  }
}
