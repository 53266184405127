export class CourseCardDto {
  title: string
  percentage: number
  completed: boolean
  todo: boolean
  imageLink: string
  url: string

  constructor(init?: Partial<CourseCardDto>) {
    Object.assign(this, init)
  }
}
