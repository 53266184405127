import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { AssessmentDto } from 'src/app/models/flip/assessment-dto'
import { AssessmentGroupDto } from 'src/app/models/flip/assessment-group-dto'
import { BannerService } from 'src/app/services/banner/banner.service'
import { ClientService } from 'src/app/services/client/client.service'
import { FlipService } from 'src/app/services/flip/flip.service'
import { PreviousRouteService } from 'src/app/services/previous-route/previous-route.service'
import IsUUID from 'validator/es/lib/isUUID'

@Component({
  selector: 'app-flip-instance',
  templateUrl: './flip-instance.component.html',
  styleUrls: ['./flip-instance.component.scss'],
})
export class FlipInstanceComponent {
  _clientName: string = 'Loading...'
  data_collapsible: any[]
  last_assessment: string
  type: string = 'FLI_P'
  age: string = ''
  dob: Date
  constructor(
    private router: ActivatedRoute,
    private clientService: ClientService,
    private bannerService: BannerService,
    private flipservice: FlipService,
    private previousRoute: PreviousRouteService,
  ) {}

  assessmentStatus: string = 'This assessment is locked'

  ngOnInit(): void {
    this.router.paramMap.subscribe((paramMap) => {
      const clientId = paramMap.get('clientId')
      console.log(clientId)
      if (!IsUUID(clientId)) {
        this.bannerService.showBanner(
          `Invalid clientId ${clientId}.`,
          'error',
          30000,
        )
        throw new Error('Invalid ClientId')
      }
      this.clientService.Details(clientId).subscribe({
        next: (clientDetails) => {
          this._clientName = clientDetails.preferredFullName
          this.age = clientDetails.age
          this.dob = clientDetails.dateOfBirth
        },
        error: (err) => {
          this.bannerService.showBanner(
            'An error occured while retrieving the client details.',
            'error',
            300000,
          )
          throw err
        },
      })

      const instanceId = paramMap.get('instanceId')

      this.flipservice
        .GetAssessmentInstanceById(instanceId, this.type)
        .subscribe({
          next: (assessmentDto: AssessmentDto) => {
            this.data_collapsible =
              this.mapFlipInstanceToCollapsible(assessmentDto)
            if (assessmentDto.lastAssessmentDate) {
              const date = new Date(assessmentDto.lastAssessmentDate)
              const formattedDate = `${date.getDate()} ${date.toLocaleString(
                'default',
                { month: 'short' },
              )}, ${date.getFullYear()}`
              this.last_assessment = 'Assessment date: ' + formattedDate
            } else {
              this.last_assessment = ''
            }
          },
          error: (err) => {
            this.bannerService.showBanner(
              'An error occured while retrieving the fliInstanceItem details.',
              'error',
              300000,
            )
            console.log(err)
            throw err
          },
        })
    })
  }

  routerLinkClicked() {
    const prevUrl = this.previousRoute.getPreviousUrl()
    if (prevUrl) {
      this.previousRoute.goToPreviousUrl(prevUrl)
    }
  }

  // Map the return data to item
  private mapFlipInstanceToCollapsible(assessmentDto: AssessmentDto): any[] {
    let groupIndex = 0
    const groupedByGroupName = assessmentDto.groups?.reduce(
      (acc: { [key: string]: any }, group: AssessmentGroupDto) => {
        groupIndex++
        const groupName = group.name || ''
        if (!acc[groupName]) {
          acc[groupName] = {
            header: groupName,
            last_assessment: assessmentDto.assessmentDate,
            subItems: [],
          }
        }
        // Sort items within each group by orderNumber (assuming orderNumber is a number)
        group.items.sort((a, b) => {
          return a.orderNumber - b.orderNumber
        })
        let subOrder = 0
        group.items.forEach((item) => {
          subOrder++
          acc[groupName].subItems.push({
            number: `Item ${groupIndex}.${subOrder}`,
            content: item.name,
            score: this.getScore(item.score),
          })
        })

        // Sort subItems within each group by orderNumber
        acc[groupName].subItems.sort(
          (a: { number: string }, b: { number: any }) => {
            return a.number.localeCompare(b.number, undefined, {
              numeric: true,
              sensitivity: 'base',
            })
          },
        )

        return acc
      },
      {},
    )

    return groupedByGroupName ? Object.values(groupedByGroupName) : []
  }
  // Function to determine the score text based on the score value
  private getScore(score: boolean | null): string {
    if (score == null) {
      return ''
    }
    return score ? 'Mostly' : 'Rarely'
  }
}
