import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { QuickViewEventDTO } from 'src/app/models/quickview/quick-view-event-dto'
import { TableColumnDefinition } from 'src/app/models/table/table-column-definition'

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
/**
 * @description This component is used to display a table with the data provided by the parent component
 * It can be used to display a table with a standard header, a header with a background color or a header with a styled header
 * The data is provided by the parent component and the table is displayed using the MatTableDataSource component
 * The table can be paginated and sorted
 * The table can also have a quick view button which can be used to display a quick view component
 * The table can also have a link button which can be used to navigate to a different page
 * The table can also have a link which can be used to navigate to a different page
 */
export class TableComponent implements AfterViewInit {
  @Input() kind: 'standard' | 'with-background-color' | 'styled-header'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() dataSource: MatTableDataSource<any> = new MatTableDataSource<any>()
  @Input() columnsToDisplay: string[] = []
  @Input() columnDefinitions: Array<TableColumnDefinition>
  @Input() pageSize: number = 12
  @Input() showPager: boolean = true
  @Input() noDataText: string = 'No data available'
  @Output() onLinkButtonClick: EventEmitter<Array<string>> = new EventEmitter<
    Array<string>
  >()
  @Output() onLinkClick: EventEmitter<Array<string>> = new EventEmitter<
    Array<string>
  >()
  @Output() onQuickViewButtonClick: EventEmitter<QuickViewEventDTO> =
    new EventEmitter<QuickViewEventDTO>()
  @Output() onEditClick = new EventEmitter<{ element: any; index: number }>()

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort

  _tableClasses: string[] = ['table']

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    const prefix = 'table-'
    this._tableClasses.push(prefix + this.kind)
  }

  ngOnChanges() {
    if (this.dataSource != null) {
      this.dataSource.paginator = this.paginator
      if (this.dataSource.paginator != null) {
        this.dataSource.paginator.pageSize = this.pageSize
      }
      this.dataSource.sort = this.sort
    }
  }

  ngAfterViewInit() {
    if (this.dataSource != null) {
      this.dataSource.paginator = this.paginator
      if (this.dataSource.paginator != null) {
        this.dataSource.paginator.pageSize = this.pageSize
      }
      this.dataSource.sort = this.sort
    }
  }

  /**
   *
   * @param event The event that is fired when aa QuickView component is clicked
   * @description This method is called when the user clicks on a quick view button
   * It raises the onQuickViewButtonClick event and passes the data and field name to the parent component
   * so that it can be handled   *
   */
  onQuickViewClicked(event: Array<string>) {
    for (let i = 0; i < this.dataSource.data.length; i++) {
      if (
        this.dataSource.data[i]['quickView'] != null &&
        this.dataSource.data[i]['quickView'].linkData == event[1]
      ) {
        this.dataSource.data[i]['quickView'].selected =
          !this.dataSource.data[i]['quickView'].selected
      } else {
        this.dataSource.data[i]['quickView'].selected = false
      }
    }
    const response = new QuickViewEventDTO()
    response.fieldName = event[0]
    response.data = event[1]
    this.onQuickViewButtonClick.emit(response)
  }
  private refreshTable() {
    this.dataSource = new MatTableDataSource([...this.dataSource.data]) // Refresh the table data source
    if (this.paginator) {
      this.dataSource.paginator = this.paginator
    }
    if (this.sort) {
      this.dataSource.sort = this.sort
    }
  }
}
