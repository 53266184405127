import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-primary-card',
  templateUrl: './primary-card.component.html',
  styleUrls: ['./primary-card.component.scss'],
})
export class PrimaryCardComponent {
  @Input() title: string = ''
  @Input() subtitle: string = ''
  @Input() size: 'tiny' | 'small' | 'medium' | 'large' | 'full' = 'medium'
  @Input() headerSize: 'small' | 'medium' = 'medium'
  @Input() paddingSize: 'none' | 'medium' | 'large' = 'none'
  @Input() height: number
  class: string[] = ['primary-card']

  ngOnInit() {
    this.class.push(this.BuildCardSize(this.size))

    if (this.paddingSize !== 'none') {
      this.class.push(`primary-card-padding-${this.paddingSize}`)
    }
  }

  BuildCardSize(size: string): string {
    const cardSizePrefix = 'primary-card'
    return cardSizePrefix + '-' + size
  }
}
