import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { Idle } from '@ng-idle/core'
import { ConfigService } from 'src/app/services/config/config.service'

@Component({
  selector: 'app-log-out-warning-modal',
  templateUrl: './log-out-warning-modal.component.html',
  styleUrls: ['./log-out-warning-modal.component.scss'],
})
export class LogOutWarningModalComponent {
  countdown?: number = null
  timeoutMinutes?: number = null

  constructor(
    public dialogRef: MatDialogRef<LogOutWarningModalComponent>,
    private idle: Idle,
    private config: ConfigService,
  ) {
    this.timeoutMinutes = config.getSettings('spa').idleTimeoutMinutes

    this.idle.onTimeoutWarning.subscribe((seconds: number) => {
      this.countdown = seconds
    })
  }

  close(value: 'cancel' | 'continue') {
    this.dialogRef.close(value == 'cancel')
  }
}
