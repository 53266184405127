import { Injectable } from '@angular/core'
import { Subject, Observable } from 'rxjs'

interface BannerMessage {
  message: string
  type: 'error' | 'success' | 'warning'
  duration?: number
}

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  private bannerSubject: Subject<BannerMessage> = new Subject<BannerMessage>()
  banner$: Observable<BannerMessage> = this.bannerSubject.asObservable()

  constructor() {}

  showBanner(
    message: string,
    type: 'error' | 'success' | 'warning',
    duration?: number,
  ) {
    this.bannerSubject.next({ message, type, duration })
  }
}
