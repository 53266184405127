import { TableColumnDefinition } from '../table/table-column-definition'

export const ClientSearchTableColumnDefinitions: TableColumnDefinition[] = [
  {
    columnName: 'preferredFullName',
    header: 'Full Name',
    sortDisabled: true,
    columnType: 'link',
    columnWidth: '205px',
    columnTextAlign: 'left',
    linkColor: 'primary',
    linkKind: 'standard',
    showIcon: false,
  },
  {
    columnName: 'dateOfBirth',
    columnWidth: '177',
    columnTextAlign: 'left',
    header: 'DOB',
    sortDisabled: true,
    columnType: 'string',
  },
  {
    columnName: 'clientPrimaryCentreName',
    columnWidth: '118',
    columnTextAlign: 'left',
    header: 'Location',
    sortDisabled: true,
    columnType: 'string',
  },
  {
    columnName: 'age',
    columnWidth: '88px',
    columnTextAlign: 'left',
    header: 'Age',
    sortDisabled: true,
    columnType: 'string',
  },
]
