import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent implements OnInit {
  @Input() kind: 'standard' | 'icon'
  @Input() destination: string
  @Input() showIcon: boolean
  @Input() color: 'primary' | 'accent' | 'warn' | 'grey'
  @Input() openInNewTab: boolean
  @Input() width: string
  _classes: string[] = []
  _displayIcon: boolean = false

  ngOnInit(): void {
    const prefix = 'link-'
    this._classes.push(prefix + this.color)
    this._classes.push(prefix + this.kind)
  }
}
