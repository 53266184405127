import { Component, OnInit } from '@angular/core'

interface item {
  id: number
  index: number
  prevAnswer: boolean | null
  currAnswer: boolean | null
}

@Component({
  selector: 'app-flip-assessment-demo',
  templateUrl: './flip-assessment-demo.component.html',
  styleUrls: ['./flip-assessment-demo.component.scss'],
})
export class FlipAssessmentDemoComponent implements OnInit {
  _items: item[] = [
    { index: 0, id: 1, prevAnswer: false, currAnswer: null },
    { index: 1, id: 2, prevAnswer: true, currAnswer: null },
  ]

  _showInstructions: boolean = false

  _basalMet: boolean = false
  _basalCount: number = 0
  _ceilingMet: boolean = false
  _ceilingCount: number = 0
  _assessmentComplete: boolean = false

  _basalMax: number = 4
  _ceilingMax: number = 6

  _currItem: item
  _currItemIndex: number

  _import: string
  _export: string
  _actionsTaken: string[] = []

  _editMode: boolean = false

  ngOnInit(): void {
    this._currItem = this.getInitialItem()
  }

  appendAction(response: boolean) {
    this._actionsTaken.push(response ? 'Mostly' : 'Rarely')
  }

  click(response: boolean) {
    this.appendAction(response)
    this._currItem.currAnswer = response

    this.processAssessmentLogic(response)

    if (this._ceilingMet) {
      this._assessmentComplete = true
    } else if (!this._basalMet) {
      this._currItem = this.searchForBasalItem(this._currItem.index - 1)
    } else {
      this._currItem = this.getNextItem()
    }
  }

  isAssessmentComplete(): boolean {
    let rarelys = 0

    for (let i = this._items.length - 1; i >= 0; i--) {
      let item = this._items[i]

      if (item.currAnswer || (item.prevAnswer && item.currAnswer === null)) {
        rarelys = 0
      } else if (item.currAnswer === false) {
        rarelys += 1
      }

      if (rarelys >= 6) {
        return true
      }
    }

    return false
  }

  processAssessmentLogic(response: boolean) {
    // If we are still establishing basal
    if (!this._basalMet) {
      if (response) {
        this._basalCount++
        this._basalMet = this._basalCount >= this._basalMax
      } else {
        this._basalCount = 0
      }

      // In the main assessment
    } else {
      this._ceilingMet = this.isAssessmentComplete()
    }
  }

  getInitialItem(): item {
    return this.searchForBasalItem(this._items.length - 1)
  }

  getNextBasalItem(): item {
    return this.searchForBasalItem(this._currItem.index - 1)
  }

  searchForBasalItem(startPos: number): item {
    for (let i = startPos; i >= 0; i--) {
      let item = this._items[i]
      if (item.prevAnswer) {
        return item
      }
    }

    this._basalMet = true
    return this.getNextItem()
  }

  getNextItem(): item {
    let item: item

    for (let i = 0; i < this._items.length; i++) {
      item = this._items[i]
      if (item.currAnswer == null && !item.prevAnswer) {
        return item
      }
    }

    this._assessmentComplete = true
    return item
  }

  getText(value: boolean | null) {
    if (value === null) {
      return '\u00A0'
    }

    return value ? 'Mostly' : 'Rarely'
  }

  import() {
    this._items = JSON.parse(this._import)
    this.clear()
  }

  export() {
    this._export = JSON.stringify(this._items)
  }

  edit() {
    this._editMode = !this._editMode
    this.clear()
  }

  addRow() {
    this._items.push({
      index: this._items.length,
      id: this._items.length + 1,
      prevAnswer: false,
      currAnswer: null,
    })
  }

  toggleInstructions() {
    this._showInstructions = !this._showInstructions
  }

  toggle(item: item, value: 'prev' | 'curr') {
    if (!this._editMode) {
      return
    }

    if (value == 'prev') {
      item.prevAnswer = this.nextValue(item.prevAnswer)
    } else if (value) {
      item.currAnswer = this.nextValue(item.currAnswer)
    }
  }

  nextValue(value: boolean | null) {
    if (value == null) {
      return false
    } else if (!value) {
      return true
    } else {
      return null
    }
  }

  removeRow() {
    this._items.pop()
  }

  clear() {
    this._basalCount = 0
    this._ceilingCount = 0
    this._basalMet = false
    this._ceilingMet = false
    this._assessmentComplete = false

    for (let i = 0; i < this._items.length; i++) {
      this._items[i].currAnswer = null
    }

    this._actionsTaken = []

    this._currItem = this.getInitialItem()
  }
}
