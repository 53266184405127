export function LabelToId(label: string) {
  const whitespace = /\s/g
  const invalidCharacters = /[^a-zA-Z0-9-]/g

  return label
    .trim()
    .toLowerCase()
    .replaceAll(whitespace, '-')
    .replaceAll(invalidCharacters, '')
}
