import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core'
export type ButtonType = 'detected' | 'correct' | 'notdetected' | 'incorrect'
export type Kind = 'primary' | 'accent'

@Component({
  selector: 'app-reddi-response-button',
  templateUrl: './reddi-response-button.component.html',
  styleUrls: ['./reddi-response-button.component.scss'],
})
export class ReddiResponseButtonComponent implements OnInit, OnChanges {
  @Input() type: ButtonType
  @Input() kind: Kind
  @Input() value: boolean = false
  @Input() showTooltip: boolean = false
  @Input() top: number = 14
  @Input() left: number = 16
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>()

  _displayText: string = ''
  _displayTextMap: Map<string, string> = new Map([
    ['detected', 'Detected'],
    ['notdetected', 'Not Detected'],
    ['correct', 'Correct'],
    ['incorrect', 'Incorrect'],
  ])

  _buttonClasses: string[] = ['reddi-response-button']
  _prefix = 'reddi-response-button-'

  ngOnInit(): void {
    this._displayText = this.getDisplayText(this.type)
    this._buttonClasses = this.buildClasses()
  }

  ngOnChanges(): void {
    this._buttonClasses = this.buildClasses()
  }

  getDisplayText(buttonType: ButtonType): string {
    return this._displayTextMap.get(buttonType)
  }

  buildClasses(): string[] {
    const classes: string[] = ['reddi-response-button']
    const checked: string = this.value ? 'checked' : 'unchecked'
    classes.push(this._prefix + this.kind + '-' + checked)
    return classes
  }
}
