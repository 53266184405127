import { Component } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute } from '@angular/router'
import * as moment from 'moment'
import { forkJoin } from 'rxjs'
import { ReviewModalComponent } from 'src/app/components/complex/modals/review-modal/review-modal.component'
import { ClientDto } from 'src/app/models/client/client-dto'
import { ReviewDto } from 'src/app/models/reddi/review-dto'
import { ReviewTableDTO } from 'src/app/models/table-defs/review-table-dto'
import { _reviewsTableColumnDefinition } from 'src/app/models/table-defs/reviews-table-def'
import { TableCategoryLinkColumnDTO } from 'src/app/models/table/table-category-link-column-dto'
import { TableColumnDefinition } from 'src/app/models/table/table-column-definition'
import { TableLinkButtonColumnDTO } from 'src/app/models/table/table-link-button-column-dto'
import { TableLinkColumnDTO } from 'src/app/models/table/table-link-column-dto'
import { BannerService } from 'src/app/services/banner/banner.service'
import { ClientService } from 'src/app/services/client/client.service'
import { PreviousRouteService } from 'src/app/services/previous-route/previous-route.service'
import { ReviewService } from 'src/app/services/review/review.service'
import { SpinnerService } from 'src/app/services/spinner/spinner.service'
import IsUUID from 'validator/es/lib/isUUID'

@Component({
  selector: 'app-client-reviews',
  templateUrl: './client-reviews.component.html',
  styleUrls: ['./client-reviews.component.scss'],
})
export class ClientReviewsComponent {
  clientName: string = ''
  _age: string = ''
  _dob: Date
  clientId: string = ''
  allReviews: ReviewDto[] = []
  allReviewsTableDTO: ReviewTableDTO[] = new Array<ReviewTableDTO>()
  openReviewsDS: MatTableDataSource<ReviewTableDTO> =
    new MatTableDataSource<ReviewTableDTO>()
  archivedReviewsDS: MatTableDataSource<ReviewTableDTO> =
    new MatTableDataSource<ReviewTableDTO>()
  _openReviewsColumnsToDisplay: string[] = []
  _openReviewsColumnDefinitions: TableColumnDefinition[] =
    _reviewsTableColumnDefinition
  _archivedReviewsColumnsToDisplay: string[] = []
  _archivedReviewsColumnDefinitions: TableColumnDefinition[] =
    _reviewsTableColumnDefinition
  confirm: boolean

  constructor(
    private route: ActivatedRoute,
    private reviewsService: ReviewService,
    private clientService: ClientService,
    protected spinnerService: SpinnerService,
    private bannerService: BannerService,
    private dialog: MatDialog,
    private previousRoute: PreviousRouteService,
  ) {}

  ngOnInit(): void {
    this.loadData()
  }

  routerLinkClicked() {
    const prevUrl = this.previousRoute.getPreviousUrl()
    if (prevUrl) {
      this.previousRoute.goToPreviousUrl(prevUrl)
    }
  }

  onTableLinkButtonClick(event: Array<string>) {
    switch (event[0]) {
      case 'readMore':
        // get the record that was selected
        for (let i = 0; i < this.allReviewsTableDTO.length; i++) {
          if (this.allReviewsTableDTO[i].reviewId == event[1]) {
            this.openDialog(this.allReviewsTableDTO[i])
            break
          }
        }
        break
      default:
        break
    }
  }

  onTableLinkClick(event: Array<string>) {}

  onQuickViewButtonClick(event: any) {}

  async openDialog(selected: any) {
    const dr = this.dialog.open(ReviewModalComponent, {
      width: '704px',
      height: '484px',
      panelClass: 'review-panel',
      data: {
        dataKey: selected,
      },
    })
    dr.afterClosed().subscribe((result) => {
      if (result) {
        this.loadData()
      }
    })
  }

  private loadData() {
    this.spinnerService.show()

    this.route.paramMap.subscribe((paramMap) => {
      const clientId = paramMap.get('clientId')
      if (!IsUUID(clientId)) {
        this.bannerService.showBanner(
          `Invalid clientId ${clientId}.`,
          'error',
          30000,
        )
        this.spinnerService.hide()
        throw new Error('Invalid ClientId')
      }
      forkJoin({
        openReviews: this.reviewsService.ListUnreviewed(clientId),
        archivedReviews: this.reviewsService.ListReviewed(clientId),
        clientDetails: this.clientService.Details(clientId),
      }).subscribe({
        next: ({ openReviews, archivedReviews, clientDetails }) => {
          openReviews.sort((p1: ReviewDto, p2: ReviewDto) => {
            return this.sortComparerCreatedOn(p1, p2)
          })

          archivedReviews.sort((p1: ReviewDto, p2: ReviewDto) => {
            return this.sortComparerCreatedOn(p1, p2)
          })

          this.clientName = clientDetails.preferredFullName
          this._age = clientDetails.age
          this._dob = clientDetails.dateOfBirth
          this.allReviews = openReviews.concat(archivedReviews)

          //Put all of the values into a table that can be used to send to the modal
          this.populateModalDTO(clientDetails)

          // Create the allReviews ReviewTableDTO
          this._openReviewsColumnsToDisplay = [
            'category',
            'reviewType',
            'reviewRule',
            'createdOn',
            'readMore',
          ]
          this.openReviewsDS = this.setDataSource(
            openReviews,
            clientDetails.preferredFullName,
          )

          this._archivedReviewsColumnsToDisplay = [
            'category',
            'reviewType',
            'reviewRule',
            'createdOn',
            'readMore',
          ]
          this.archivedReviewsDS = this.setDataSource(
            archivedReviews,
            clientDetails.preferredFullName,
          )

          this.spinnerService.hide()
        },
        error: (error) => {
          this.spinnerService.hide()
          this.bannerService.showBanner(
            'An error occured while retrieving the review data.',
            'error',
            300000,
          )
          throw error
        },
      })
    })
  }

  private populateModalDTO(clientDetails: ClientDto) {
    this.allReviewsTableDTO = new Array<ReviewTableDTO>()
    for (let i = 0; i < this.allReviews.length; i++) {
      let review = new ReviewTableDTO()
      review.reviewId = this.allReviews[i].reviewId
      review.reviewed = this.allReviews[i].reviewed
      review.category = new TableCategoryLinkColumnDTO()
      let temp = this.allReviews[i].severityName.replace('For ', '')
      if (temp.toUpperCase().includes('ACTION')) {
        review.category.linkColor = 'warn'
      } else {
        review.category.linkColor = 'primary'
      }
      review.category.linkText = temp
      review.fullName = new TableLinkColumnDTO()
      review.fullName.linkText = clientDetails.preferredFullName
      review.fullName.linkColor = 'primary'
      review.fullName.linkData = this.allReviews[i].clientId
      review.fullName.linkUrl = `/dashboard/${this.allReviews[i].clientId}`
      review.reviewType = this.allReviews[i].typeName
      review.reviewRule = this.allReviews[i].ruleName
      review.createdOn = moment(this.allReviews[i].createdOn).format(
        'Do MMMM, YYYY',
      )
      review.readMore = new TableLinkButtonColumnDTO()
      review.readMore.linkButtonColor = 'primary'
      review.readMore.linkButtonType = 'button'
      review.readMore.linkData = this.allReviews[i].reviewId
      review.readMore.linkText = 'Read more'
      review.comments = this.allReviews[i].comments.replaceAll(
        '\\n\\n',
        '&#10;',
      )
      this.allReviewsTableDTO.push(review)
    }
  }

  private sortComparerCreatedOn(p1: ReviewDto, p2: ReviewDto) {
    let p1int = new Date(p1.createdOn)
    let p2int = new Date(p2.createdOn)
    if (p1int.getTime() > p2int.getTime()) {
      return -1
    }

    if (p1int.getTime() < p2int.getTime()) {
      return 1
    }

    return 0
  }

  private setDataSource(
    reviews: ReviewDto[],
    fullName: string,
  ): MatTableDataSource<ReviewTableDTO> {
    const reviewTableList: ReviewTableDTO[] = new Array<ReviewTableDTO>()
    let dataSource: MatTableDataSource<ReviewTableDTO> =
      new MatTableDataSource<ReviewTableDTO>()

    reviews.forEach((rv) => {
      const review = new ReviewTableDTO()
      review.reviewId = rv.reviewId
      review.reviewed = rv.reviewed
      review.category = new TableCategoryLinkColumnDTO()
      let temp = rv.severityName.replace('For ', '')
      if (temp.toUpperCase().includes('ACTION')) {
        review.category.linkColor = 'warn'
      } else {
        review.category.linkColor = 'primary'
      }
      review.category.linkText = temp
      review.fullName = new TableLinkColumnDTO()
      review.fullName.linkText = fullName
      review.fullName.linkColor = 'primary'
      review.fullName.linkData = rv.clientId
      review.fullName.linkUrl = `/client-dashboard/${rv.clientId}`
      review.reviewType = rv.typeName
      review.reviewRule = rv.ruleName
      review.createdOn = moment(rv.createdOn).format('Do MMMM, YYYY')
      review.readMore = new TableLinkButtonColumnDTO()
      review.readMore.linkButtonColor = 'primary'
      review.readMore.linkButtonType = 'button'
      review.readMore.linkData = rv.reviewId
      review.readMore.linkText = 'Read more'
      review.comments = rv.comments.replaceAll('\\n\\n', '&#10;')
      reviewTableList.push(review)
    })
    dataSource = new MatTableDataSource<ReviewTableDTO>(reviewTableList)
    return dataSource
  }
}
