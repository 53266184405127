import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, catchError, map, tap } from 'rxjs'
import { ReviewDto } from 'src/app/models/reddi/review-dto'
import { ApplicationInsightsService } from '../application-insights/application-insights.service'
import { ConfigService } from '../config/config.service'
@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  constructor(
    private http: HttpClient,
    private appInsights: ApplicationInsightsService,
    private config: ConfigService,
  ) {}

  private readonly reviewBaseUrl =
    this.config.getSettings('webApi').baseUri + 'review/'
  private readonly reviewDetailUrl = this.reviewBaseUrl + 'detail'
  private readonly reviewMarkReviewedUrl = this.reviewBaseUrl + 'markreviewed'
  private readonly reviewListUrl = this.reviewBaseUrl + 'list'
  private readonly reviewListReviewedUrl = this.reviewBaseUrl + 'list/reviewed'
  private readonly reviewListUnreviewedUrl =
    this.reviewBaseUrl + 'list/unreviewed'

  Detail(reviewId: string): Observable<ReviewDto> {
    let params: Record<string, any> = { reviewId: reviewId }

    let result = this.http
      .get<any>(this.reviewDetailUrl, { params: params })
      .pipe(
        tap((_) =>
          this.appInsights.logEvent('Retrieved Review Detail from DataVerse.'),
        ),
        catchError(async (err) => {
          throw err
        }),
      )

    return result
  }

  MarkReviewed(reviewId: string): Observable<any> {
    let params: Record<string, any> = { reviewId: reviewId }

    let result = this.http
      .get<any>(this.reviewMarkReviewedUrl, { params: params })
      .pipe(
        tap((_) =>
          this.appInsights.logEvent('Retrieved MarkReviews from DataVerse.'),
        ),
        catchError(async (err) => {
          throw err
        }),
      )

    return result
  }

  ListAll(clientId: string): Observable<ReviewDto[]> {
    let params: Record<string, any> = { clientId: clientId }

    let result = this.http
      .get<any>(this.reviewListUrl, { params: params })
      .pipe(
        map((response: any) => {
          const reviewList = response as ReviewDto[]
          return reviewList
        }),
        tap((_) =>
          this.appInsights.logEvent('Retrieved LiastAll from DataVerse.'),
        ),
        catchError(async (err) => {
          throw err
        }),
      )

    return result
  }

  ListReviewed(clientId: string): Observable<ReviewDto[]> {
    let params: Record<string, any> = { clientId: clientId }

    let result = this.http
      .get<any>(this.reviewListReviewedUrl, { params: params })
      .pipe(
        tap((_) =>
          this.appInsights.logEvent('Retrieved ListReviewed from DataVerse.'),
        ),
        catchError(async (err) => {
          throw err
        }),
      )

    return result
  }

  ListUnreviewed(clientId: string): Observable<ReviewDto[]> {
    let params: Record<string, any> = { clientId: clientId }

    let result = this.http
      .get<any>(this.reviewListUnreviewedUrl, { params: params })
      .pipe(
        tap((_) =>
          this.appInsights.logEvent('Retrieved ListUnreviewed from DataVerse.'),
        ),
        catchError(async (err) => {
          throw err
        }),
      )

    return result
  }
}
