import { Component, OnInit } from '@angular/core'
import { MsalService } from '@azure/msal-angular'
import { createClaimsTable } from '../../../claim-utils'
import { ApplicationInsightsService } from 'src/app/services/application-insights/application-insights.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  loginDisplay = false
  displayedColumns: string[] = ['claim', 'value', 'description']
  dataSource: any = []

  constructor(
    private authService: MsalService,
    private appInsights: ApplicationInsightsService,
  ) {}

  ngOnInit(): void {
    this.appInsights.logPageView('home', '/home')
    this.setLoginDisplay()
    this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims)
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0
  }

  getClaims(claims: any) {
    if (claims) {
      const claimsTable = createClaimsTable(claims)
      this.dataSource = [...claimsTable]
    }
  }
}
