import { Component } from '@angular/core'
import { MsalService } from '@azure/msal-angular'
import { AuthUtilsService } from 'src/app/services/auth-utils/auth-utils-service'
import { ConfigService } from 'src/app/services/config/config.service'
import { UserService } from 'src/app/services/user-service/user-service.service'

@Component({
  selector: 'app-clinician-portfolio',
  templateUrl: './clinician-portfolio.component.html',
  styleUrls: ['./clinician-portfolio.component.scss'],
})
export class ClinicianPortfolioComponent {
  name: string
  claims: Record<string, unknown>
  constructor(
    private authService: MsalService,
    private userService: UserService,
    private config: ConfigService,
    private authUtils: AuthUtilsService,
  ) {}

  ngOnInit(): void {
    this.name = this.userService.GetUserName()
  }

  logout() {
    if (this.userService.IsLoggedIn()) {
      this.authUtils.logout()
    }
  }
}
