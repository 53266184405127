/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
// Base Angular libs
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms' // <-- NgModel lives here
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { CommonModule } from '@angular/common';
//Ng Mask Modules
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import { IConfig, NgxMaskModule } from 'ngx-mask'
//MS Identity modules and components
import { MsalRedirectComponent } from '@azure/msal-angular'
import { MsalConfigDynamicModule } from './msal-config-dynamic.module'

//Ng Material modules
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatTooltipModule } from '@angular/material/tooltip'

//Custom components
import { ButtonPaginationDirective } from 'src/app/common/button-pagination-directive'
import { SidebarComponent } from 'src/app/components/complex/sidebar/sidebar.component'
import { ActionButtonComponent } from 'src/app/components/core/action-button/action-button.component'
import { ClinicianPortfolioComponent } from 'src/app/components/core/clinician-portfolio/clinician-portfolio.component'
import { HearhubLogoComponent } from 'src/app/components/core/hearhub-logo/hearhub-logo.component'
import { SidebarPrimaryButtonComponent } from 'src/app/components/core/sidebar-primary-button/sidebar-primary-button.component'
import { SidebarSecondaryButtonComponent } from 'src/app/components/core/sidebar-secondary-button/sidebar-secondary-button.component'
import { GlobalHttpInterceptorService } from './common/global-http-interceptor-service'
import { MatPaginatorIntlTsc } from './common/tsc-paginator-intl'
import { FlipViewMainCardComponent } from './components/complex/flip-view-main-card/flip-view-main-card.component'
import { HeaderBarComponent } from './components/complex/header-bar/header-bar.component'
import { BackButtonComponent } from './components/core/back-button/back-button.component'
import { CategoryPillComponent } from './components/core/category-pill/category-pill.component'
import { DatepickerComponent } from './components/core/datepicker/datepicker.component'
import { InformationCardComponent } from './components/core/information-card/information-card.component'
import { PrimaryCardComponent } from './components/core/primary-card/primary-card.component'
import { QuickViewButtonComponent } from './components/core/quick-view-button/quick-view-button.component'
import { SnackBarComponent } from './components/core/snack-bar/snack-bar.component'
import { SpinnerComponent } from './components/core/spinner/spinner.component'
import { TableComponent } from './components/core/table/table.component'
import { TextInputComponent } from './components/core/text-input/text-input.component'
import { FlipOverviewGraphComponent } from './components/core/flip-overview-graph/flip-overview-graph.component'
import { FlipReportFormComponent } from './components/screen/flip-report-form/flip-report-form.component'

// Services
import { CollapsibleComponent } from './components/core/collapsible/collapsible.component'
import { ApplicationInsightsService } from './services/application-insights/application-insights.service'
import { ConfigService } from './services/config/config.service'
import { ErrorHandlerService } from './services/error-handler/error-handler.service'
import { LogPublishersService } from './services/logger/log-publishers-service'
import { LogService } from './services/logger/log.service'

// Screens
import { AreYouSureModalComponent } from './components/complex/modals/are-you-sure-modal/are-you-sure-modal.component'
import { ReviewModalComponent } from './components/complex/modals/review-modal/review-modal.component'
import { CategoryTileComponent } from './components/core/category-tile/category-tile.component'
import { DropdownComponent } from './components/core/dropdown/dropdown.component'
import { LinkButtonComponent } from './components/core/link-button/link-button.component'
import { LinkComponent } from './components/core/link/link.component'
import { PowerBiGraphComponent } from './components/core/power-bi-graph/power-bi-graph.component'
import { SearchableDropdownComponent } from './components/core/searchable-dropdown/searchable-dropdown.component'
import { SideCardComponent } from './components/core/side-card/side-card.component'
import { ClientDashboardComponent } from './components/screen/client-dashboard/client-dashboard.component'
import { ClientReviewsComponent } from './components/screen/client-reviews/client-reviews.component'
import { ClientComponent } from './components/screen/client/client.component'
import { DashboardComponent } from './components/screen/dashboard/dashboard.component'
import { FaqsAndFormsComponent } from './components/screen/faqs-and-forms/faqs-and-forms.component'
import { FlipAssessmentDemoComponent } from './components/screen/flip-assessment-demo/flip-assessment-demo.component'
import { HomeComponent } from './components/screen/home/home.component'
import { LoginComponent } from './components/screen/login/login.component'
import { ProfileComponent } from './components/screen/profile/profile.component'
import { TestServicesComponent } from './components/screen/test-services/test-services.component'
import { SafePipe } from './pipes/safe/safe.pipe'
import { UserService } from './services/user-service/user-service.service'

import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'
import { ClientInformationComponent } from './components/complex/client-information/client-information.component'
import { ClientSearchComponent } from './components/complex/client-search/client-search.component'
import { FlipItemSummaryComponent } from './components/complex/flip-item-summary/flip-item-summary.component'
import { AssessmentModalComponent } from './components/complex/modals/assessment-modal/assessment-modal.component'
import { LogOutWarningModalComponent } from './components/complex/modals/log-out-warning-modal/log-out-warning-modal.component'
import { ReddiEditRoundModalComponent } from './components/complex/modals/reddi-edit-round-modal/reddi-edit-round-modal.component'
import { SubmitEntryModalComponent } from './components/complex/modals/submit-entry-modal/submit-entry-modal.component'
import { ClientInformationEntryComponent } from './components/core/client-information-entry/client-information-entry.component'
import { FlipAssessmentSideCardComponent } from './components/core/flip-assessment-side-card/flip-assessment-side-card.component'
import { FlipButtonComponent } from './components/core/flip-button/flip-button.component'
import { FlipLogoComponent } from './components/core/flip-logo/flip-logo.component'
import { HelpTipComponent } from './components/core/help-tip/help-tip.component'
import { IncorrectSoundTooltipComponent } from './components/core/incorrect-sound-tooltip/incorrect-sound-tooltip.component'
import { NotesSideCardComponent } from './components/core/notes-side-card/notes-side-card.component'
import { ReddiDistanceRadioButtonComponent } from './components/core/reddi-distance-radio-button/reddi-distance-radio-button.component'
import { ReddiLogoComponent } from './components/core/reddi-logo/reddi-logo.component'
import { ReddiResponseButtonComponent } from './components/core/reddi-response-button/reddi-response-button.component'
import { ReddiResponseIconComponent } from './components/core/reddi-response-icon/reddi-response-icon.component'
import { ReddiRoundEditButtonComponent } from './components/core/reddi-round-edit-button/reddi-round-edit-button.component'
import { ReddiTabularCheckboxComponent } from './components/core/reddi-tabular-checkbox/reddi-tabular-checkbox.component'
import { TooltipComponent } from './components/core/tooltip/tooltip.component'
import { CoursesComponent } from './components/screen/courses/courses.component'
import { FlipAssessmentComponent } from './components/screen/flip-assessment/flip-assessment.component'
import { FlipHistoryComponent } from './components/screen/flip-history/flip-history.component'
import { FlipIndividualGraphComponent } from './components/screen/flip-individual-graph/flip-individual-graph.component'
import { FlipInstanceComponent } from './components/screen/flip-instance/flip-instance.component'
import { FlipPopulationComponent } from './components/screen/flip-population/flip-population.component'
import { ReddiHistoryComponent } from './components/screen/reddi-history/reddi-history.component'
import { ReddiInstanceComponent } from './components/screen/reddi-instance/reddi-instance.component'
import { AuthUtilsService } from './services/auth-utils/auth-utils-service'
import { ReddiRoundsStepperComponent } from './components/core/reddi-rounds-stepper/reddi-rounds-stepper.component'
import { ReddiAssessmentComponent } from './components/screen/reddi-assessment/reddi-assessment.component'
import { CourseCardComponent } from './components/core/course-card/course-card.component'
import { FlipExternalReportComponent } from './components/screen/flip-external-report/flip-external-report.component'

export const DATE_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'D MMMM YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
}

function setupAppConfigServiceFactory(service: ConfigService): any {
  const promise = service.init('assets/configuration/configuration.json')
  return () => promise
}

// Set up mask config
const maskConfig: Partial<IConfig> = {
  validation: false,
}

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    HomeComponent,
    TestServicesComponent,
    SidebarComponent,
    SidebarPrimaryButtonComponent,
    SidebarSecondaryButtonComponent,
    ClinicianPortfolioComponent,
    HearhubLogoComponent,
    LoginComponent,
    ActionButtonComponent,
    BackButtonComponent,
    PrimaryCardComponent,
    InformationCardComponent,
    SpinnerComponent,
    HeaderBarComponent,
    SnackBarComponent,
    TextInputComponent,
    DatepickerComponent,
    DropdownComponent,
    SearchableDropdownComponent,
    LinkButtonComponent,
    LinkComponent,
    TableComponent,
    AreYouSureModalComponent,
    FaqsAndFormsComponent,
    QuickViewButtonComponent,
    ClientComponent,
    FlipAssessmentDemoComponent,
    SideCardComponent,
    DashboardComponent,
    ClientReviewsComponent,
    ReviewModalComponent,
    CategoryTileComponent,
    ClientDashboardComponent,
    PowerBiGraphComponent,
    SafePipe,
    LogOutWarningModalComponent,
    FlipPopulationComponent,
    CategoryPillComponent,
    FlipIndividualGraphComponent,
    FlipItemSummaryComponent,
    ClientInformationComponent,
    ClientInformationEntryComponent,
    CollapsibleComponent,
    ClientSearchComponent,
    ReddiLogoComponent,
    FlipViewMainCardComponent,
    FlipLogoComponent,
    ReddiResponseButtonComponent,
    FlipInstanceComponent,
    TooltipComponent,
    ReddiTabularCheckboxComponent,
    FlipButtonComponent,
    HelpTipComponent,
    FlipAssessmentSideCardComponent,
    NotesSideCardComponent,
    ReddiDistanceRadioButtonComponent,
    ReddiInstanceComponent,
    ReddiResponseIconComponent,
    ReddiEditRoundModalComponent,
    ReddiRoundEditButtonComponent,
    IncorrectSoundTooltipComponent,
    ReddiAssessmentComponent,
    ReddiRoundsStepperComponent,
    FlipAssessmentComponent,
    AssessmentModalComponent,
    FlipHistoryComponent,
    SubmitEntryModalComponent,
    ReddiHistoryComponent,
    CoursesComponent,
    CourseCardComponent,
    FlipExternalReportComponent,
    FlipOverviewGraphComponent,
    FlipReportFormComponent,
  ],
  imports: [
    RouterModule,
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    FormsModule,
    MatSidenavModule,
    MatButtonModule,
    MatListModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatTooltipModule,
    ButtonPaginationDirective,
    MatDialogModule,
    NgxMaskModule.forRoot(),
    MsalConfigDynamicModule.forRoot('assets/configuration/configuration.json'),
    NgIdleKeepaliveModule.forRoot(),
    MatButtonToggleModule,
    MatRadioModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: setupAppConfigServiceFactory,
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: ApplicationInsightsService,
      useClass: ApplicationInsightsService,
      deps: [ConfigService],
    },
    {
      provide: LogPublishersService,
      useClass: LogPublishersService,
      deps: [ConfigService],
    },
    {
      provide: LogService,
      useClass: LogService,
      deps: [LogPublishersService, ConfigService],
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpInterceptorService,
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: LOCALE_ID, useValue: 'en-au' },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlTsc },
    { provide: UserService, useClass: UserService },
    { provide: AuthUtilsService, useClass: AuthUtilsService },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
