import { Component, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { ClientDto } from 'src/app/models/client/client-dto';
import { AssessmentDto } from 'src/app/models/flip/assessment-dto';
import { BannerService } from 'src/app/services/banner/banner.service';
import { ClientService } from 'src/app/services/client/client.service';
import { FlipService } from 'src/app/services/flip/flip.service';
import { PreviousRouteService } from 'src/app/services/previous-route/previous-route.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import IsUUID from 'validator/es/lib/isUUID'
import { AssessmentGroupDto } from 'src/app/models/flip/assessment-group-dto';
import { AssessmentItemDto } from 'src/app/models/flip/assessment-item-dto';
import { Filter } from 'src/app/models/power-bi/filter';
import { PdfService } from 'src/app/services/pdf/pdf.service';
import { ConfigService } from 'src/app/services/config/config.service';


export class Group {
  header: string
  subItems: Item[] = []
}

export class Item {
  number: string
  content: string
  score: string
}

@Component({
  selector: 'app-flip-external-report',
  templateUrl: './flip-external-report.component.html',
  styleUrls: ['./flip-external-report.component.scss']
})
export class FlipExternalReportComponent implements OnInit {
  
  _todayDate: Date = new Date()
  _fallbackUrl: string = '/dashboard'
  _client: ClientDto
  _clientId: string
  _assessment: AssessmentDto
  _lastAssessmentDateAgo: string
  _lastAssessmentDateUnavailable: boolean = false
  _lastAssessmentDate: string
  _age: string
  _dob: string
  _score: string = '0'
  _collapsibleItems: Group[] = new Array<Group>()
  _collapsibleItemsRarely: Group[] = new Array<Group>()
  _title: string
  _role: string
  _name: string
  _flipFilters: Filter[]
  selectedDropdownValues: string[] = []; // Declare the property here
  selectedRareDropdownValues: string[] = [];
  formattedDate: string
  constructor(
    private activatedRoute: ActivatedRoute,
    private bannerService: BannerService,
    private clientService: ClientService,
    private dialog: MatDialog,
    private flipService: FlipService,
    private previousRoute: PreviousRouteService,
    private router: Router,
    public spinnerService: SpinnerService,
    private pdfService: PdfService,
    private config: ConfigService) {}
    _dateFormat: string = 'Do MMMM, YYYY'
    _dateFormat1: string = 'DDMMYYYY'
    shouldShowContentPage2 = false;
  protected logoFolderPath: string =
  this.config.getSettings('spa').imageFolder + '/flip-logo/'

protected flipLogoPath: string =
  this.logoFolderPath + 'TSC_FLI-P_logo_web_64X42.png'
protected path: string
   
    
  ngOnInit() {

    this.spinnerService.show()
    this.activatedRoute.queryParams.subscribe(params => {
      this.updateDisplayCondition(params);
    });
    this.path = this.flipLogoPath
    this.activatedRoute.queryParams.subscribe(params => {
        this.selectedDropdownValues = [
        params['dropdown1'],
        params['dropdown2'],
        params['dropdown3'],
        params['dropdown4']
      ];
      this.selectedRareDropdownValues = [
        params['rareDropdown1'],
        params['rareDropdown2'],
        params['rareDropdown3'],
        params['rareDropdown4'],
              ];
      this._name = params['clinicianName']
      this._role = params['title']        
      console.log(this.selectedRareDropdownValues)
    });

    this._clientId = this.activatedRoute.snapshot.paramMap.get('clientId')
    if (!IsUUID(this._clientId)) {
      this.bannerService.showBanner(`Invalid client requested.`, 'error', 3000)
      this.previousRoute.goToPreviousUrl(this._fallbackUrl)
    }

    this._flipFilters = [
      {
        attribute: 'Client/contactid',
        operation: 'eq',
        value: this._clientId,
      },
    ]

    let requests = {
      clientDetails: this.clientService.Details(this._clientId),
      getAssessment: this.flipService.GetLastAssessment(this._clientId),
     
    }

    forkJoin(requests).subscribe({
      next: ({ clientDetails, getAssessment }) => {
        this._assessment = getAssessment

        if (
          this._assessment.lastAssessmentDate === null ||
          this._assessment.lastAssessmentDate === undefined
        ) {
          this._lastAssessmentDateUnavailable = true
        }

        this._lastAssessmentDate = moment(
          this._assessment.lastAssessmentDate,
        ).format(this._dateFormat)
        this._lastAssessmentDateAgo = this.CalculateTimeSinceLastAssessmentDate(
          new Date(this._assessment.lastAssessmentDate),
        )
        this._score = this._assessment.score?.toString()
        this._collapsibleItems = this.MapAssessmentToCollapsible(
          this._assessment,
        )
        
        this._collapsibleItemsRarely = this.MapRareAssessmentToCollapsible(
          this._assessment,
        )

        console.log(this._collapsibleItems)
        this._client = clientDetails
        this._age = this._client.age
        this._dob = moment(this._client.dateOfBirth).format(this._dateFormat)
        this._title = this._client.preferredFullName
         
        

        this.spinnerService.hide()
      },
      error: () => {
        this.bannerService.showBanner(
          `Unable to retrieve assessment details.`,
          'error',
          3000,
        )
        this.previousRoute.goToPreviousUrl(this._fallbackUrl)
      },
    })
    
  }
  
  updateDisplayCondition(params: any): void {
    const parametersToCheck = [
      params['dropdown1'], params['dropdown2'], params['dropdown3'], params['dropdown4'],
      params['rareDropdown1'], params['rareDropdown2'], params['rareDropdown3'], params['rareDropdown4'],
      
    ];

    // Check if at least one parameter is truthy (i.e., has a value)
    this.shouldShowContentPage2 = parametersToCheck.some(param => !!param);
  }

  CalculateTimeSinceLastAssessmentDate(date: Date): string {
    const timeDifference = new Date().getTime() - date.getTime()
    const dayDifference = Math.floor(timeDifference / (1000 * 3600 * 24))

    if (dayDifference < 7) {
      return `${dayDifference} days`
    } else {
      return `${Math.floor(dayDifference / 7)} weeks`
    }
  }

  MapAssessmentToCollapsible(assessment: AssessmentDto): Group[] {
    const result: Group[] = []

    if (assessment.groups) {
      assessment.groups.forEach(group => {
        // Filter items within this group based on selectedDropdownValues
        const filteredItems = group.items.filter(item => 
          this.selectedDropdownValues.includes(item.itemNumber.toString()));
  
          if (filteredItems.length > 0) {
          const collapsibleGroup = this.MapAssessmentGroupToCollapsibleGroup(group, filteredItems);
          result.push(collapsibleGroup);
        }
      });
    }
  
    return result;
  }
  

  MapRareAssessmentToCollapsible(assessment: AssessmentDto): Group[] {
    const result: Group[] = []

    if (assessment.groups) {
      assessment.groups.forEach(group => {
        // Filter items within this group based on selectedDropdownValues
        const filteredRareItems = group.items.filter(item => 
          this.selectedRareDropdownValues.includes(item.itemNumber.trim().toString()));
       
        // If there are any filtered items, map this group to a collapsible group
        if (filteredRareItems.length > 0) {
          const collapsibleGroup = this.MapAssessmentGroupToCollapsibleGroup(group, filteredRareItems);
          console.log("pushedgroup" + collapsibleGroup)
          result.push(collapsibleGroup);
        }
      });
    }
  
    return result;
  }

  MapAssessmentGroupToCollapsibleGroup(group: AssessmentGroupDto, filteredItems: AssessmentItemDto[]): Group {
    const result = new Group();
    result.header = group.name ?? '';
  
    // Use the filtered items to map to collapsible items
    filteredItems.forEach(item => {
      const collapsibleItem = this.MapAssessmentItemToCollapsibleItem(item);
      result.subItems.push(collapsibleItem);
    });
  
    return result;
  }

  MapAssessmentItemToCollapsibleItem(item: AssessmentItemDto): Item {
    return {
      number: item.itemNumber,
      content: item.name,
      score: this.GetScoreText(null),
    }
  }

  GetScoreText(score: boolean | null): string {
    switch (score) {
      case true:
        return 'Mostly'
      case false:
        return 'Rarely'
      default:
        return ''
    }
  }

  GetButtonType(score: string): 'Mostly' | 'Rarely' | 'Blank' {
    switch (score) {
      case 'Mostly':
        return 'Mostly';
      case 'Rarely':
        return 'Rarely';
      default:
        return 'Blank'; 
    }
  }
  // Additional methods 
  generateReportPDF(){
    this.formattedDate = moment(
      this._todayDate
    ).format(this._dateFormat1)
    var reportName = this._title + ' ' + this.formattedDate + ' FLI-P-Report' 
    const content: HTMLElement = document.getElementById('report-content');
    this.pdfService.generatePdf(reportName)

  }
}
