import { TableColumnDefinition } from '../table/table-column-definition'

export const DashboardTableColumnDefinitions: TableColumnDefinition[] = [
  {
    columnName: 'preferredFullName',
    header: 'Full Name',
    sortDisabled: true,
    columnType: 'link',
    columnWidth: '180px',
    columnTextAlign: 'left',
    linkColor: 'primary',
    linkKind: 'standard',
    showIcon: false,
  },
  {
    columnName: 'dateOfBirth',
    columnWidth: '130px',
    columnTextAlign: 'left',
    header: 'DOB',
    sortDisabled: true,
    columnType: 'truncated-string',
  },
  {
    columnName: 'clientPrimaryCentreName',
    columnWidth: '75px',
    columnTextAlign: 'left',
    header: 'Location',
    sortDisabled: true,
    columnType: 'truncated-string',
  },
  {
    columnName: 'age',
    columnWidth: '50px',
    columnTextAlign: 'left',
    header: 'Age',
    sortDisabled: true,
    columnType: 'truncated-string',
  },
  {
    columnName: 'quickView',
    columnWidth: '60px',
    columnTextAlign: 'left',
    header: '',
    sortDisabled: true,
    columnType: 'quick-view-button',
    linkText: 'Quick View',
    linkButtonColor: 'accent',
    linkButtonType: 'button',
  },
]
