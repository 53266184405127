import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() height: number = 0
  @Input() width: number = 0
  @Input() top: number = 0
  @Input() left: number = 0
}
