import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'
import * as moment from 'moment'
import { forkJoin, Observable } from 'rxjs'
import { ClientInformationAttribute } from 'src/app/models/client-information/ClientInformationAttribute'
import { ClientDto } from 'src/app/models/client/client-dto'
import { ClientTableDTO } from 'src/app/models/client/client-table-dto'
import { FeatureStatusRecord } from 'src/app/models/features/feature-status-record'
import { QuickViewEventDTO } from 'src/app/models/quickview/quick-view-event-dto'
import { DashboardTableColumnDefinitions } from 'src/app/models/table-defs/dashboard-table-def'
import { TableColumnDefinition } from 'src/app/models/table/table-column-definition'
import { TableLinkColumnDTO } from 'src/app/models/table/table-link-column-dto'
import { TableQuickViewColumnDTO } from 'src/app/models/table/table-quick-view-column-dto'
import { BannerService } from 'src/app/services/banner/banner.service'
import { ClientService } from 'src/app/services/client/client.service'
import { FeaturesService } from 'src/app/services/features/features.service'
import { SpinnerService } from 'src/app/services/spinner/spinner.service'
import { UserService } from 'src/app/services/user-service/user-service.service'

export type DashboardMode = 'my-clients' | 'all-clients'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
  _tableClients: ClientDto[]

  _clientInformationDisplayText = ''
  _clientInformationUrl = ''
  _clientInformationAttributes: ClientInformationAttribute[] =
    this.createEmptyAttributeList()
  _clientInFocus: string = ''

  _tableColumnsToDisplay: string[] = [
    'preferredFullName',
    'age',
    'dateOfBirth',
    'clientPrimaryCentreName',
    'quickView',
  ]
  _tableColumnDefinitions: TableColumnDefinition[] =
    DashboardTableColumnDefinitions

  _tableData: MatTableDataSource<ClientTableDTO> =
    new MatTableDataSource<ClientTableDTO>()

  _userName: string = ''

  _pageMode: DashboardMode

  _pageTitle: string = ''
  _helpTip: string = ''
  _welcomeMessage: string = ''
  _viewClientText: string = ''
  _viewClientDestination: string = ''

  _clientAddEnabled: boolean
  _clientEditEnabled: boolean

  constructor(
    public clientService: ClientService,
    public spinnerService: SpinnerService,
    public userService: UserService,
    public featureService: FeaturesService,
    public bannerService: BannerService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.spinnerService.show()

    // Determine if should be setup for all clients or my clients
    const routeName: string = this.activatedRoute.snapshot.url[0].path
    this._pageMode = this.determinePageMode(routeName)

    let allClients = this.clientService.OrgClients('', '', 5000)
    let myClients = this.clientService.List('', '', '', 5000)
    let clients: Observable<ClientDto[]>

    switch (this._pageMode) {
      case 'all-clients':
        this.configurePageForAllClients()
        clients = allClients
        break
      case 'my-clients':
        this.configurePageForMyClients()
        clients = myClients
        break
    }

    // Get username information
    this._userName = this.userService.GetUserName().split(' ')[0]
    this._welcomeMessage = this.getWelcomeMessage()

    //Get Data
    let requests = {
      clients: clients,
      features: this.featureService.GetFeatures(),
    }

    forkJoin(requests).subscribe({
      next: ({ clients, features }) => {
        this._clientAddEnabled = this.getFeatureValue(features, 'clientAdd')
        this._clientEditEnabled = this.getFeatureValue(features, 'clientEdit')
        this._tableClients = clients
        this._tableData = this.mapClientData(this._tableClients)
      },
      error: (error: Error) => {
        this.spinnerService.hide()
        this.bannerService.showBanner(
          'An error occured while retrieving the reference data.',
          'error',
          30000,
        )

        throw error
      },
      complete: () => {
        this.spinnerService.hide()
      },
    })
  }

  determinePageMode(route: string): DashboardMode {
    if (route === 'dashboard') {
      return 'my-clients'
    } else if (route === 'all-clients') {
      return 'all-clients'
    }
    throw Error('Unable to determine dashboard mode')
  }

  configurePageForMyClients() {
    this._pageTitle = 'My Clients'
    this._helpTip = 'You are the case manager for these clients.'
    this._viewClientText = 'View All Clients'
    this._viewClientDestination = '/all-clients'
  }

  configurePageForAllClients() {
    this._pageTitle = 'All Clients'
    this._helpTip = 'These are all the clients in your organisation.'
    this._viewClientText = 'View My Clients'
    this._viewClientDestination = '/dashboard'
  }

  getFeatureValue(features: FeatureStatusRecord[], featureName: string) {
    return features.find((feature) => feature.name === featureName).enabled
  }

  redirect(destination: string) {
    this.router.navigate([destination])
  }

  getWelcomeMessage(): string {
    return 'Hello, ' + this._userName
  }

  mapClientData(clients: ClientDto[]): MatTableDataSource<ClientTableDTO> {
    const tableData: ClientTableDTO[] = new Array<ClientTableDTO>()

    clients.forEach((source) => {
      const target = new ClientTableDTO()
      target.age = source.age
      target.clientPrimaryCentreName = source.clientPrimaryCentreName
      target.dateOfBirth = moment(source.dateOfBirth).format('Do MMMM, YYYY')
      target.genderCodeName = source.genderCodeName

      target.preferredFullName = new TableLinkColumnDTO()
      target.preferredFullName.linkText = source.preferredFullName
      target.preferredFullName.linkUrl = '/client-dashboard/' + source.contactId
      target.preferredFullName.linkData = source.contactId
      target.preferredFullName.selected = false

      target.quickView = new TableQuickViewColumnDTO()
      target.quickView.linkText = 'Quick View'
      target.quickView.linkData = source
      target.quickView.selected = false

      tableData.push(target)
    })

    return new MatTableDataSource<ClientTableDTO>(tableData)
  }

  onQuickViewButtonClick(event: QuickViewEventDTO) {
    if (this._clientInFocus === event.data.contactId) {
      this.clearClientInformation()
    } else {
      this.setClientInformation(event.data)
    }
  }

  setClientInformation(client: ClientDto) {
    this._clientInFocus = client.contactId
    this._clientInformationDisplayText = this._clientEditEnabled
      ? 'Edit profile'
      : ''
    this._clientInformationUrl = this._clientEditEnabled
      ? '/edit-client/' + client.contactId
      : ''
    this.setAttribute(
      this._clientInformationAttributes,
      'Full Name',
      client.preferredFullName,
    )
    this.setAttribute(this._clientInformationAttributes, 'Age', client.age)
    this.setAttribute(
      this._clientInformationAttributes,
      'DOB',
      moment(client.dateOfBirth).format('Do MMMM, YYYY'),
    )
    this.setAttribute(
      this._clientInformationAttributes,
      'Gender',
      client.genderCodeName,
    )
    this.setAttribute(
      this._clientInformationAttributes,
      'Case Manager',
      client.clientCaseManagerName,
    )
  }

  clearClientInformation() {
    this._clientInFocus = ''
    this._clientInformationDisplayText = ''
    this._clientInformationUrl = ''
    this._clientInformationAttributes = this.createEmptyAttributeList()
  }

  createAttribute(
    display: string,
    value: string = '',
    placeholder: string = '',
  ): ClientInformationAttribute {
    return {
      display: display,
      value: value,
      placeholder: placeholder,
    }
  }

  createEmptyAttributeList(): ClientInformationAttribute[] {
    return [
      this.createAttribute('Full Name', '', '(Select quick view)'),
      this.createAttribute('Age', '', ''),
      this.createAttribute('DOB', '', ''),
      this.createAttribute('Gender', '', ''),
      this.createAttribute('Case Manager', '', ''),
    ]
  }

  getAttribute(
    attributes: ClientInformationAttribute[],
    display: string,
  ): ClientInformationAttribute {
    return attributes.find((attribute) => attribute.display === display)
  }

  setAttribute(
    attributes: ClientInformationAttribute[],
    display: string,
    value: string,
  ) {
    this.getAttribute(attributes, display).value = value
  }
}
