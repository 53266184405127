import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatExpansionPanel } from '@angular/material/expansion'
import { Observable, map, startWith } from 'rxjs'
import { LabelToId } from 'src/app/common/input-utils'
import { Datum } from 'src/app/models/common/datum'

@Component({
  selector: 'app-searchable-dropdown',
  templateUrl: './searchable-dropdown.component.html',
  styleUrls: ['./searchable-dropdown.component.scss'],
})
export class SearchableDropdownComponent implements OnInit {
  @Input() data: Datum[]
  @Input() control: FormControl<Datum>
  @Input() label: string
  @Input() required: boolean
  @Input() errorText: string
  id: string

  @ViewChild(MatExpansionPanel, { static: true })
  _matExpansionPanelElement: MatExpansionPanel

  _placeholder: boolean = true
  _placeholderText: string = '- Select -'

  _displayValue: string = this._placeholderText
  _filteredData: Observable<Datum[]>
  _search: FormControl = new FormControl('')

  ngOnInit(): void {
    this.id = LabelToId(this.label)

    this.control.valueChanges.subscribe((datum) => {
      this._placeholder = datum == null
      this._displayValue = datum == null ? this._placeholderText : datum.label
    })

    this._filteredData = this._search.valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filter(value || '')),
    )
  }

  ClearClicked(): void {
    this.control.setValue(null)
    this.ClearSearch()
    this._matExpansionPanelElement.toggle()
  }

  ClearSearch(): void {
    this._search.setValue(null)
  }

  SetControl(value: Datum): void {
    this.ClearSearch()
    this.control.setValue(value)
    this.control.markAsDirty()
    this._matExpansionPanelElement.toggle()
  }

  private _filter(value: string): Datum[] {
    const filterValue = value.toLowerCase()
    return this.data.filter((datum) =>
      datum.label.toLowerCase().includes(filterValue),
    )
  }

  OnExpansionPanelClose() {
    this.control.markAsTouched()
  }
}
