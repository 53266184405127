import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { finalize, forkJoin } from 'rxjs';
import { ClientDto } from 'src/app/models/client/client-dto';
import { Datum } from 'src/app/models/common/datum';
import { AssessmentDto } from 'src/app/models/flip/assessment-dto';
import { AssessmentItemDto } from 'src/app/models/flip/assessment-item-dto';
import { FlipItem } from 'src/app/models/flip/flip-item';
import { BannerService } from 'src/app/services/banner/banner.service';
import { ClientService } from 'src/app/services/client/client.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { FlipService } from 'src/app/services/flip/flip.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'app-flip-report-form',
  templateUrl: './flip-report-form.component.html',
  styleUrls: ['./flip-report-form.component.scss']
})
export class FlipReportFormComponent {
   
  type: string = 'FLI_P'
  initialForm: FormGroup
  _todayDate: Date = new Date();
  
  dropdowns: { label: string; data: Datum[]; control: FormControl; required: boolean; errorText?: string }[] = [];
  rareDropdown: { label: string; data: Datum[]; control: FormControl; required: boolean; errorText?: string }[] = [];
  clientId: string
 
  private _clientDto: ClientDto
  _flipAssessmentDataDto: AssessmentDto
  _stillProcessing: boolean = true
  _clientName: string
  _age: string
  _dob: string
  _title: string
  _dateOfBirth: Date = new Date()
  _showClientDetails: boolean = false
  _dateFormat: string = 'Do MMMM, YYYY'
  _score: string = '0'
  protected logoFolderPath: string =
  this.config.getSettings('spa').imageFolder + '/flip-logo/'
  protected flipLogoPath: string =
  this.logoFolderPath + 'TSC_FLI-P_logo_web_64X42.png'
  protected path: string
  private _items: FlipItem[]

  constructor(
    public flipService: FlipService,
    private router: Router,
    private clientService: ClientService,
    private route: ActivatedRoute,
    public spinnerService: SpinnerService,
    public bannerService: BannerService,
    private config: ConfigService,
    ) {}
    ngOnInit() {
      this.initialForm = new FormGroup({
        clinicianName: new FormControl(''),
        title: new FormControl('')
      });
      this.path = this.flipLogoPath
      
      this.spinnerService.show()
      this.clientId = this.route.snapshot.params['clientId']
      forkJoin([
        this.clientService.Details(this.clientId),
        this.flipService.GetLastAssessment(this.clientId),
      ])
        .pipe(
          finalize(() => {
            this.spinnerService.hide()
            this._stillProcessing = false
          }),
        )
        .subscribe(
          ([clientDto, assessmentDto]) => {
            this._clientDto = clientDto
            this._clientName = this._clientDto.preferredFullName
            this._age = this._clientDto.age
            this._dateOfBirth = this._clientDto.dateOfBirth
            this._dob= moment(this._dateOfBirth).format(this._dateFormat)
            this._showClientDetails = true
            this._flipAssessmentDataDto = assessmentDto
            this._score= this._flipAssessmentDataDto?.score?.toString()
            this._flipAssessmentDataDto.assessmentDate =
              this._flipAssessmentDataDto.assessmentDate ?? moment().toDate()
            this._flipAssessmentDataDto.clientId = this.clientId
            const flipAssessmentAllItems =
              this._flipAssessmentDataDto.groups.flatMap((a) => a.items)
            this.extractItems(flipAssessmentAllItems)
            console.log(this._flipAssessmentDataDto)
           
            this.getLastFourAssessmentsWithScoreTrue();
            this.populateDropdownsWithFalseScoreItems();
            
          },
          (error) => {
            this.spinnerService.hide()
            console.log(error)
            throw error
          },
        )
        
    }

    extractItems(flipAssessmentAllItems: AssessmentItemDto[]) {
      this._items = []
      for (let count = 0; count < flipAssessmentAllItems.length; count++) {
        let item = flipAssessmentAllItems[count]
  
        let flipItem: FlipItem = {
          id: item.orderNumber,
          index: count,
          prevAnswer: item.score,
          currAnswer: null,
          assessmentItem: item,
        }
  
        this._items.push(flipItem)
      }
    }
    getLastFourAssessmentsWithScoreTrue() {
      
      const itemsWithScoreTrue = this._flipAssessmentDataDto?.groups
        .flatMap(group => group.items)
        .filter(item => item.score)
        .map(item => {
          // Create a new object for the dropdown with both the name and number
          return {
            label: `(${item.itemNumber}) ${item.name} `,
            value: item.itemNumber.toString(),
          };
        });
    
      // error
      if (itemsWithScoreTrue.length === 0) {
        console.log("No items with score true found.");
        return;
      }
    
      //  assign this items array to all  dropdowns
      for (let i = 0; i < 4; i++) {
        this.dropdowns.push({
          label: `Skill ${i + 1}`,
          data: itemsWithScoreTrue,
          control: new FormControl(''),
          required: true,
        });
      }
    
      // Since all dropdowns have the same values, we can now set up the form controls for each
      this.dropdowns.forEach((dropdown, index) => {
        const controlName = 'dropdown' + (index + 1);
        this.initialForm.addControl(controlName, dropdown.control);
      });
    
      console.log(this.dropdowns);
    }
    
  // Items to achieve assessment 
  populateDropdownsWithFalseScoreItems() {
    const itemsWithScoreFalse = this._flipAssessmentDataDto?.groups
      .flatMap(group => group.items)
      .filter(item => item.score == false) // Notice the negation here for false
      .map(item => ({
        label: `(${item.itemNumber}) ${item.name}`,
        value: item.itemNumber.toString(),
      }));
  
    if (itemsWithScoreFalse.length === 0) {
      console.log("No items with score false found.");
      return;
    }
  
    for (let i = 0; i < 4; i++) { 
      this.rareDropdown.push({
        label: `Skill ${i + 1}`,
        data: itemsWithScoreFalse,
        control: new FormControl(''),
        required: true,
      });
    }
  
    // Update the form controls for these new dropdowns
    this.rareDropdown.forEach((rareDropdown, index) => {
      const controlName = 'rareDropdown' + (index + 1); // Different naming to distinguish
      this.initialForm.addControl(controlName, rareDropdown.control);
    });
  }
  

  onSubmit() {

  this.router.navigate(['flip/external-report', this.clientId], { queryParams: this.initialForm.value });
  }
}
