import { Component, OnInit } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute } from '@angular/router'
import * as moment from 'moment'
import { forkJoin } from 'rxjs'
import { ClientDto } from 'src/app/models/client/client-dto'
import { ReddiConfigurationDto } from 'src/app/models/reddi/reddi-configuration-dto'
import { ReddiDto, ReddiRoundDto } from 'src/app/models/reddi/reddi-dto'
import { ReddiRoundTableDto } from 'src/app/models/reddi/reddi-round-table-dto'
import { ReddiInstanceTableColumnDefinition } from 'src/app/models/table-defs/reddi-instance-table-def'
import { TableColumnDefinition } from 'src/app/models/table/table-column-definition'
import { BannerService } from 'src/app/services/banner/banner.service'
import { ClientService } from 'src/app/services/client/client.service'
import { PreviousRouteService } from 'src/app/services/previous-route/previous-route.service'
import {
  CreateReddiRoundTableDto,
  Cue,
  DistanceToDisplayName,
  ImpactingFactor,
  ImpactingFactorToDisplayName,
  ListeningCondition,
  PresenterRole,
  VolumeToDisplayName,
} from 'src/app/services/reddi/reddi-utils'
import { ReddiService } from 'src/app/services/reddi/reddi.service'
import { SpinnerService } from 'src/app/services/spinner/spinner.service'

@Component({
  selector: 'app-reddi-instance',
  templateUrl: './reddi-instance.component.html',
  styleUrls: ['./reddi-instance.component.scss'],
})
export class ReddiInstanceComponent implements OnInit {
  _title: string = '\u00A0'
  _age: string
  _dateOfBirth: Date = new Date()
  _dataEnteredBy: string
  _soundEnteredBy: string
  _presentationDate: string
  _considerations: string[] = []
  _notes: string = 'No notes entered'
  _showClientDetails: boolean = false

  _tableColumnsToDisplay: string[] = []

  allColumns: string[] = [
    'distance',
    'listening',
    'volume',
    'cues',
    'm',
    'or',
    'oo',
    'ah',
    'ee',
    'sh',
    's',
  ]

  exceptSound2: string[] = [
    'distance',
    'listening',
    'volume',
    'cues',
    'm',
    'oo',
    'ah',
    'ee',
    'sh',
    's',
  ]

  _iteration: ReddiDto
  _client: ClientDto
  _configuration: ReddiConfigurationDto

  _tableColumnDefinitions: TableColumnDefinition[] =
    ReddiInstanceTableColumnDefinition

  _tableData: MatTableDataSource<ReddiRoundTableDto> =
    new MatTableDataSource<ReddiRoundTableDto>()

  constructor(
    public spinnerService: SpinnerService,
    public reddiService: ReddiService,
    public router: ActivatedRoute,
    private bannerService: BannerService,
    public clientService: ClientService,
    public prevRoute: PreviousRouteService,
  ) {}

  ngOnInit(): void {
    this.spinnerService.show()

    const clientId: string = this.router.snapshot.url[2].path
    const iterationId: string = this.router.snapshot.url[3].path

    let request = {
      iteration: this.reddiService.getReddiIteration(iterationId),
      client: this.clientService.Details(clientId),
      configuration: this.reddiService.getConfiguration(),
    }

    forkJoin(request).subscribe({
      next: ({ iteration, client, configuration }) => {
        this._iteration = iteration
        this._client = client
        this._configuration = configuration

        this.processSidebarComponents(iteration)
        this._tableData = new MatTableDataSource<ReddiRoundTableDto>(
          this.processRounds(iteration),
        )

        this._tableColumnsToDisplay = this.getTableColumns(
          !this._configuration.ignoreSound2,
        )

        this._title = this._client.preferredFullName
        this._age = this._client.age
        this._dateOfBirth = this._client.dateOfBirth

        this._showClientDetails = true
      },
      error: () => {
        this.bannerService.showBanner(
          `Unable to retrieve data.`,
          'error',
          30000,
        )
        this.spinnerService.hide()
      },
      complete: () => {
        this.spinnerService.hide()
      },
    })
  }

  getTableColumns(sound2Enabled: boolean) {
    return sound2Enabled ? this.allColumns : this.exceptSound2
  }

  processSidebarComponents(reddi: ReddiDto) {
    this._title = reddi.iteration.clientDisplayName
    this._dataEnteredBy = reddi.iteration.presenterDisplayName
    this._soundEnteredBy = PresenterRole[reddi.iteration.presenterRole]
    this._presentationDate = moment(reddi.iteration.presentationDate).format(
      'dddd MMMM Do, yyyy',
    )

    for (let i = 0; i < reddi.iteration.impactingFactors.length; i++) {
      if (reddi.iteration.impactingFactors[i] !== ImpactingFactor.None) {
        this._considerations.push(
          ImpactingFactorToDisplayName(reddi.iteration.impactingFactors[i]),
        )
      }
    }
    this._notes = reddi.iteration.notes
  }

  routerLinkClicked() {
    const prevUrl = this.prevRoute.getPreviousUrl()
    if (prevUrl) {
      this.prevRoute.goToPreviousUrl(prevUrl)
    }
  }

  processRounds(reddi: ReddiDto): ReddiRoundTableDto[] {
    const tableRounds: ReddiRoundTableDto[] = []
    let round: ReddiRoundDto

    for (let r = 0; r < reddi.rounds.length; r++) {
      round = reddi.rounds[r]
      console.log(round)

      tableRounds.push({
        distance: DistanceToDisplayName(round.distance),
        listening: ListeningCondition[round.listeningCondition],
        volume: VolumeToDisplayName(round.volume),
        cues: Cue[round.cue],
        m: CreateReddiRoundTableDto(round.sound1Result, round.sound1Imitation),
        or: CreateReddiRoundTableDto(round.sound2Result, round.sound2Imitation),
        oo: CreateReddiRoundTableDto(round.sound3Result, round.sound3Imitation),
        ah: CreateReddiRoundTableDto(round.sound4Result, round.sound4Imitation),
        ee: CreateReddiRoundTableDto(round.sound5Result, round.sound5Imitation),
        sh: CreateReddiRoundTableDto(round.sound6Result, round.sound6Imitation),
        s: CreateReddiRoundTableDto(round.sound7Result, round.sound7Imitation),
        action: '',
      })
    }

    return tableRounds
  }
}
