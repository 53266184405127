import { Component, Input } from '@angular/core'
import { ConfigService } from 'src/app/services/config/config.service'
@Component({
  selector: 'app-hearhub-logo',
  templateUrl: './hearhub-logo.component.html',
  styleUrls: ['./hearhub-logo.component.scss'],
})
export class HearhubLogoComponent {
  constructor(private config: ConfigService) {}

  protected logoFolderPath: string =
    this.config.getSettings('spa').imageFolder + '/hearhub-logo/'

  protected greenWithWhiteTextLogoPath: string =
    this.logoFolderPath + 'HearHub_Final-LOGO_Green-and-white_FINAL-145x48.png'
  protected whiteWithGreenTextLogoPath: string =
    this.logoFolderPath + 'HearHub_Final-LOGO_White-and-green_FINAL-145x48.png'

  protected path: string

  @Input() inverted: boolean = false

  ngOnInit() {
    this.path = this.inverted
      ? this.whiteWithGreenTextLogoPath
      : this.greenWithWhiteTextLogoPath
  }
}
