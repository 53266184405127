import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { MsalBroadcastService, MsalService } from '@azure/msal-angular'
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PopupRequest,
} from '@azure/msal-browser'
import { Observable, Subject, Subscription, filter } from 'rxjs'
import { ApplicationInsightsService } from 'src/app/services/application-insights/application-insights.service'
import { BannerService } from 'src/app/services/banner/banner.service'
import { ConfigService } from 'src/app/services/config/config.service'
import { SpinnerService } from 'src/app/services/spinner/spinner.service'
import { SubscriptionService } from 'src/app/services/subscription/subscription.service'
import {
  LoginStatus,
  UserService,
} from 'src/app/services/user-service/user-service.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>()
  private subscription: Subscription
  _loginStatus: Observable<LoginStatus>

  constructor(
    private router: Router,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private subscriptionService: SubscriptionService,
    protected spinnerService: SpinnerService,
    private appInsights: ApplicationInsightsService,
    private bannerService: BannerService,
    private userService: UserService,
    private config: ConfigService,
  ) {
    console.log('login component constructor')
  }

  ngOnInit(): void {
    this.appInsights.logPageView('login', '/login')

    // If user navigates to the login page and they are already logged in then set the logged in status
    if (this.authService.instance.getActiveAccount() != null) {
      this.spinnerService.show()
      this.userService.SetLoggedIn(LoginStatus.LoggedIn)
      this.navigateOnLogin()
    } else {
      this.userService.SetLoggedIn(LoginStatus.LoggedOut)
    }
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS,
        ),
      )
      .subscribe((result: EventMessage) => {
        this.spinnerService.show()

        // Set the active account into the session so that it is available for other components
        const payload = result.payload as AuthenticationResult
        this.authService.instance.setActiveAccount(payload.account)
        const claims =
          this.authService.instance.getActiveAccount()?.idTokenClaims
        if (claims != null) {
          this.userService.SetUserName(claims['name'] as string)
        }
        // Call the service to get the subscription details
        this.subscription = this.subscriptionService.HasTools().subscribe({
          next: (response: boolean) => {
            this.userService.SetLoggedIn(LoginStatus.LoggedIn)
            this.userService.SetHasTools(response)
            this.navigateOnLogin()
            this.spinnerService.hide()
          },
          error: (error: any) => {
            this.userService.SetHasTools(false)
            this.bannerService.showBanner('An error occurred.', 'error', 300000)
            this.userService.SetLoggedIn(LoginStatus.LoggedIn)
            this.navigateOnLogin()
            throw error
          },

          complete: () => {
            this.userService.SetLoggedIn(LoginStatus.LoggedIn)
            this.spinnerService.hide()
          },
        })
      })
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  handleClick() {
    this.spinnerService.show()

    this.userService.SetLoggedIn(LoginStatus.InProgress)
    let request: PopupRequest = {
      scopes: ['user.read'],
      prompt: this.config.getSettings('spa').loginType,
    }
    let resp = this.authService.loginPopup(request).subscribe({
      next: () => {
        //do nothing - let the event handler above handle the login success
      },
      error: (error) => {
        // User has cancelled
        this.spinnerService.hide()
        this.userService.SetLoggedIn(LoginStatus.LoggedOut)
        throw error
      },
    })
  }

  navigateOnLogin() {
    console.log('navigate on login')
    this.router.navigate([
      this.userService.HasTools() ? '/dashboard' : '/courses',
    ])
  }
}
