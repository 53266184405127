import { TableReddiResponseIconColumnDto } from '../table/table-reddi-response-icon-column-dto'
import { TableReddiRoundEditButtonColumnDto } from '../table/table-reddi-round-edit-button-column.dto'

export class ReddiRoundTableDto {
  rowId?: string
  distance: string
  listening: string
  volume: string
  cues: string
  m: TableReddiResponseIconColumnDto
  or?: TableReddiResponseIconColumnDto
  oo: TableReddiResponseIconColumnDto
  ah: TableReddiResponseIconColumnDto
  ee: TableReddiResponseIconColumnDto
  sh: TableReddiResponseIconColumnDto
  s: TableReddiResponseIconColumnDto
  action?: string
  edit?: TableReddiRoundEditButtonColumnDto
}
