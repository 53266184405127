import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, catchError, map, tap } from 'rxjs'
import { ReddiConfigurationDto } from 'src/app/models/reddi/reddi-configuration-dto'
import { ReddiDto, ReddiRoundDto } from 'src/app/models/reddi/reddi-dto'
import { ReddiListDto } from 'src/app/models/reddi/reddi-list-dto'
import { ApplicationInsightsService } from '../application-insights/application-insights.service'
import { ConfigService } from '../config/config.service'

@Injectable({
  providedIn: 'root',
})
export class ReddiService {
  constructor(
    private http: HttpClient,
    private appInsights: ApplicationInsightsService,
    private config: ConfigService,
  ) {}

  private readonly reddiBaseUrl =
    this.config.getSettings('webApi').baseUri + 'reddi/'
  private readonly getIterationReddiRoundsUrl =
    this.reddiBaseUrl + 'getiterationreddirounds'
  private readonly getReddiRoundByIdUrl =
    this.reddiBaseUrl + 'getreddiRoundbyId'
  private readonly getConfigurationUrl = this.reddiBaseUrl + 'configuration'
  private readonly getcreateReddiAssessmentUrl = this.reddiBaseUrl + 'create'
  private readonly getReddiListUrl = this.reddiBaseUrl + 'list'

  getReddiIteration(iterationId: string): Observable<ReddiDto> {
    const params: Record<string, any> = { iterationId: iterationId }

    const result = this.http
      .get<ReddiDto>(this.getIterationReddiRoundsUrl, { params: params })
      .pipe(
        map((response: any) => {
          const reddi = response as ReddiDto
          return reddi
        }),
        tap((_) =>
          this.appInsights.logEvent('Retrieved REDDI Iteration from Dataverse'),
        ),
        catchError(async (err) => {
          //Let the top level component handle the error
          throw err
        }),
      )

    return result
  }

  getReddiRoundsByID(roundId: string): Observable<ReddiRoundDto> {
    const params: Record<string, any> = { roundId: roundId }

    const result = this.http
      .get<ReddiRoundDto>(this.getReddiRoundByIdUrl, { params: params })
      .pipe(
        map((response: any) => {
          const reddiRound = response as ReddiRoundDto
          return reddiRound
        }),
        tap((_) =>
          this.appInsights.logEvent('Retrieved reddi Round from dataverse'),
        ),
        catchError(async (err) => {
          throw err
        }),
      )
    return result
  }

  getConfiguration(): Observable<ReddiConfigurationDto> {
    const result = this.http
      .get<ReddiConfigurationDto>(this.getConfigurationUrl)
      .pipe(
        map((response: any) => {
          const reddi = response as ReddiConfigurationDto
          return reddi
        }),
        tap((_) =>
          this.appInsights.logEvent(
            'Retrieved REDDI Configuration from Dataverse',
          ),
        ),
        catchError(async (err) => {
          //Let the top level component handle the error
          throw err
        }),
      )

    return result
  }

  createReddi(reddi: ReddiDto): Observable<any> {
    return this.http.post<any>(this.getcreateReddiAssessmentUrl, reddi).pipe(
      tap((_) => this.appInsights.logEvent('reddi assessment saved')),
      catchError(async (err) => {
        throw err
      }),
    )
  }

  getList(clientId: string): Observable<ReddiListDto[]> {
    const params: Record<string, any> = { clientId: clientId }

    const result = this.http
      .get<ReddiListDto[]>(this.getReddiListUrl, { params: params })
      .pipe(
        map((response: any) => {
          const reddiList = response as ReddiListDto[]
          return reddiList
        }),
        tap((_) =>
          this.appInsights.logEvent('Retrieved REDDI list from Dataverse'),
        ),
        catchError(async (err) => {
          throw err
        }),
      )
    return result
  }
}
