import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-information-card',
  templateUrl: './information-card.component.html',
  styleUrls: ['./information-card.component.scss'],
})
export class InformationCardComponent {
  @Input() title: string = ''
  @Input() subtitle: string = '\u00A0' // set default value so tha this renders and maintain card size uniform
  class: string[] = ['information-card']
}
