import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

export enum LoginStatus {
  InProgress = 0,
  LoggedIn = 1,
  LoggedOut = 2,
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  loginStatus$ = new BehaviorSubject<LoginStatus>(LoginStatus.LoggedOut)
  hasTools$ = new BehaviorSubject<boolean>(false)
  userName$ = new BehaviorSubject<string>('')

  constructor() {
    const currentValue = localStorage.getItem('loginStatus')

    if (currentValue == null) {
      this.loginStatus$.next(LoginStatus.LoggedOut)
    } else {
      const loggedIn = parseInt(currentValue) as LoginStatus
      this.loginStatus$.next(loggedIn)
    }
  }

  IsLoggedIn(): boolean {
    const currentValue = localStorage.getItem('loginStatus')
    if (currentValue == null) {
      this.loginStatus$.next(LoginStatus.LoggedOut)
      return this.loginStatus$.value == LoginStatus.LoggedIn
    }
    const loggedIn = parseInt(currentValue) as LoginStatus
    this.loginStatus$.next(loggedIn)
    return this.loginStatus$.value == LoginStatus.LoggedIn
  }

  GetLoginStatus(): LoginStatus {
    const loginStatus = localStorage.getItem('loginStatus')
    if (loginStatus == null) {
      return LoginStatus.LoggedOut
    }
    const loggedIn = parseInt(loginStatus) as LoginStatus
    this.loginStatus$.next(loggedIn)
    return this.loginStatus$.getValue()
  }

  // hastools
  SetLoggedIn(loggedIn: LoginStatus) {
    localStorage.setItem('loginStatus', loggedIn.toString())
    this.loginStatus$.next(loggedIn)
  }

  SetHasTools(hasTools: boolean) {
    localStorage.setItem('hasTools', hasTools.toString())
    this.hasTools$.next(hasTools)
  }

  HasTools(): boolean {
    const hasTools = localStorage.getItem('hasTools')
    if (hasTools == null) {
      return false
    }
    this.hasTools$.next(hasTools == 'true')
    return this.hasTools$.getValue()
  }

  // Username
  SetUserName(userName: string) {
    localStorage.setItem('userName', userName)
    this.userName$.next(userName)
  }

  GetUserName(): string {
    const userName = localStorage.getItem('userName')
    if (userName == null) {
      return ''
    }
    return userName
  }

  getPreferredUsername(): string {
    // Retrieve the key associated with the account information
    const accountKeys = localStorage.getItem('msal.account.keys')
    if (!accountKeys) {
      console.error('Account keys not found')
      return ''
    }

    // Parse the JSON string to get the array of keys
    const keysArray = JSON.parse(accountKeys)
    if (!keysArray || keysArray.length === 0) {
      console.error('Account keys array is empty or invalid')
      return ''
    }

    // Use the first key from the array to retrieve the specific account object
    // You might need to adjust this if you need to select a different key
    const accountValue = localStorage.getItem(keysArray[0])
    if (!accountValue) {
      console.error('Account object not found')
      return ''
    }

    // Parse the account object
    const accountObj = JSON.parse(accountValue)
    if (!accountObj || !accountObj.idTokenClaims) {
      console.error('Invalid account object or idTokenClaims not found')
      return ''
    }

    // Retrieve the preferred_username from idTokenClaims
    const preferredUsername = accountObj.idTokenClaims.preferred_username
    if (!preferredUsername) {
      console.error('Preferred username not found in idTokenClaims')
      return ''
    }

    return preferredUsername
  }
}
