import { Component, EventEmitter, Output } from '@angular/core'
import { MatRadioChange } from '@angular/material/radio'

@Component({
  selector: 'app-reddi-distance-radio-button',
  templateUrl: './reddi-distance-radio-button.component.html',
  styleUrls: ['./reddi-distance-radio-button.component.scss'],
})
export class ReddiDistanceRadioButtonComponent {
  @Output() selectionChange = new EventEmitter<string>()

  labels: string[] = ['0.5 - 0.9', '1 - 1.9', '2 - 3.9', '4 - 7.9', '8+']
  selected: string | null = null
  title: string = 'Distance (metres)*'

  onSelectionChange(label: string): void {
    if (this.selected === label) {
      this.selected = null
    } else {
      this.selected = label
    }
    this.selectionChange.emit(this.selected)
  }
}
