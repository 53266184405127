import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Filter } from 'src/app/models/power-bi/filter'
import { BannerService } from 'src/app/services/banner/banner.service'
import { ClientService } from 'src/app/services/client/client.service'
import { PreviousRouteService } from 'src/app/services/previous-route/previous-route.service'
import IsUUID from 'validator/es/lib/isUUID'

@Component({
  selector: 'app-flip-individual-graph',
  templateUrl: './flip-individual-graph.component.html',
  styleUrls: ['./flip-individual-graph.component.scss'],
})
export class FlipIndividualGraphComponent {
  _clientName: string = 'Loading...'
  _flipFilters: Filter[]
  _age: string = ''
  _dob: Date
  private _clientId: string

  constructor(
    private router: ActivatedRoute,
    private clientService: ClientService,
    private bannerService: BannerService,
    private previousRoute: PreviousRouteService,
  ) {}

  ngOnInit(): void {
    this.router.paramMap.subscribe((paramMap) => {
      const clientId = paramMap.get('clientId')
      if (!IsUUID(clientId)) {
        this.bannerService.showBanner(
          `Invalid clientId ${clientId}.`,
          'error',
          30000,
        )
        throw new Error('Invalid ClientId')
      }
      this.clientService.Details(clientId).subscribe({
        next: (clientDetails) => {
          this._clientName = clientDetails.preferredFullName
          this._age = clientDetails.age
          this._dob = clientDetails.dateOfBirth
        },
        error: (err) => {
          this.bannerService.showBanner(
            'An error occured while retrieving the client details.',
            'error',
            300000,
          )
          throw err
        },
      })

      this._flipFilters = [
        {
          attribute: 'Client/contactid',
          operation: 'eq',
          value: clientId,
        },
      ]
    })
  }

  routerLinkClicked() {
    const prevUrl = this.previousRoute.getPreviousUrl()
    if (prevUrl) {
      this.previousRoute.goToPreviousUrl(prevUrl)
    }
  }
}
