import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'
import * as moment from 'moment'
import { firstValueFrom, forkJoin } from 'rxjs'
import { ClientDto } from 'src/app/models/client/client-dto'
import { AssessmentDto } from 'src/app/models/flip/assessment-dto'
import { AssessmentGroupDto } from 'src/app/models/flip/assessment-group-dto'
import { AssessmentItemDto } from 'src/app/models/flip/assessment-item-dto'
import { FlipHistoryDto } from 'src/app/models/flip/flip-history-dto'
import { FlipHistoryTableDto } from 'src/app/models/flip/flip-history-table-dto'
import { Filter } from 'src/app/models/power-bi/filter'
import { FlipHistoryTableColumnDefinitions } from 'src/app/models/table-defs/flip-history-table-def'
import { TableColumnDefinition } from 'src/app/models/table/table-column-definition'
import { TableLinkColumnDTO } from 'src/app/models/table/table-link-column-dto'
import { BannerService } from 'src/app/services/banner/banner.service'
import { ClientService } from 'src/app/services/client/client.service'
import { FlipService } from 'src/app/services/flip/flip.service'
import { PreviousRouteService } from 'src/app/services/previous-route/previous-route.service'
import { SpinnerService } from 'src/app/services/spinner/spinner.service'
import IsUUID from 'validator/es/lib/isUUID'
import {
  SubmitEntryModalComponent,
  SubmitEntryModalResponse,
} from '../../complex/modals/submit-entry-modal/submit-entry-modal.component'

export class Group {
  header: string
  subItems: Item[] = []
}

export class Item {
  number: string
  content: string
  score: string
}

@Component({
  selector: 'app-flip-history',
  templateUrl: './flip-history.component.html',
  styleUrls: ['./flip-history.component.scss'],
})
export class FlipHistoryComponent implements OnInit {
  _assessment: AssessmentDto
  _age: string
  _client: ClientDto
  _clientId: string
  _collapsibleItems: Group[] = new Array<Group>()
  _dateFormat: string = 'Do MMMM, YYYY'
  _dob: Date
  _flipFilters: Filter[]
  _fallbackUrl: string = '/dashboard'
  _fullPageReportLink: string = ''
  _createFlipReportLink: string = ''
  _history: FlipHistoryDto[] = new Array<FlipHistoryDto>()
  _lastAssessmentDate: string
  _lastAssessmentDateAgo: string
  _lastAssessmentDateUnavailable: boolean = false
  _tableColumnDefinitions: TableColumnDefinition[] =
    FlipHistoryTableColumnDefinitions
  _tableColumnsToDisplay: string[] = [
    'assessmentDate',
    'score',
    'dataEnteredBy',
    'notes',
    'view',
  ]
  _tableData: MatTableDataSource<FlipHistoryTableDto> =
    new MatTableDataSource<FlipHistoryTableDto>()
  _title: string
  _todaysDateFormatted: string = moment().format(this._dateFormat)

  constructor(
    private activatedRoute: ActivatedRoute,
    private bannerService: BannerService,
    private clientService: ClientService,
    private dialog: MatDialog,
    private flipService: FlipService,
    private previousRoute: PreviousRouteService,
    private router: Router,
    public spinnerService: SpinnerService,
  ) {}

  ngOnInit(): void {
    this.spinnerService.show()

    this._clientId = this.activatedRoute.snapshot.paramMap.get('clientId')
    if (!IsUUID(this._clientId)) {
      this.bannerService.showBanner(`Invalid client requested.`, 'error', 3000)
      this.previousRoute.goToPreviousUrl(this._fallbackUrl)
    }

    this._fullPageReportLink = `/flip/graph/${this._clientId}`
    this._createFlipReportLink = `/flip/flip-report-form/${this._clientId}`
    this._flipFilters = [
      {
        attribute: 'Client/contactid',
        operation: 'eq',
        value: this._clientId,
      },
    ]

    let requests = {
      clientDetails: this.clientService.Details(this._clientId),
      getAssessment: this.flipService.GetLastAssessment(this._clientId),
      history: this.flipService.History(this._clientId),
    }

    forkJoin(requests).subscribe({
      next: ({ clientDetails, getAssessment, history }) => {
        this._assessment = getAssessment

        if (
          this._assessment.lastAssessmentDate === null ||
          this._assessment.lastAssessmentDate === undefined
        ) {
          this._lastAssessmentDateUnavailable = true
        }

        this._lastAssessmentDate = moment(
          this._assessment.lastAssessmentDate,
        ).format(this._dateFormat)
        this._lastAssessmentDateAgo = this.CalculateTimeSinceLastAssessmentDate(
          new Date(this._assessment.lastAssessmentDate),
        )

        this._collapsibleItems = this.MapAssessmentToCollapsible(
          this._assessment,
        )

        this._client = clientDetails
        this._age = this._client.age
        this._dob = this._client.dateOfBirth
        this._title = this._client.preferredFullName

        this._history = history
        this._tableData = this.MapHistoryToTable(
          this._assessment.clientId,
          this._history,
        )

        this.spinnerService.hide()
      },
      error: () => {
        this.bannerService.showBanner(
          `Unable to retrieve assessment details.`,
          'error',
          3000,
        )
        this.previousRoute.goToPreviousUrl(this._fallbackUrl)
      },
    })
  }

  routerLinkClicked() {
    this.previousRoute.clearPreviousUrl()
    this.previousRoute.goToPreviousUrl('/client-dashboard/' + this._clientId)
  }

  CalculateTimeSinceLastAssessmentDate(date: Date): string {
    const timeDifference = new Date().getTime() - date.getTime()
    const dayDifference = Math.floor(timeDifference / (1000 * 3600 * 24))

    if (dayDifference < 7) {
      return `${dayDifference} days`
    } else {
      return `${Math.floor(dayDifference / 7)} weeks`
    }
  }

  MapAssessmentToCollapsible(assessment: AssessmentDto): Group[] {
    const result: Group[] = []

    if (assessment.groups !== null) {
      for (let i = 0; i < assessment.groups.length; i++) {
        result.push(
          this.MapAssessmentGroupToCollapsibleGroup(assessment.groups[i]),
        )
      }
    }

    return result
  }

  MapAssessmentGroupToCollapsibleGroup(group: AssessmentGroupDto): Group {
    const result: Group = new Group()

    result.header = group.name ?? ''

    if (group.items !== null) {
      for (let i = 0; i < group.items.length; i++) {
        result.subItems.push(
          this.MapAssessmentItemToCollapsibleItem(group.items[i]),
        )
      }
    }

    return result
  }

  MapAssessmentItemToCollapsibleItem(item: AssessmentItemDto): Item {
    return {
      number: item.itemNumber,
      content: item.name,
      score: this.GetScoreText(item.score),
    }
  }

  MapHistoryToTable(
    clientId: string,
    history: FlipHistoryDto[],
  ): MatTableDataSource<FlipHistoryTableDto> {
    const tableData: FlipHistoryTableDto[] = new Array<FlipHistoryTableDto>()

    for (let i = 0; i < history.length; i++) {
      let source = history[i]

      let target = new FlipHistoryTableDto()
      target.date = source.assessmentDate
      target.assessmentDate = moment(source.assessmentDate).format(
        this._dateFormat,
      )
      target.dataEnteredBy = source.ownerName
      target.notes = source.notes

      target.view = new TableLinkColumnDTO()
      target.view.linkText = 'View details'
      target.view.linkUrl = `/flip/instance/${clientId}/${source.instanceId}`

      target.score = source.score.toString()

      tableData.push(target)
    }

    tableData.sort((a, b) => {
      if (a.date > b.date) {
        return -1
      } else if (a.date < b.date) {
        return 1
      }

      return 0
    })

    return new MatTableDataSource<FlipHistoryTableDto>(tableData)
  }

  NavigateToFlipAssessment() {
    this.router.navigate([`/flip-assessment/${this._clientId}`])
  }

  async SubmitExistingAssessment() {
    const dr = this.dialog.open(SubmitEntryModalComponent, {
      width: '704px',
      height: '414px',
      panelClass: 'are-you-sure-panel',
    })

    let response: SubmitEntryModalResponse = await firstValueFrom(
      dr.afterClosed(),
    )

    if (response.save) {
      this.spinnerService.show()

      // Remove time component to match main flip assessment page logic
      this._assessment.assessmentDate = new Date()
      this._assessment.assessmentDate.setHours(0, 0, 0, 0)

      this._assessment.finishingSession = true
      this._assessment.notes = response.value

      // Set to null to ensure recalculated properly by API
      this._assessment.score = null
      this._assessment.ageInDaysAtAssessment = null
      this._assessment.ageInMonthsAtAssessment = null

      //Submit
      this.flipService.Submit(this._assessment).subscribe({
        next: () => {
          this.bannerService.showBanner(
            'Sucessfully submitted FLI-P Assessment',
            'success',
            3000,
          )

          this.router.navigate([`/client-dashboard/${this._clientId}`])
        },
        error: () => {
          this.bannerService.showBanner(
            'Error submitting FLI-P Assessment',
            'error',
            3000,
          )
          this.spinnerService.hide()
        },
      })
    }
  }

  GetScoreText(score: boolean | null): string {
    switch (score) {
      case true:
        return 'Mostly'
      case false:
        return 'Rarely'
      default:
        return ''
    }
  }
}
