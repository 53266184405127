import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core'
import { AssessmentItemDto } from '../../../models/flip/assessment-item-dto'
import { AssessmentModalComponent } from '../modals/assessment-modal/assessment-modal.component'
import { firstValueFrom } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'
import { CategoryPillComponent } from '../../core/category-pill/category-pill.component'

@Component({
  selector: 'app-flip-item-summary',
  templateUrl: './flip-item-summary.component.html',
  styleUrls: ['./flip-item-summary.component.scss'],
})
export class FlipItemSummaryComponent {
  @ViewChild('flipItemContainer', { static: false }) itemContainer: ElementRef
  @ViewChild(CategoryPillComponent) categoryPillComponent: CategoryPillComponent
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>()
  @Input() number: string
  @Input() description: string
  @Input() score: string
  @Input() buttonType: 'Mostly' | 'Rarely' | 'Blank' = 'Mostly'
  @Input() variant: 'score-pill' | 'score-text' = 'score-pill'
  @Input() selected: boolean = false
  @Input() assessmentItemDto: AssessmentItemDto

  _scoreClass: string[] = ['flip-score']

  private intersectionObserver?: IntersectionObserver
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    public elm: ElementRef,
    private renderer: Renderer2,
  ) {}
  public applyHoveredState(isHovered: boolean): void {
    this.setHoveredState(isHovered)
  }
  ngOnInit(): void {
    if (this.variant === 'score-text') {
      const prefix = 'flip-score-'
      this._scoreClass.push(prefix + this.score.toLowerCase())
    }
  }

  ngAfterViewInit(): void {
    this.intersectionObserver = new IntersectionObserver(
      (entries) => {
        const entry = entries[0]
        if (entry.isIntersecting) {
          if (this.categoryPillComponent) {
            this.categoryPillComponent.hovered = true
          }
        } else {
          if (this.categoryPillComponent) {
            this.categoryPillComponent.hovered = false
          }
        }
        this.changeDetectorRef.detectChanges()
      },
      { threshold: 0.5 },
    )

    if (this.itemContainer) {
      this.intersectionObserver.observe(this.itemContainer.nativeElement)
    }
  }

  getDialog(): MatDialog {
    return this.dialog
  }

  async assessmentDialog(): Promise<boolean> {
    const dr = this.dialog.open(AssessmentModalComponent, {
      width: '650px',
      height: '450px',
      panelClass: 'assessment-modal-panel',
      data: this.assessmentItemDto,
    })
    return await firstValueFrom(dr.afterClosed())
  }

  onItemClick() {
    this.itemClicked.emit(this.number)
    let dialog = new Promise<boolean>((resolve) => {
      resolve(this.assessmentDialog())
    })
  }
  setHoveredState(isHovered: boolean): void {
    if (this.categoryPillComponent) {
      this.categoryPillComponent.hovered = isHovered
    }
  }
}
